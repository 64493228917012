var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("StepHeader", {
        attrs: {
          "header-label": "page_eni_wzrdtitle",
          "help-label": "page_eni_help"
        }
      }),
      _c("p", {
        domProps: { innerHTML: _vm._s(_vm.$t("page_eni_welcome_standard")) }
      }),
      _c("hr"),
      _c("div", { staticClass: "row" }, [
        _vm._m(0),
        _c("div", { staticClass: "col-sm-10" }, [
          _c("p", { staticClass: "lead" }, [
            _vm._v(" " + _vm._s(_vm.$t("page_eni_goal_lead")) + " ")
          ]),
          _c("p", { domProps: { innerHTML: _vm._s(_vm.$t("page_eni_goal")) } })
        ])
      ]),
      _c("hr"),
      _c("div", { staticClass: "row" }, [
        _vm._m(1),
        _c("div", { staticClass: "col-sm-10" }, [
          _c("p", { staticClass: "lead" }, [
            _vm._v(" " + _vm._s(_vm.$t("page_eni_preparation_lead")) + " ")
          ]),
          _c("div", {
            domProps: { innerHTML: _vm._s(_vm.$t("page_eni_preparation")) }
          })
        ])
      ]),
      _c("hr"),
      _c("div", { staticClass: "row" }, [
        _vm._m(2),
        _c("div", { staticClass: "col-sm-10" }, [
          _c("p", { staticClass: "lead" }, [
            _vm._v(" " + _vm._s(_vm.$t("page_eni_feedback_lead")) + " ")
          ]),
          _c("p", {
            domProps: { innerHTML: _vm._s(_vm.$t("page_eni_feedback")) }
          })
        ])
      ]),
      _c("p", [_vm._v(" " + _vm._s(_vm.$t("page_eni_thankyou")) + " ")])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-sm-offset-1 col-sm-1" }, [
      _c("span", {
        staticClass: "icon icon--info icon-large",
        attrs: { alt: "info" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-sm-offset-1 col-sm-1" }, [
      _c("span", {
        staticClass: "icon icon--eye icon-large",
        attrs: { alt: "info" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-sm-offset-1 col-sm-1" }, [
      _c("span", {
        staticClass: "icon icon--doc icon-large",
        attrs: { alt: "info" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }