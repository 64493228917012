var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h1", [_vm._v("Development Page")]),
      _c("p", [
        _vm._v(
          "On this page are examples and other parts to test the Nowhow-Components"
        )
      ]),
      _c(
        "NowhowMessageOverlay",
        {
          model: {
            value: _vm.overlayVisible,
            callback: function($$v) {
              _vm.overlayVisible = $$v
            },
            expression: "overlayVisible"
          }
        },
        [
          [
            _vm._v(" BODY "),
            _c("br"),
            _vm._v(" Und hier noch etwas Text und ein Icon: "),
            _c("p", [
              _c("span", {
                staticClass: "icon icon--pdf",
                attrs: { "aria-hidden": "true" }
              }),
              _vm._v(" My document")
            ])
          ]
        ],
        2
      ),
      _c(
        "NowhowModal",
        {
          attrs: {
            id: "modal-dev",
            visible: _vm.modalVisible,
            title: "modal-title"
          },
          on: {
            "update:visible": function($event) {
              _vm.modalVisible = $event
            }
          }
        },
        [
          _c("template", { slot: "body" }, [
            _vm._v(" BODY "),
            _c("br"),
            _vm._v(" Und hier noch etwas Text und ein Icon: "),
            _c("p", [
              _c("span", {
                staticClass: "icon icon--pdf",
                attrs: { "aria-hidden": "true" }
              }),
              _vm._v(" My document")
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-secondary",
                on: {
                  click: function($event) {
                    _vm.modalVisible = false
                  }
                }
              },
              [_vm._v("closeModal")]
            )
          ]),
          _c("template", { slot: "footer" }, [_vm._v(" footer ")])
        ],
        2
      ),
      _c("div", { staticClass: "form-group" }, [
        _c("label", { staticClass: "col-sm-3 control-label" }, [
          _vm._v("Swisstopo Searchbox ")
        ]),
        _c(
          "div",
          { staticClass: "col-sm-9" },
          [
            _c("SwisstopoFeatureSearch", {
              attrs: { id: "dev-SwisstopoFeatureSearch" },
              on: { featureSelected: _vm.onSwisstopoFeatureSelected }
            }),
            _vm.swissTopoSelectedFeature
              ? _c("div", [
                  _vm._v(" Ausgewählte Adresse: "),
                  _c("div", [
                    _vm._v(
                      " Strasse/Nr: " +
                        _vm._s(
                          _vm.swissTopoSelectedFeature.attributes.strname_deinr
                            .replace(
                              _vm.swissTopoSelectedFeature.attributes.deinr,
                              ""
                            )
                            .trim()
                        ) +
                        " " +
                        _vm._s(_vm.swissTopoSelectedFeature.attributes.deinr) +
                        " "
                    )
                  ]),
                  _c("div", [
                    _vm._v(
                      " ZIP/Ort: " +
                        _vm._s(_vm.swissTopoSelectedFeature.attributes.dplz4) +
                        " " +
                        _vm._s(
                          _vm.swissTopoSelectedFeature.attributes.dplzname
                        ) +
                        " "
                    )
                  ])
                ])
              : _vm._e()
          ],
          1
        )
      ]),
      _c("ValidationObserver", {
        ref: "observer",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var invalid = ref.invalid
              return [
                _c(
                  "form",
                  { staticClass: "form-horizontal" },
                  [
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("Examples for Inputfields")]),
                        _c("NowhowTextbox", {
                          attrs: {
                            id: "dev-textbox",
                            label: "Textbox",
                            validate: "required"
                          },
                          model: {
                            value: _vm.textbox,
                            callback: function($$v) {
                              _vm.textbox = $$v
                            },
                            expression: "textbox"
                          }
                        }),
                        _c("NowhowTextbox", {
                          attrs: {
                            id: "dev-textboxClone",
                            label:
                              "Textbox (Mit gleichem Model zu Testzwecken)",
                            validate: "required"
                          },
                          model: {
                            value: _vm.textbox,
                            callback: function($$v) {
                              _vm.textbox = $$v
                            },
                            expression: "textbox"
                          }
                        }),
                        _c("NowhowDismissableTextbox", {
                          attrs: {
                            id: "dev-dismissableTextbox",
                            label: "Dismissable Textbox",
                            validate: "required"
                          },
                          model: {
                            value: _vm.dismissableTextbox,
                            callback: function($$v) {
                              _vm.dismissableTextbox = $$v
                            },
                            expression: "dismissableTextbox"
                          }
                        }),
                        _c("NowhowTextbox", {
                          directives: [{ name: "focus", rawName: "v-focus" }],
                          attrs: {
                            id: "dev-textbox2",
                            label: "Textbox mit initialem Fokus"
                          },
                          model: {
                            value: _vm.textbox2,
                            callback: function($$v) {
                              _vm.textbox2 = $$v
                            },
                            expression: "textbox2"
                          }
                        }),
                        _c("NowhowDatebox", {
                          attrs: { id: "dev-date", label: "Datumseingabe" },
                          model: {
                            value: _vm.datebox,
                            callback: function($$v) {
                              _vm.datebox = $$v
                            },
                            expression: "datebox"
                          }
                        }),
                        _c("NowhowTextarea", {
                          attrs: { id: "dev-textarea", label: "Textarea" },
                          model: {
                            value: _vm.textarea,
                            callback: function($$v) {
                              _vm.textarea = $$v
                            },
                            expression: "textarea"
                          }
                        }),
                        _c("NowhowDropdown", {
                          attrs: {
                            id: "dev-dropdown",
                            items: _vm.dropdownItems,
                            validate: "required",
                            label: "Dropdown"
                          },
                          model: {
                            value: _vm.dropdownValue,
                            callback: function($$v) {
                              _vm.dropdownValue = $$v
                            },
                            expression: "dropdownValue"
                          }
                        }),
                        _c("NowhowCheckbox", {
                          attrs: {
                            id: "dev-checkbox",
                            label: "single Checkbox"
                          },
                          model: {
                            value: _vm.checkboxFlag,
                            callback: function($$v) {
                              _vm.checkboxFlag = $$v
                            },
                            expression: "checkboxFlag"
                          }
                        }),
                        _c("NowhowCheckboxList", {
                          attrs: {
                            id: "dev-checkboxlist",
                            items: _vm.checkboxItems,
                            validate: "required",
                            label: "Which color do you like",
                            inline: false
                          },
                          model: {
                            value: _vm.checkboxItemsValue,
                            callback: function($$v) {
                              _vm.checkboxItemsValue = $$v
                            },
                            expression: "checkboxItemsValue"
                          }
                        }),
                        _c("NowhowRadioList", {
                          attrs: {
                            id: "dev-radiolist",
                            value: _vm.radioValue,
                            items: _vm.radioItems,
                            validate: "required",
                            label: "Gender",
                            inline: false
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "fieldset",
                      [
                        _c("legend", [
                          _vm._v("Radiolist Items for multiple Lists")
                        ]),
                        _c("NowhowRadioList", {
                          attrs: {
                            id: "dev-radiolist-v-model",
                            items: _vm.dropdownSearchResultItems,
                            validate: "required|numeric",
                            label: "RadioList mit v-model",
                            inline: true
                          },
                          model: {
                            value: _vm.searchResultUniversityDegree,
                            callback: function($$v) {
                              _vm.searchResultUniversityDegree = $$v
                            },
                            expression: "searchResultUniversityDegree"
                          }
                        }),
                        _c("NowhowRadioList", {
                          attrs: {
                            id: "dev-radiolist-v-model-second",
                            items: _vm.dropdownSearchResultItems,
                            validate: "required|numeric",
                            label:
                              "RadioList mit v-model same Items other value",
                            inline: true
                          },
                          model: {
                            value: _vm.searchResultUniversityDegree2,
                            callback: function($$v) {
                              _vm.searchResultUniversityDegree2 = $$v
                            },
                            expression: "searchResultUniversityDegree2"
                          }
                        }),
                        _c("NowhowRadioList", {
                          attrs: {
                            id: "dev-radiolist-v-model-third",
                            items: _vm.dropdownSearchResultItemsCopy,
                            validate: "required|numeric",
                            label:
                              "RadioList mit v-model copy of Items other value",
                            inline: true
                          },
                          model: {
                            value: _vm.searchResultUniversityDegree3,
                            callback: function($$v) {
                              _vm.searchResultUniversityDegree3 = $$v
                            },
                            expression: "searchResultUniversityDegree3"
                          }
                        }),
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            { staticClass: "col-sm-3 control-label" },
                            [_vm._v("RadioList v-model value")]
                          ),
                          _c("div", { staticClass: "col-sm-9" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.searchResultUniversityDegree) +
                                " "
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            { staticClass: "col-sm-3 control-label" },
                            [_vm._v("RadioList v-model other value")]
                          ),
                          _c("div", { staticClass: "col-sm-9" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.searchResultUniversityDegree2) +
                                " "
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            { staticClass: "col-sm-3 control-label" },
                            [
                              _vm._v(
                                "RadioList with v-model copy of Items other value"
                              )
                            ]
                          ),
                          _c("div", { staticClass: "col-sm-9" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.searchResultUniversityDegree3) +
                                " "
                            )
                          ])
                        ])
                      ],
                      1
                    ),
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("Radiolist change value")]),
                        _c("NowhowRadioList", {
                          attrs: {
                            id: "dev-radiolist-v-model-preselection",
                            items: _vm.radiolistSearchItemsPreselected,
                            validate: "required|numeric",
                            label: "RadioList with inital value",
                            inline: true
                          },
                          model: {
                            value: _vm.radiolistValuePreselected,
                            callback: function($$v) {
                              _vm.radiolistValuePreselected = $$v
                            },
                            expression: "radiolistValuePreselected"
                          }
                        }),
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            { staticClass: "col-sm-3 control-label" },
                            [_vm._v("RadioList with inital value")]
                          ),
                          _c("div", { staticClass: "col-sm-9" }, [
                            _vm._v(
                              " " + _vm._s(_vm.radiolistValuePreselected) + " "
                            )
                          ])
                        ]),
                        _c("NowhowTextbox", {
                          attrs: {
                            label: "Change value manually",
                            id: "dev-radiolist-value"
                          },
                          model: {
                            value: _vm.radiolistValuePreselected,
                            callback: function($$v) {
                              _vm.radiolistValuePreselected = $$v
                            },
                            expression: "radiolistValuePreselected"
                          }
                        }),
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            { staticClass: "col-sm-3 control-label" },
                            [_vm._v("RadioList with inital value")]
                          ),
                          _c("div", { staticClass: "col-sm-9" }, [
                            _vm._v(
                              " " + _vm._s(_vm.radiolistValuePreselected) + " "
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            { staticClass: "col-sm-3 control-label" },
                            [_vm._v("Value auf NULL setzen")]
                          ),
                          _c("div", { staticClass: "col-sm-9" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-default",
                                attrs: { type: "button" },
                                on: { click: _vm.setPreselectedNull }
                              },
                              [_vm._v(" Auf NULL setzen ")]
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            { staticClass: "col-sm-3 control-label" },
                            [_vm._v("Value auf 3 setzen")]
                          ),
                          _c("div", { staticClass: "col-sm-9" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-default",
                                attrs: { type: "button" },
                                on: { click: _vm.setPreselected3 }
                              },
                              [_vm._v(" Auf 3 setzen ")]
                            )
                          ])
                        ])
                      ],
                      1
                    ),
                    _c(
                      "fieldset",
                      [
                        _c("legend", [
                          _vm._v("Radiolist validate tabnavigation")
                        ]),
                        _c("NowhowTextbox", {
                          attrs: {
                            id: "dummy-textbox",
                            label: "dummy textbox for tabnavigation",
                            value: "dummyvalue"
                          }
                        }),
                        _c("ValidationObserver", {
                          ref: "observer3",
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(observer) {
                                  return [
                                    _c("NowhowRadioList", {
                                      attrs: {
                                        id: "dev-radiolist-planet",
                                        items: _vm.radiolistValuePlanetItems,
                                        validate: "required|numeric",
                                        label: "RadioList Planets"
                                      },
                                      model: {
                                        value: _vm.radiolistValuePlanet,
                                        callback: function($$v) {
                                          _vm.radiolistValuePlanet = $$v
                                        },
                                        expression: "radiolistValuePlanet"
                                      }
                                    }),
                                    _c("div", { staticClass: "form-group" }, [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "col-sm-3 control-label"
                                        },
                                        [_vm._v("RadioList value")]
                                      ),
                                      _c("div", { staticClass: "col-sm-9" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.radiolistValuePlanet) +
                                            " "
                                        )
                                      ])
                                    ]),
                                    _c("pre", [
                                      _vm._v(
                                        "                        " +
                                          _vm._s(observer) +
                                          "\n                    "
                                      )
                                    ])
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    ),
                    _c(
                      "fieldset",
                      [
                        _c("legend", [_vm._v("Radio Yes/No Items")]),
                        _c("NowhowRadioYesNo", {
                          attrs: {
                            id: "dev-radioyesno-v-model",
                            name: _vm.$t("lu_noga_still_valid"),
                            "label-yes": "allg_ja",
                            "label-no": "allg_nein",
                            label: "RadioYesNo mit v-model",
                            validate: "required",
                            inline: true
                          },
                          model: {
                            value: _vm.radioYesNoValue,
                            callback: function($$v) {
                              _vm.radioYesNoValue = $$v
                            },
                            expression: "radioYesNoValue"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "fieldset",
                      [
                        _c("legend", [
                          _vm._v(
                            "Checkbox change Items actualize Value and the other way"
                          )
                        ]),
                        _c("NowhowCheckboxList", {
                          attrs: {
                            id: "dev-checkbox-items-sync",
                            items: _vm.checkboxSyncItems,
                            label: "Checkbox sync",
                            validate: "required"
                          },
                          model: {
                            value: _vm.checkboxSyncValue,
                            callback: function($$v) {
                              _vm.checkboxSyncValue = $$v
                            },
                            expression: "checkboxSyncValue"
                          }
                        }),
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            { staticClass: "col-sm-3 control-label" },
                            [_vm._v("Value")]
                          ),
                          _c("div", { staticClass: "col-sm-9" }, [
                            _vm._v(" " + _vm._s(_vm.checkboxSyncValue) + " ")
                          ])
                        ]),
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            { staticClass: "col-sm-3 control-label" },
                            [_vm._v("RadioList Items")]
                          ),
                          _c(
                            "div",
                            { staticClass: "col-sm-9" },
                            _vm._l(_vm.checkboxSyncItems, function(item) {
                              return _c(
                                "div",
                                {
                                  key: item.value,
                                  staticStyle: { "margin-bottom": "5px" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(item.text) +
                                      ": " +
                                      _vm._s(item.selected) +
                                      " => "
                                  ),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-secondary",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function($event) {
                                          item.selected = !item.selected
                                        }
                                      }
                                    },
                                    [_vm._v(" invert selected ")]
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        ])
                      ],
                      1
                    ),
                    _c("ValidationObserver", {
                      ref: "observer2",
                      attrs: { slim: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(observer) {
                              return [
                                _c("NowhowCheckbox", {
                                  attrs: {
                                    id: "dev-checkbox-validation",
                                    label: "Checkbox required",
                                    validate: {
                                      required: { allowFalse: false }
                                    }
                                  },
                                  model: {
                                    value: _vm.checkboxFlagValidation,
                                    callback: function($$v) {
                                      _vm.checkboxFlagValidation = $$v
                                    },
                                    expression: "checkboxFlagValidation"
                                  }
                                }),
                                _c("div", { staticClass: "form-group" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-sm-offset-4 col-sm-8" },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-primary",
                                          attrs: { disabled: observer.invalid }
                                        },
                                        [
                                          _vm._v(
                                            " Next (disabled wenn initial oder invalid) "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ])
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    }),
                    _c("br"),
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col-sm-offset-4 col-sm-8 button-group"
                        },
                        [
                          _c("button", { staticClass: "btn btn-secondary" }, [
                            _vm._v("Prev")
                          ]),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              attrs: { disabled: invalid }
                            },
                            [_vm._v(" Next (disabled wenn invalid) ")]
                          )
                        ]
                      )
                    ])
                  ],
                  1
                )
              ]
            }
          }
        ])
      }),
      _c("div", { staticClass: "button-group" }, [
        _c(
          "button",
          { staticClass: "btn btn-primary", on: { click: _vm.toggleFooter } },
          [_vm._v("Toggle FooterBar")]
        ),
        _c(
          "button",
          { staticClass: "btn btn-primary", on: { click: _vm.showOverlay } },
          [_vm._v("Show Overlay")]
        ),
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            on: {
              click: function($event) {
                _vm.modalVisible = true
              }
            }
          },
          [_vm._v("Show Modal")]
        )
      ]),
      _c("h1", [_vm._v("Glyphcons")]),
      _vm._m(0)
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-asterisk                               "
        }),
        _vm._v(" asterisk")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-plus                                   "
        }),
        _vm._v(" plus")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-euro                                   "
        }),
        _vm._v(" euro")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-minus                                  "
        }),
        _vm._v(" minus")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-cloud                                  "
        }),
        _vm._v(" cloud")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-envelope                               "
        }),
        _vm._v(" envelope")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-pencil                                 "
        }),
        _vm._v(" pencil")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-glass                                  "
        }),
        _vm._v(" glass")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-music                                  "
        }),
        _vm._v(" music")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-search                                 "
        }),
        _vm._v(" search")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-heart                                  "
        }),
        _vm._v(" heart")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-star                                   "
        }),
        _vm._v(" star")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-star-empty                             "
        }),
        _vm._v(" star-empty")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-user                                   "
        }),
        _vm._v(" user")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-film                                   "
        }),
        _vm._v(" film")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-th-large                               "
        }),
        _vm._v(" th-large")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-th                                     "
        }),
        _vm._v(" th")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-th-list                                "
        }),
        _vm._v(" th-list")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-ok                                     "
        }),
        _vm._v(" ok")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-remove                                 "
        }),
        _vm._v(" remove")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-zoom-in                                "
        }),
        _vm._v(" zoom-in")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-zoom-out                               "
        }),
        _vm._v(" zoom-out")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-off                                    "
        }),
        _vm._v(" off")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-signal                                 "
        }),
        _vm._v(" signal")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-cog                                    "
        }),
        _vm._v(" cog")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-trash                                  "
        }),
        _vm._v(" trash")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-home                                   "
        }),
        _vm._v(" home")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-file                                   "
        }),
        _vm._v(" file")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-time                                   "
        }),
        _vm._v(" time")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-road                                   "
        }),
        _vm._v(" road")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-download-alt                           "
        }),
        _vm._v(" download-alt")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-download                               "
        }),
        _vm._v(" download")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-upload                                 "
        }),
        _vm._v(" upload")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-inbox                                  "
        }),
        _vm._v(" inbox")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-play-circle                            "
        }),
        _vm._v(" play-circle")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-repeat                                 "
        }),
        _vm._v(" repeat")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-refresh                                "
        }),
        _vm._v(" refresh")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-list-alt                               "
        }),
        _vm._v(" list-alt")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-lock                                   "
        }),
        _vm._v(" lock")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-flag                                   "
        }),
        _vm._v(" flag")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-headphones                             "
        }),
        _vm._v(" headphones")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-volume-off                             "
        }),
        _vm._v(" volume-off")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-volume-down                            "
        }),
        _vm._v(" volume-down")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-volume-up                              "
        }),
        _vm._v(" volume-up")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-qrcode                                 "
        }),
        _vm._v(" qrcode")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-barcode                                "
        }),
        _vm._v(" barcode")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-tag                                    "
        }),
        _vm._v(" tag")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-tags                                   "
        }),
        _vm._v(" tags")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-book                                   "
        }),
        _vm._v(" book")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-bookmark                               "
        }),
        _vm._v(" bookmark")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-print                                  "
        }),
        _vm._v(" print")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-camera                                 "
        }),
        _vm._v(" camera")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-font                                   "
        }),
        _vm._v(" font")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-bold                                   "
        }),
        _vm._v(" bold")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-italic                                 "
        }),
        _vm._v(" italic")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-text-height                            "
        }),
        _vm._v(" text-height")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-text-width                             "
        }),
        _vm._v(" text-width")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-align-left                             "
        }),
        _vm._v(" align-left")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-align-center                           "
        }),
        _vm._v(" align-center")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-align-right                            "
        }),
        _vm._v(" align-right")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-align-justify                          "
        }),
        _vm._v(" align-justify")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-list                                   "
        }),
        _vm._v(" list")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-indent-left                            "
        }),
        _vm._v(" indent-left")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-indent-right                           "
        }),
        _vm._v(" indent-right")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-facetime-video                         "
        }),
        _vm._v(" facetime-video")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-picture                                "
        }),
        _vm._v(" picture")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-map-marker                             "
        }),
        _vm._v(" map-marker")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-adjust                                 "
        }),
        _vm._v(" adjust")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-tint                                   "
        }),
        _vm._v(" tint")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-edit                                   "
        }),
        _vm._v(" edit")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-share                                  "
        }),
        _vm._v(" share")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-check                                  "
        }),
        _vm._v(" check")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-move                                   "
        }),
        _vm._v(" move")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-step-backward                          "
        }),
        _vm._v(" step-backward")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-fast-backward                          "
        }),
        _vm._v(" fast-backward")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-backward                               "
        }),
        _vm._v(" backward")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-play                                   "
        }),
        _vm._v(" play")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-pause                                  "
        }),
        _vm._v(" pause")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-stop                                   "
        }),
        _vm._v(" stop")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-forward                                "
        }),
        _vm._v(" forward")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-fast-forward                           "
        }),
        _vm._v(" fast-forward")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-step-forward                           "
        }),
        _vm._v(" step-forward")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-eject                                  "
        }),
        _vm._v(" eject")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-chevron-left                           "
        }),
        _vm._v(" chevron-left")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-chevron-right                          "
        }),
        _vm._v(" chevron-right")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-plus-sign                              "
        }),
        _vm._v(" plus-sign")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-minus-sign                             "
        }),
        _vm._v(" minus-sign")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-remove-sign                            "
        }),
        _vm._v(" remove-sign")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-ok-sign                                "
        }),
        _vm._v(" ok-sign")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-question-sign                          "
        }),
        _vm._v(" question-sign")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-info-sign                              "
        }),
        _vm._v(" info-sign")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-screenshot                             "
        }),
        _vm._v(" screenshot")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-remove-circle                          "
        }),
        _vm._v(" remove-circle")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-ok-circle                              "
        }),
        _vm._v(" ok-circle")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-ban-circle                             "
        }),
        _vm._v(" ban-circle")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-arrow-left                             "
        }),
        _vm._v(" arrow-left")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-arrow-right                            "
        }),
        _vm._v(" arrow-right")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-arrow-up                               "
        }),
        _vm._v(" arrow-up")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-arrow-down                             "
        }),
        _vm._v(" arrow-down")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-share-alt                              "
        }),
        _vm._v(" share-alt")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-resize-full                            "
        }),
        _vm._v(" resize-full")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-resize-small                           "
        }),
        _vm._v(" resize-small")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-exclamation-sign                       "
        }),
        _vm._v(" exclamation-sign")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-gift                                   "
        }),
        _vm._v(" gift")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-leaf                                   "
        }),
        _vm._v(" leaf")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-fire                                   "
        }),
        _vm._v(" fire")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-eye-open                               "
        }),
        _vm._v(" eye-open")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-eye-close                              "
        }),
        _vm._v(" eye-close")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-warning-sign                           "
        }),
        _vm._v(" warning-sign")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-plane                                  "
        }),
        _vm._v(" plane")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-calendar                               "
        }),
        _vm._v(" calendar")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-random                                 "
        }),
        _vm._v(" random")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-comment                                "
        }),
        _vm._v(" comment")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-magnet                                 "
        }),
        _vm._v(" magnet")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-chevron-up                             "
        }),
        _vm._v(" chevron-up")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-chevron-down                           "
        }),
        _vm._v(" chevron-down")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-retweet                                "
        }),
        _vm._v(" retweet")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-shopping-cart                          "
        }),
        _vm._v(" shopping-cart")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-folder-close                           "
        }),
        _vm._v(" folder-close")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-folder-open                            "
        }),
        _vm._v(" folder-open")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-resize-vertical                        "
        }),
        _vm._v(" resize-vertical")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-resize-horizontal                      "
        }),
        _vm._v(" resize-horizontal")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-hdd                                    "
        }),
        _vm._v(" hdd")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-bullhorn                               "
        }),
        _vm._v(" bullhorn")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-bell                                   "
        }),
        _vm._v(" bell")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-certificate                            "
        }),
        _vm._v(" certificate")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-thumbs-up                              "
        }),
        _vm._v(" thumbs-up")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-thumbs-down                            "
        }),
        _vm._v(" thumbs-down")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-hand-right                             "
        }),
        _vm._v(" hand-right")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-hand-left                              "
        }),
        _vm._v(" hand-left")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-hand-up                                "
        }),
        _vm._v(" hand-up")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-hand-down                              "
        }),
        _vm._v(" hand-down")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-circle-arrow-right                     "
        }),
        _vm._v(" circle-arrow-right ")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-circle-arrow-left                      "
        }),
        _vm._v(" circle-arrow-left")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-circle-arrow-up                        "
        }),
        _vm._v(" circle-arrow-up")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-circle-arrow-down                      "
        }),
        _vm._v(" circle-arrow-down")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-globe                                  "
        }),
        _vm._v(" globe")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-wrench                                 "
        }),
        _vm._v(" wrench")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-tasks                                  "
        }),
        _vm._v(" tasks")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-filter                                 "
        }),
        _vm._v(" filter")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-briefcase                              "
        }),
        _vm._v(" briefcase")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-fullscreen                             "
        }),
        _vm._v(" fullscreen")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-dashboard                              "
        }),
        _vm._v(" dashboard")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-paperclip                              "
        }),
        _vm._v(" paperclip")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-heart-empty                            "
        }),
        _vm._v(" heart-empty")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-link                                   "
        }),
        _vm._v(" link")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-phone                                  "
        }),
        _vm._v(" phone")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-pushpin                                "
        }),
        _vm._v(" pushpin")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-usd                                    "
        }),
        _vm._v(" usd")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-gbp                                    "
        }),
        _vm._v(" gbp")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-sort                                   "
        }),
        _vm._v(" sort")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-sort-by-alphabet                       "
        }),
        _vm._v(" sort-by-alphabet")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-sort-by-alphabet-alt                   "
        }),
        _vm._v(" sort-by-alphabet-alt ")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-sort-by-order                          "
        }),
        _vm._v(" sort-by-order")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-sort-by-order-alt                      "
        }),
        _vm._v(" sort-by-order-alt")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-sort-by-attributes                     "
        }),
        _vm._v(" sort-by-attributes ")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-sort-by-attributes-alt                 "
        }),
        _vm._v(" sort-by-attributes-alt ")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-unchecked                              "
        }),
        _vm._v(" unchecked")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-expand                                 "
        }),
        _vm._v(" expand")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-collapse-down                          "
        }),
        _vm._v(" collapse-down")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-collapse-up                            "
        }),
        _vm._v(" collapse-up")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-log-in                                 "
        }),
        _vm._v(" log-in")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-flash                                  "
        }),
        _vm._v(" flash")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-log-out                                "
        }),
        _vm._v(" log-out")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-new-window                             "
        }),
        _vm._v(" new-window")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-record                                 "
        }),
        _vm._v(" record")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-save                                   "
        }),
        _vm._v(" save")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-open                                   "
        }),
        _vm._v(" open")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-saved                                  "
        }),
        _vm._v(" saved")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-import                                 "
        }),
        _vm._v(" import")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-export                                 "
        }),
        _vm._v(" export")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-send                                   "
        }),
        _vm._v(" send")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-floppy-disk                            "
        }),
        _vm._v(" floppy-disk")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-floppy-saved                           "
        }),
        _vm._v(" floppy-saved")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-floppy-remove                          "
        }),
        _vm._v(" floppy-remove")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-floppy-save                            "
        }),
        _vm._v(" floppy-save")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-floppy-open                            "
        }),
        _vm._v(" floppy-open")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-credit-card                            "
        }),
        _vm._v(" credit-card")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-transfer                               "
        }),
        _vm._v(" transfer")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-cutlery                                "
        }),
        _vm._v(" cutlery")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-header                                 "
        }),
        _vm._v(" header")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-compressed                             "
        }),
        _vm._v(" compressed")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-earphone                               "
        }),
        _vm._v(" earphone")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-phone-alt                              "
        }),
        _vm._v(" phone-alt")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-tower                                  "
        }),
        _vm._v(" tower")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-stats                                  "
        }),
        _vm._v(" stats")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-sd-video                               "
        }),
        _vm._v(" sd-video")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-hd-video                               "
        }),
        _vm._v(" hd-video")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-subtitles                              "
        }),
        _vm._v(" subtitles")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-sound-stereo                           "
        }),
        _vm._v(" sound-stereo")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-sound-dolby                            "
        }),
        _vm._v(" sound-dolby")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-sound-5-1                              "
        }),
        _vm._v(" sound-5-1")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-sound-6-1                              "
        }),
        _vm._v(" sound-6-1")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-sound-7-1                              "
        }),
        _vm._v(" sound-7-1")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-copyright-mark                         "
        }),
        _vm._v(" copyright-mark")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-registration-mark                      "
        }),
        _vm._v(" registration-mark")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-cloud-download                         "
        }),
        _vm._v(" cloud-download")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-cloud-upload                           "
        }),
        _vm._v(" cloud-upload")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-tree-conifer                           "
        }),
        _vm._v(" tree-conifer")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-tree-deciduous                         "
        }),
        _vm._v(" tree-deciduous")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-cd                                     "
        }),
        _vm._v(" cd")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-save-file                              "
        }),
        _vm._v(" save-file")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-open-file                              "
        }),
        _vm._v(" open-file")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-level-up                               "
        }),
        _vm._v(" level-up")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-copy                                   "
        }),
        _vm._v(" copy")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-paste                                  "
        }),
        _vm._v(" paste")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-alert                                  "
        }),
        _vm._v(" alert")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-equalizer                              "
        }),
        _vm._v(" equalizer")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-king                                   "
        }),
        _vm._v(" king")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-queen                                  "
        }),
        _vm._v(" queen")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-pawn                                   "
        }),
        _vm._v(" pawn")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-bishop                                 "
        }),
        _vm._v(" bishop")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-knight                                 "
        }),
        _vm._v(" knight")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-baby-formula                           "
        }),
        _vm._v(" baby-formula")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-tent                                   "
        }),
        _vm._v(" tent")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-blackboard                             "
        }),
        _vm._v(" blackboard")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-bed                                    "
        }),
        _vm._v(" bed")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-apple                                  "
        }),
        _vm._v(" apple")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-erase                                  "
        }),
        _vm._v(" erase")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-hourglass                              "
        }),
        _vm._v(" hourglass")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-lamp                                   "
        }),
        _vm._v(" lamp")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-duplicate                              "
        }),
        _vm._v(" duplicate")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-piggy-bank                             "
        }),
        _vm._v(" piggy-bank")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-scissors                               "
        }),
        _vm._v(" scissors")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-bitcoin                                "
        }),
        _vm._v(" bitcoin")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-btc                                    "
        }),
        _vm._v(" btc")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-xbt                                    "
        }),
        _vm._v(" xbt")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-yen                                    "
        }),
        _vm._v(" yen")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-jpy                                    "
        }),
        _vm._v(" jpy")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-ruble                                  "
        }),
        _vm._v(" ruble")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-rub                                    "
        }),
        _vm._v(" rub")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-scale                                  "
        }),
        _vm._v(" scale")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-ice-lolly                              "
        }),
        _vm._v(" ice-lolly")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-ice-lolly-tasted                       "
        }),
        _vm._v(" ice-lolly-tasted")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-education                              "
        }),
        _vm._v(" education")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-option-horizontal                      "
        }),
        _vm._v(" option-horizontal")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-option-vertical                        "
        }),
        _vm._v(" option-vertical")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-menu-hamburger                         "
        }),
        _vm._v(" menu-hamburger")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-modal-window                           "
        }),
        _vm._v(" modal-window")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-oil                                    "
        }),
        _vm._v(" oil")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-grain                                  "
        }),
        _vm._v(" grain")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-sunglasses                             "
        }),
        _vm._v(" sunglasses")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-text-size                              "
        }),
        _vm._v(" text-size")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-text-color                             "
        }),
        _vm._v(" text-color")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-text-background                        "
        }),
        _vm._v(" text-background")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-object-align-top                       "
        }),
        _vm._v(" object-align-top")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-object-align-bottom                    "
        }),
        _vm._v(" object-align-bottom ")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-object-align-horizontal                "
        }),
        _vm._v(" object-align-horizontal ")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-object-align-left                      "
        }),
        _vm._v(" object-align-left")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-object-align-vertical                  "
        }),
        _vm._v(" object-align-vertical ")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-object-align-right                     "
        }),
        _vm._v(" object-align-right ")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-triangle-right                         "
        }),
        _vm._v(" triangle-right")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-triangle-left                          "
        }),
        _vm._v(" triangle-left")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-triangle-bottom                        "
        }),
        _vm._v(" triangle-bottom")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-triangle-top                           "
        }),
        _vm._v(" triangle-top")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-console                                "
        }),
        _vm._v(" console")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-superscript                            "
        }),
        _vm._v(" superscript")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-subscript                              "
        }),
        _vm._v(" subscript")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-menu-left                              "
        }),
        _vm._v(" menu-left")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-menu-right                             "
        }),
        _vm._v(" menu-right")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-menu-down                              "
        }),
        _vm._v(" menu-down")
      ]),
      _c("li", [
        _c("span", {
          staticClass:
            "glyphicon glyphicon-menu-up                                "
        }),
        _vm._v(" menu-up")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }