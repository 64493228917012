var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: { "form-group text-line": true } }, [
    _vm.label
      ? _c(
          "label",
          { staticClass: "col-sm-3 control-label", attrs: { for: _vm.id } },
          [_vm._v(" " + _vm._s(_vm.$t(_vm.label)) + " ")]
        )
      : _vm._e(),
    _c("div", { staticClass: "col-sm-9 text-line" }, [
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isHtmlText,
              expression: "!isHtmlText"
            }
          ],
          key: _vm.id,
          staticClass: "form-control text-line",
          attrs: {
            text: _vm.text,
            id: _vm.id,
            name: _vm.id,
            disabled: _vm.disabled
          }
        },
        [_vm._v(_vm._s(_vm.text))]
      ),
      _c("span", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isHtmlText,
            expression: "isHtmlText"
          }
        ],
        domProps: { innerHTML: _vm._s(_vm.text) }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }