import { MutationTree } from 'vuex';
import { IHeaderNotificationState } from './state';

export const SET_VISIBLE = 'setVisible';
export const SET_TYPE = 'setType';
export const SET_TEXT = 'setText';
export const SET_ERRORS = 'setErrorMessages';
export const SET_CLOSEAFTERDELAY = 'setCloseAfterDelay';
export const SET_DISMISSIBLE = 'setDismissible';

export const mutations: MutationTree<IHeaderNotificationState> = {
    [SET_VISIBLE]: (state, visible: boolean) => {
        state.visible = visible;
    },
    [SET_TYPE]: (state, type: string) => {
        state.type = type;
    },

    /** Legt den anzuzeigenden Text fest
     * @remarks Zweifache Slashes werden '//' als Zeilenumbruch interpretiert
     */
    [SET_TEXT]: (state, text: string) => {
        state.text = text.replace(/\/\//g, '<br />');
    },

    /** Legt die anzuzeigenden Fehlermeldungen fest
     * @remarks In Stings werden zweifache Slashes '//' als Zeilenumbruch interpretiert
     */
    [SET_ERRORS]: (state, errorMessages: any) => {
        if (typeof errorMessages === 'string') {
            state.text = errorMessages.replace(/\/\//g, '<br />');
        } else {
            let text = '';
            for (const key in errorMessages) {
                if (key in errorMessages) {
                    text += errorMessages[key];
                    text += '<br />';
                }
            }
            state.text = text;
        }
    },
    [SET_CLOSEAFTERDELAY]: (state, closeAfterDelay) => {
        state.closeAfterDelay = closeAfterDelay;

        if (!closeAfterDelay) {
            state.closeDelay = 0;
        } else {
            state.closeDelay = 5000;
        }
    },
    [SET_DISMISSIBLE]: (state, value: boolean) => {
        state.isDismissible = value;
    }
};
