import { IRootState } from '@/store';
import { GetterTree } from 'vuex';
import { ISwisstopoState } from './state';

/** Die Resultate einer Feature-Suche */
export const FEATURE_SEARCH_RESULT = 'featureSearchResult';

/** Die Detail-Daten eines ausgewählten Features
 * @remarks Dieses Feature ist typischerweise ein ausgewähltes aus einer vorangehenden Feature-Suche
 */
export const SELECTED_FEATURE = 'selectedFeature';

export const getters: GetterTree<ISwisstopoState, IRootState> = {
    [FEATURE_SEARCH_RESULT]: state => state.searchResult,
    [SELECTED_FEATURE]: state => state.selectedFeature
};
