



























import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { IMaskComponent, IMask } from 'vue-imask';

@Component({
  components: {
    'imask-input': IMaskComponent
    
  }
})

/** Implementiert eine Dreifach-Textbox mit Label für die Eingabe eines Access-Codes. Die Komponenten behandelt eigenständig die Fokussierung und die Textlänge
 * innerhalb der 3 Eingabefelder. Es findet jedoch keine Validierung der Eingabe selbst statt.
 * @remarks Rendert je eine Spalte für das Label und die 3 Eingabefelder (sm-3 und sm-9 im Bootstrap-Grid)
 */
export default class AccessCodeTextbox extends Vue {
    @Prop({ required: true, type: String }) private id!: string;

    @Prop({ required: false, type: String, default: '' }) private label!: string;

    @Prop({ required: false, type: Boolean, default: false }) private required!: boolean;

    /** Der anzuzeigende AccessCode (12 Zeichen ohne Trennzeichen) */
    @Prop({ required: false, default: '' }) private accessCode!: string;

    @Prop({ required: false, type: String, default: '' })
    private cy!: string;

    /** Property für den "maskierten" Wert */
    private maskedValue = '';
    
    private patternMask =  IMask.createMask({
      mask: '#### - #### - ####',
      definitions: {
        '#': /[ABCDEFGHJKLMNPQRSTUVWXYZ23456789]/
      },
      unmask:true,
      prepare: function (str) {
        return str.toUpperCase();
      }
    });
  
    public onAccept(){
      this.$emit('input', this.patternMask.unmaskedValue);
    }

    /** Behandelt die Änderung des Access-Code durch die übergeordnete Komponente, indem der neue Inhalt auf die 3 Parts verteilt wird.
     */
    @Watch('accessCode', { deep: true, immediate: true })
    private onAccessCodeChanged() {
      //Diese Zuweisung führt im Log zu einer Warnung für eine Endlosschleife. Diese Warnung kann im Moment ignoriert werden.
      this.maskedValue = this.accessCode;
    }
}
