var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h1", [_vm._v(_vm._s(_vm.$t("page_enfinish_title")))]),
    _c(
      "div",
      { staticClass: "alert alert-success", attrs: { role: "alert" } },
      [
        _c("span", { staticClass: "sr-only" }, [_vm._v("success:")]),
        _vm._v(" " + _vm._s(_vm.$t("page_enfinish_info1")) + " ")
      ]
    ),
    _c("p", [_vm._v(" " + _vm._s(_vm.$t("page_enfinish_info2")) + " ")])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }