




import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

import { namespace } from 'vuex-class';
import { NAME as NAME_FOOTERBAR } from '@/store/modules/footerBar';
const footerBarModul = namespace(NAME_FOOTERBAR);
import { DISABLE_BUTTONRIGHT } from '@/store/modules/footerBar/actions';

/** Proxy, welcher den Disabled-Zustand des rechts-liegenden "Weiter"-Button steuert
 * @devdoc Kann als Proxy in einem Validation-Slot verwendet werden, um die Buttons der Footer-Bar steuern zu können.
 */

@Component
export default class FooterBarRightButtonProxy extends Vue {
    @footerBarModul.Action(DISABLE_BUTTONRIGHT) disableButtonRight!: Function;

    /** Liefert oder definiert den Status des rechts-liegenden "Weiter"-Button */
    @Prop({ required: false, type: Boolean, default: false }) private disabled!: boolean;

    /** Behandelt die Änderung des Disabled-Property, indem der Wert auf den rechts-liegenden "Weiter"-Button angewendet wird.
     * @remarks Die anderen Buttons werden unverändert übernommen
     */
    @Watch('disabled', { deep: true, immediate: true })
    private onDisabledChanged(newVal) {
        console.log('FooterBarRightButtonProxy.vue::onDisabledChanged', newVal);
        //Aktion "Weiter" steht gemäss neuem Wert zur Verfügung
        this.disableButtonRight(newVal);
    }
}
