var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("nav", { staticClass: "nav-process process-items-3" }, [
      _c("ul", [
        _c("li", { staticClass: "active" }, [
          _c("a", { attrs: { href: "#" } }, [
            _vm._v(_vm._s(_vm.$t("allg_accesscode")))
          ])
        ]),
        _c("li", { staticClass: "disabled" }, [
          _c("a", { attrs: { href: "#" } }, [
            _vm._v(_vm._s(_vm.$t("allg_password")))
          ])
        ]),
        _c("li", { staticClass: "disabled" }, [
          _c("a", { attrs: { href: "#" } }, [
            _vm._v(_vm._s(_vm.$t("allg_delivery")))
          ])
        ])
      ])
    ]),
    _c("h1", [_vm._v(_vm._s(_vm.$t("page_reg_title")))]),
    _c("div", { domProps: { innerHTML: _vm._s(_vm.$t("page_reg_info")) } }),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isTyping,
            expression: "!isTyping"
          }
        ],
        staticClass: "alert alert-info",
        attrs: { role: "alert" }
      },
      [
        _c("span", { staticClass: "sr-only" }, [_vm._v("info:")]),
        _vm._v(_vm._s(_vm.$t("page_reg_accesscodeinfo")) + " ")
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isTyping && (!_vm.isComplete || !_vm.isCorrect),
            expression: "isTyping && (!isComplete || !isCorrect)"
          }
        ],
        staticClass: "alert alert-warning",
        attrs: { role: "alert" }
      },
      [
        _c("span", { staticClass: "sr-only" }, [_vm._v("warning:")]),
        _vm._v(_vm._s(_vm.$t("page_reg_accesscodeincorrect")) + " ")
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isTyping && _vm.isComplete && _vm.isCorrect,
            expression: "isTyping && isComplete && isCorrect"
          }
        ],
        staticClass: "alert alert-success",
        attrs: { role: "alert" }
      },
      [
        _c("span", { staticClass: "sr-only" }, [_vm._v("success:")]),
        _vm._v(_vm._s(_vm.$t("page_reg_accesscodevalid")) + " ")
      ]
    ),
    _c(
      "form",
      {
        staticClass: "form-horizontal",
        attrs: { role: "form" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.getAssignedSurveyName(_vm.accessCode)
          }
        }
      },
      [
        _c(
          "fieldset",
          [
            _c("AccessCodeTextbox", {
              directives: [{ name: "focus", rawName: "v-focus" }],
              attrs: {
                id: "accesscode-textbox",
                "access-code": _vm.accessCode,
                required: true,
                label: "allg_accesscode",
                validate: _vm.getValidation(
                  "registrationDataViewModel.accessCode"
                )
              },
              on: { input: _vm.onAccessCodeInput }
            }),
            _c("div", { staticClass: "col-sm-offset-3 col-sm-9" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: {
                    type: "submit",
                    role: "button",
                    disabled: !_vm.isCorrect
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("allg_confirmandcontinue")) + " ")]
              )
            ])
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }