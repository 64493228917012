












































import Vue from 'vue';

import { Component, Prop, Watch } from 'vue-property-decorator';

import { ISelectListItem } from '@/types/clienttypes';
import { ProviderInstance } from 'vee-validate/dist/types/types';

@Component({})
export default class NowhowDropdown extends Vue {
    @Prop({ required: true, type: String }) private id!: string;

    @Prop({ required: false, type: String, default: '' }) private label!: string;

    @Prop({ required: false, type: Boolean, default: false }) private disabled!: boolean;

    @Prop({ required: false, type: Boolean, default: false }) private readonly!: boolean;

    @Prop({ default: '', type: [String, Object] }) validate!: string | object;

    @Prop({ required: true, type: Array as () => Array<ISelectListItem>, default: () => [] }) items!: ISelectListItem[];

    @Prop({
        required: false,
        type: [String, Array as () => Array<string>, Number, Array as () => Array<number>],
        default: ''
    })
    value!: string | number;

    @Prop({ required: false, type: String, default: '' })
    private cy!: string;

    private isMounted = false;
    private provider: ProviderInstance;
    private touched = false;

    mounted() {
        const options = document.getElementsByName(String(this.id)) as NodeListOf<HTMLOptionElement>;
        options.forEach(checkbox => {
            //Selected je nach Typ unterschiedlich festlegen
            //Hinweis: Es wird erwartet dass, der Basis-Typ von checkbox.value und von value exakt der gleiche ist.
            if (Array.isArray(this.value)) {
                //Array: Prüfen, ob der Wert im Array enthalten ist
                //checkbox.selected = Array.from(this.value).includes(checkbox.value);
                if (typeof checkbox.value === 'string') {
                    checkbox.selected = (this.value as Array<string>).includes(checkbox.value);
                } else if (typeof checkbox.value === 'number') {
                    checkbox.selected = (this.value as Array<number>).includes(checkbox.value);
                } else {
                    //Andere Typen werden nicht unterstützt
                    throw new Error(`Expected string or number, got '${checkbox.value}'.`);
                }
            } else {
                //Einzel-Wert: prüfen, ob der Wert exakt gleich ist
                checkbox.selected = this.value === checkbox.value;
            }
        });
        this.provider = this.$refs['provider'] as ProviderInstance;
        if (this.value) {
            this.onChange(this.value);
        }
        this.isMounted = true;
    }

    public focus() {
        (this.$refs.inputfield as HTMLInputElement).focus();
    }

    get selectedValues() {
        return this.items.filter(item => item.selected).map(item => item.value);
    }

    private onChange(value) {
        if (this.value === value) {
            return;
        }

        // Finde die Items die item.selected sind.
        const selectedItemsByValue = this.items.filter(item => {
            if (Array.isArray(this.value)) {
                return value?.includes(item.value);
            } else {
                // Hinweis: Hier wird absichtlich der == Operator verwendet, um strings und numbers verwenden zu können
                // eslint-disable-next-line eqeqeq
                return value == item.value;
            }
        });
        // select/deselect items in this.items
        this.items.map(item => {
            item.selected = selectedItemsByValue?.includes(item);
            return item;
        });

        this.provider.validate(value).then(() => {
            //TODO falls numerisch, als numerisch ausgeben, ansonsten wird erst nach Serverseitigen Roundtrip der Wert als Numersich verarbeitet

            this.$emit('input', value);
            this.$emit('change', value);
            this.touched = true;
        });
    }

    @Watch('value')
    valueChanged(newVal) {
        if (newVal) {
            this.onChange(newVal.toString());
        }
    }

    @Watch('items')
    itemsChanged() {
        this.onChange(this.value?.toString());
    }

    private onFocusout(value) {
        this.provider.validate(this.value).then(() => {
            this.$emit('focusout', this.value);
        });
    }
}
