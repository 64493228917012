var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("nav", { staticClass: "nav-process noselect" }, [
        _c(
          "ul",
          [
            _c("router-link", {
              attrs: { to: { name: _vm.ENTRY_WIZARD_INFO } },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var isActive = ref.isActive
                    return [
                      _c(
                        "li",
                        {
                          class: [isActive && "active"],
                          attrs: { title: _vm.$t("page_eni_wzrdtitle") }
                        },
                        [
                          _c("span", { staticClass: "nav-bar" }),
                          _vm._v(
                            " " + _vm._s(_vm.$t("page_eni_wzrdtitle")) + " "
                          )
                        ]
                      )
                    ]
                  }
                }
              ])
            }),
            _c("router-link", {
              attrs: { to: { name: _vm.ENTRY_WIZARD_CONTACT } },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var isActive = ref.isActive
                    return [
                      _c(
                        "li",
                        {
                          class: [isActive && "active"],
                          attrs: { title: _vm.$t("nav_contact") }
                        },
                        [
                          _c("span", { staticClass: "nav-bar" }),
                          _vm._v(" " + _vm._s(_vm.$t("nav_contact")) + " ")
                        ]
                      )
                    ]
                  }
                }
              ])
            }),
            _c("router-link", {
              attrs: { to: { name: _vm.ENTRY_WIZARD_LOCAL } },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var isActive = ref.isActive
                    return [
                      _c(
                        "li",
                        {
                          class: [isActive && "active"],
                          attrs: { title: _vm.$t("nav_lu_details") }
                        },
                        [
                          _c("span", { staticClass: "nav-bar" }),
                          _vm._v(" " + _vm._s(_vm.$t("nav_lu_details")) + " ")
                        ]
                      )
                    ]
                  }
                }
              ])
            }),
            _c("router-link", {
              attrs: { to: { name: _vm.ENTRY_WIZARD_ENTERPRISE } },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var isActive = ref.isActive
                    return [
                      _c(
                        "li",
                        {
                          class: [isActive && "active"],
                          attrs: { title: _vm.$t("nav_eu_details") }
                        },
                        [
                          _c("span", { staticClass: "nav-bar" }),
                          _vm._v(" " + _vm._s(_vm.$t("nav_eu_details")) + " ")
                        ]
                      )
                    ]
                  }
                }
              ])
            }),
            _c("router-link", {
              attrs: { to: { name: _vm.ENTRY_WIZARD_ACTIVITY } },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var isActive = ref.isActive
                    return [
                      _c(
                        "li",
                        {
                          class: [isActive && "active"],
                          attrs: { title: _vm.$t("nav_activity") }
                        },
                        [
                          _c("span", { staticClass: "nav-bar" }),
                          _vm._v(" " + _vm._s(_vm.$t("nav_activity")) + " ")
                        ]
                      )
                    ]
                  }
                }
              ])
            }),
            _c("router-link", {
              attrs: { to: { name: _vm.ENTRY_WIZARD_CENSUS } },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var isActive = ref.isActive
                    return [
                      _c(
                        "li",
                        {
                          class: [isActive && "active"],
                          attrs: { title: _vm.$t("nav_census") }
                        },
                        [
                          _c("span", { staticClass: "nav-bar" }),
                          _vm._v(" " + _vm._s(_vm.$t("nav_census")) + " ")
                        ]
                      )
                    ]
                  }
                }
              ])
            }),
            _c("router-link", {
              attrs: { to: { name: _vm.ENTRY_WIZARD_CONFIRMATION } },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var isActive = ref.isActive
                    return [
                      _c("li", { class: [isActive && "active"] }, [
                        _c("span", { staticClass: "nav-bar" }),
                        _vm._v(" " + _vm._s(_vm.$t("nav_confirmation")) + " ")
                      ])
                    ]
                  }
                }
              ])
            })
          ],
          1
        )
      ]),
      _c("router-view")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }