//Element Fokusieren, nachdem dies eingefügt wurde.
export default {
    // When the bound element is inserted into the DOM...
    inserted: function (element) {
        // Focus the element
        if (element && element.__vue__ && element.__vue__.focus) {
            const func = element.__vue__.focus;
            //Focus mit Zeitverzögerung aufrufen, weil der Focus erst zu einem Zeitpunkt nach dem Rendern gesetzt werden kann
            setTimeout(
                elem => {
                    //Hier die Prüfung auf Existenz und Zustand wiederholen, da inzwischen das Element wieder aus dem DOM entfernt oder verändert worden sein könnte.
                    if (elem.offsetParent !== null) {
                        func();
                    }
                },
                200,
                element
            );
        }
        // Focus on Html-Element
        if (element && element.focus) {
            element.focus();
        }
    }
};
