







































import Vue from 'vue';
import FocusLock from 'vue-focus-lock';
import { Component, Prop, Watch } from 'vue-property-decorator';

@Component({
    components: { FocusLock }
})

/** Ein Modal
 */
export default class NowhowModal extends Vue {
    @Prop({ required: false, type: String, default: ' ' }) private title!: string;
    @Prop({ required: true, type: String }) private id!: string;
    @Prop({ required: false, type: String, default: '' }) private text!: string;

    //binding! :visible.sync
    @Prop({ required: true, type: Boolean, default: false }) private visible!: boolean;
    @Prop({ required: false, type: Boolean, default: true }) private canClose!: boolean;
    @Prop({ required: false, type: Boolean, default: true }) private hasHeader!: boolean;
    @Prop({ required: false, type: String, default: '' }) private additionalCss!: boolean;
    @Prop({ required: false, type: String, default: 'col-xs-11 col-md-10 col-lg-8 col-xl-6' })
    private contentCss!: boolean;

    private backdropElement: HTMLElement;

    beforeDestroy() {
        this.closeModal();
    }

    @Watch('visible')
    visibleChanged(newVal) {
        console.debug('NowhowModal.vue::visibleChanged', newVal);
        if (newVal) {
            this.openModal();
        } else {
            this.closeModal();
        }
    }

    closeModal() {
        if (this.canClose) {
            //Remove Eventlisteners
            document.removeEventListener('keydown', this.handleKeydown);
            this.backdropElement?.removeEventListener('click', this.handleClick);

            this.$emit('update:visible', false);
        }
    }
    openModal() {
        if (this.canClose) {
            //Register Eventlisteners
            document.addEventListener('keydown', this.handleKeydown);
            this.$nextTick(() => {
                this.backdropElement = document.getElementById('backdrop');
                this.backdropElement.addEventListener('click', this.handleClick);
            });
        }

        this.$emit('update:visible', true);
    }

    handleKeydown(event) {
        const keycode = event.which || event.keyCode;
        if (keycode === 27) {
            // handle Escape key (ESC)
            this.closeModal();
            return;
        }
    }
    handleClick(event) {
        event.preventDefault();
        this.closeModal();
    }
    get hasFooterSlot() {
        return !!this.$slots['footer'];
    }
}
