import { IRootState } from '@/store';
import { GetterTree } from 'vuex';
import { IEnvironmentState } from './state';

/** Liefert den Namen des Environments für die Vue-App. */
export const VUE_APP_ENVIRONMENT = 'VueAppEnvironment';

export const getters: GetterTree<IEnvironmentState, IRootState> = {
    [VUE_APP_ENVIRONMENT]: state => state.environment.vueAppEnvironment
};
