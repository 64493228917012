var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("nav", { staticClass: "nav-process process-items-3" }, [
      _c("ul", [
        _c("li", { staticClass: "disabled" }, [
          _c("a", { attrs: { href: "#" } }, [
            _vm._v(_vm._s(_vm.$t("allg_accesscode")))
          ])
        ]),
        _c("li", { staticClass: "disabled" }, [
          _c("a", { attrs: { href: "#" } }, [
            _vm._v(_vm._s(_vm.$t("allg_password")))
          ])
        ]),
        _c("li", { staticClass: "active" }, [
          _c("a", { attrs: { href: "#" } }, [
            _vm._v(_vm._s(_vm.$t("allg_delivery")))
          ])
        ])
      ])
    ]),
    _c("h1", [_vm._v(_vm._s(_vm.$t("page_reg_title")))]),
    this.isRegistrationSuccessful
      ? _c("div", [
          _c(
            "div",
            { staticClass: "alert alert-success", attrs: { role: "alert" } },
            [
              _c("span", { staticClass: "sr-only" }, [_vm._v("success:")]),
              _vm._v(_vm._s(_vm.$t("page_rcn_message")) + " ")
            ]
          ),
          _c("div", {
            domProps: { innerHTML: _vm._s(_vm.$t("page_rcn_info")) }
          })
        ])
      : _vm._e(),
    this.isRegistrationFailed
      ? _c("div", [
          _c(
            "div",
            { staticClass: "alert alert-danger", attrs: { role: "alert" } },
            [
              _c("span", { staticClass: "sr-only" }, [_vm._v("danger:")]),
              _vm._v(_vm._s(_vm.$t("page_rer_infofailure")) + " ")
            ]
          ),
          _vm._v(" " + _vm._s(_vm.$t("page_rer_info")) + " "),
          _c("br"),
          _c("br"),
          _c("div", { staticClass: "form-horizontal" }, [
            _c(
              "div",
              { staticClass: "col-sm-offset-3 col-sm-9" },
              [
                _c(
                  "router-link",
                  { attrs: { to: { name: _vm.ACCOUNT_CONTACT } } },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { role: "button" }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("nav_contact")) + " ")]
                    )
                  ]
                )
              ],
              1
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }