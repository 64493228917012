



































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';

import { namespace } from 'vuex-class';
import { NAME } from '@/areas/account/store';
const accountModul = namespace(NAME);
import { IS_LOGGED_IN } from '@/areas/account/store/getters';

import Header from './Header.vue';
import store from '@/store';
import HeaderNotifcation from './HeaderNotifcation.vue';
import { CLOSE } from '@/store/modules/headerNotification/actions';
import LoadingSpinner from './LoadingSpinner.vue';
import Navigation from './Navigation.vue';
import LeftNavigation from './LeftNavigation.vue';
import Footer from './Footer.vue';
import FooterBar from './FooterBar.vue';

@Component({
    components: {
        Header,
        HeaderNotifcation,
        LoadingSpinner,
        Navigation,
        LeftNavigation,
        Footer,
        FooterBar
    }
})

/** Definiert das Gesamt-Layout der Anwendung
 * @remarks stellt auch des Menu als LeftNavigation dar, wenn erforderlich.
 */
export default class Layout extends Vue {
    @accountModul.Getter(IS_LOGGED_IN) loggedIn!: boolean;

    /** Gibt an, ob das Anwendungs-Menu (für die Navigation) benötigt wird
     * @remarks Das Menu wird grundsätzlich nur im ausgeloggten Zustand dargestellt.
     * Via optionales Property "requiresMenu" in den Metadaten der Route kann das jedoch explizit übersteuert werden.
     */
    isMenuRequired(): boolean {
        this.$route.name === 'finish';

        //Explizit definiert?
        if (this.$route.meta?.requiresMenu === true || this.$route.meta?.requiresMenu === false) {
            console.debug('requiresMenu', this.$route.meta?.requiresMenu);
            return this.$route.meta?.requiresMenu;
        }
        //Sonst den Login-Zustand verwenden
        return !this.loggedIn === true;
    }

    /** Gibt an, ob die Service-Navigation (inkl. Sprachwahl) benötigt wird
     * @remarks Die Service-Navigation (inkl. Sprachwahl) wird grundsätzlich immer dargestellt.
     * Via optionales Property "requiresServiceNavigation" in den Metadaten der Route kann das
     * jedoch explizit übersteuert werden.
     */
    isServieNavigationRequired(): boolean {
        //Explizit definiert?
        if (
            this.$route.meta?.requiresServiceNavigation === true ||
            this.$route.meta?.requiresServiceNavigation === false
        ) {
            console.debug('requiresServiceNavigation', this.$route.meta?.requiresServiceNavigation);
            return this.$route.meta?.requiresServiceNavigation;
        }
        //Sonst den Default verwenden
        return true;
    }

    /** Behandelt die Änderung der Route
     * @remarks Fehlermeldungen sollen nur solange sichtbar sein, wie der Benutzer auf der gleichen "Page" bleibt.
     */
    @Watch('$route', { deep: true, immediate: true })
    onRouteChanged(to, from) {
        console.debug('Layout.vue::onRouteChanged to', to);

        //Allfällige Fehlermeldungen im Header entfernen
        store.dispatch('headerNotification/' + CLOSE, { root: true });
    }
}
