import { MutationTree } from 'vuex';
import { ISaveIndicationState } from './state';

export const SET_ACTIVE = 'SET_ACTIVE';
export const SET_INACTIVE = 'SET_INACTIVE';

export const mutations: MutationTree<ISaveIndicationState> = {
    /** Legt den aktiven Zustand fest
     */ [SET_ACTIVE]: state => {
        state.active = true;
    },
    /** Legt den inaktiven Zustand fest
     */ [SET_INACTIVE]: state => {
        state.active = false;
    }
};
