var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "Portal",
        { attrs: { to: "modals" } },
        [
          _c("FocusLock", { attrs: { disabled: !_vm.visible } }, [
            _c(
              "div",
              {
                staticClass: "modal modal--open",
                class: _vm.additionalCss,
                attrs: { id: _vm.id, role: "dialog", tabindex: "-1" }
              },
              [
                _vm.visible
                  ? _c(
                      "div",
                      {
                        staticClass: "modal__content well",
                        class: _vm.contentCss
                      },
                      [
                        _vm.hasHeader
                          ? _c("div", { staticClass: "modal__header" }, [
                              _c("h1", [_vm._v(_vm._s(_vm.title))])
                            ])
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            directives: [{ name: "focus", rawName: "v-focus" }],
                            staticClass: "modal__body",
                            attrs: { tabindex: "0" }
                          },
                          [
                            _vm.text
                              ? _c("div", [_vm._v(_vm._s(_vm.text))])
                              : _vm._e(),
                            !_vm.text
                              ? _vm._t("body", [
                                  _vm._v(" Dies ist der Standard Modal Body ")
                                ])
                              : _vm._e()
                          ],
                          2
                        ),
                        _vm.hasFooterSlot
                          ? _c(
                              "div",
                              { staticClass: "modal__footer" },
                              [_vm._t("footer")],
                              2
                            )
                          : _vm._e()
                      ]
                    )
                  : _vm._e()
              ]
            ),
            _c("div", {
              class: { backdrop: true, "backdrop--open": _vm.visible },
              attrs: { id: "backdrop" }
            })
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }