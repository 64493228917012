
































































//Imports für dieses Modul
import Vue from 'vue';
import { namespace } from 'vuex-class';
import { NAME } from '../store/index';
import { NAME as NAME_HEADERNOTIFICATION } from '@/store/modules/headerNotification';
import { Component, Watch } from 'vue-property-decorator';
import { NEW_SUCESS, NEW_ALERT } from '@/store/modules/headerNotification/actions';
import { CredentialsDto } from '@/api-viewmodel';
import { ACCOUNT_RECOVERY, ACCOUNT_REGISTER, ENTRY_WIZARD } from '@/router';
import NowhowTextbox from '@/components/NowhowTextbox.vue';

const headerModul = namespace(NAME_HEADERNOTIFICATION);
const modul = namespace(NAME);

//Getters und Actions
import { AUTHENTICATED_USERNAME, IS_EXTERNAL_USER, IS_LOGGED_IN } from '../store/getters';
import { LOGIN, LOGOUT } from '../store/actions';

@Component({
    components: {
        NowhowTextbox
    }
})

/** Login an der Anwendung.
 * @remarks Nach erfolgreicher Anmeldung wird der Benutzer entsprechend dem Request oder seiner Rolle weitergeleitet
 * @remarks Implementiert {@link https://collaboration.nowhow.ch/erst/foswiki/bin/view/Erst/LoginScreen} */
export default class AccountLogin extends Vue {
    protected WEBFORMS_BASE_URL: string | undefined = process.env.VUE_APP_WEBFORMS_BACKEND_URL;

    constructor() {
        super();
        //Hint den Fallback zentral lösen
        if (!this.WEBFORMS_BASE_URL) {
            //Fallback für den Fall dass keine Backend-URL verfügbar ist
            const url = window.location.href;
            const searchParam = 'redesign/';
            const length = url.toLowerCase().indexOf(searchParam) - 1; //ohne 'redesign/' Pfad
            this.WEBFORMS_BASE_URL = url.substr(0, length);
            console.debug('AccountLogin::constructor (with fallback URL)', this.WEBFORMS_BASE_URL);
        } else {
            console.debug('AccountLogin::constructor', this.WEBFORMS_BASE_URL);
        }
    }

    private returnUrl = '/';

    /** Route für die Registrierung
     * @devdoc Wird hier als Property deklariert um das vue-binding im Router-Link zu ermöglichen
     */
    private ACCOUNT_REGISTER = ACCOUNT_REGISTER;

    /** Route für das Passwort-Recovery
     * @devdoc Wird hier als Property deklariert um das vue-binding im Router-Link zu ermöglichen
     */ private ACCOUNT_RECOVERY = ACCOUNT_RECOVERY;

    //Hinweis: Benutzername und Passwort für das Login werden nicht im Store geführt
    credentials = new CredentialsDto();

    @headerModul.Action(NEW_SUCESS) successMessage!: Function;
    @headerModul.Action(NEW_ALERT) alertMessage!: Function;

    @modul.Action(LOGIN) login!: Function;
    @modul.Action(LOGOUT) logout!: Function;
    @modul.Getter(AUTHENTICATED_USERNAME) authenticatedUsername!: string;
    @modul.Getter(IS_EXTERNAL_USER) isExternalUser!: boolean;
    @modul.Getter(IS_LOGGED_IN) loggedIn!: boolean;

    /** Wrapper für eine Form-Submit-Aktion, um das Standard-Submit-Verhalten im Zusammenspiel mit vee-validate zu unterbinden.
     * @devdoc Wird hier benötigt, da sich die zu versendenden Daten nicht im Store befinden.
     */
    private loginWrapper() {
        this.login(this.credentials);
    }

    created() {
        console.debug('Login.vue::created ', this.WEBFORMS_BASE_URL);
        // get return url from route parameters or default to '/'
        console.debug('login::query', this.$route.query);
        // NOTE: das Casing des ReturnUrl-Query-Parameters unterscheidet sich zwischen Frontend und Backend
        const backendReturnUrl = this.$route.query.ReturnUrl; //CamelCase deutet auf Return-URL des Backends hin
        if (typeof backendReturnUrl === 'string') {
            //Allfälligen Pfad aus der Return-URL ins Backend entfernen, weil dieser in der WEBFORMS_BASE_URL bereits enthalten ist
            console.debug('login::window.location.origin', window.location.origin);
            const applicationPath = this.WEBFORMS_BASE_URL.substring(window.location.origin.length);
            console.debug('login::applicationPath', applicationPath);
            this.returnUrl = backendReturnUrl.substring(applicationPath.length);
        } else {
            const frontendReturnUrl = this.$route.query.returnUrl;
            this.returnUrl = typeof frontendReturnUrl === 'string' ? frontendReturnUrl : '/';
        }

        console.debug('login::returnUrl', this.returnUrl);

        //Falls sich der Client bereit im eingeloggten Zustand befinden, direkt zur passenden View/Page weitergehen
        if (this.loggedIn) {
            this.redirectAfterLogin();
        }
    }

    @Watch('loggedIn')
    onPropertyChanged(newVal: boolean) {
        //Einen frisch eingeloggten Client direkt zur passenden View/Page weiterleiten
        if (newVal) {
            this.redirectAfterLogin();
        }
    }

    /**  Leitet einen Client zur dem Login entsprechenden View/Page weiter, indem die
     * returnUrl der Query angewendet wird, falls vorhanden.
     * @devdoc Der Spezialfall für das Printout eines Falls in einem Neuen Tab funktioniert
     * via dieser returnUrl.
     */
    redirectAfterLogin() {
        console.log('Login::redirectAfterLogin::returnUrl', this.returnUrl);
        if (this.isExternalUser) {
            //Für externe Benutzer: direkt in den Wizard
            console.log('external user ' + this.authenticatedUsername + ' has logged in. Routing to Entry Wizard.');
            //HINT: Hier auf die übergeordnete Wizard-Komponente weiterleiten (und nicht direkt auf einen Step), um den dortigen Initialisierungsvorgang auszulösen
            this.$router.push({ name: ENTRY_WIZARD });
        } else {
            //Für BFS-Benutzer weiterleiten in den Wizard ODER ins Backend-GUI
            if (this.returnUrl.includes('wizard') && this.$route.query.CaseId) {
                //Wenn explizit der EntryWizard mit einem Case aufgerufen wird, dorthin weiterleiten
                console.log(
                    'BFS user ' +
                        this.authenticatedUsername +
                        ' has logged in for the EntryWizard. Navigating to the entry Wizard, CaseId: ' +
                        this.$route.query.CaseId
                );
                this.$router.push({
                    //HINT: Hier auf die übergeordnete Wizard-Komponente weiterleiten (und nicht direkt auf einen Step), um den dortigen Initialisierungsvorgang auszulösen
                    name: ENTRY_WIZARD,
                    query: this.$route.query /* query übernehmen, damit die CaseId für den Wizard erhalten bleibt */
                });
            } else {
                //Sonst: die Return-URL für das klassische UI verwenden
                const location = this.WEBFORMS_BASE_URL + this.returnUrl;
                console.log(`BFS user '${this.authenticatedUsername}' has logged in. Navigating to '${location}'`);

                window.location.href = location;
            }
        }
    }
}
