































































import Vue from 'vue';

import { Component, Prop } from 'vue-property-decorator';

import NowhowCheckboxList from '@/components/NowhowCheckboxList.vue';
import { ISelectListItem } from '@/types/clienttypes';

/** Implementiert eine Auswahl für 12 Monate (als Saison) mit Label und kompletter Validierung falls Required
 * @remarks Rendert eine Tabelle mit Quartals-Einteilung
 * @remarks Implementiert die Anforderung aus dem DatenErfassungLocalTaetigkeitScreen
 * @devdoc Verwendet intern die NowhowCheckboxList Komponente für die Funktion. Die Anzeige wird jedoch mittels
 * Buttons gelöst, um die gewünschte Darstellung zu erhalten
 */
@Component({
    components: { NowhowCheckboxList }
})
export default class NowhowSeasonPicker extends Vue {
    @Prop({ required: true, type: String }) private id!: string;

    @Prop({ required: false, type: String, default: '' }) private label!: string;

    @Prop({ required: false, type: Boolean, default: false }) private disabled!: boolean;

    @Prop({ required: false, type: Boolean, default: false }) private readonly!: boolean;

    @Prop({ default: '', type: [String, Object] }) validate!: string | object;

    /* Die Auswahl der 12 Monate als Saison
     * @remarks Es wird erwartet, dass exakt 12 Items übergeben werden
     */
    @Prop({ required: true, type: Array as () => Array<ISelectListItem>, default: () => [] }) items!: ISelectListItem[];

    @Prop({
        required: false,
        type: [String, Array as () => Array<string>, Number, Array as () => Array<number>],
        default: []
    })
    value!: string | string[] | number | number[];

    @Prop({ required: false, type: String, default: '' })
    private cy!: string;

    /** Liefert eine Zeile für die Darstellung des Zeitraums in Quartalen von je 3 Monaten */
    private getRowOfItems(rowNumber: number): Array<ISelectListItem> {
        const ar = new Array<ISelectListItem>();
        ar.push(this.items[0 + rowNumber]);
        ar.push(this.items[3 + rowNumber]);
        ar.push(this.items[6 + rowNumber]);
        ar.push(this.items[9 + rowNumber]);
        return ar;
    }

    get required() {
        const isString = typeof this.validate === 'string' || this.validate instanceof String;
        // eslint-disable-next-line no-prototype-builtins
        return isString ? this.validate.toString().indexOf('required') >= 0 : this.validate.hasOwnProperty('required');
    }

    mounted() {
        console.debug('NowhowSeasonPicker::mounted');
        //
    }

    private onChange(args) {
        this.$emit('change', args);
    }

    private onInput(args) {
        this.$emit('input', args);
    }

    private onFocusout(args) {
        this.$emit('focusout', args);
    }
}
