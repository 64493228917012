var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("StepHeader", {
        attrs: {
          "header-label": "page_ludt_wzrdtitle",
          "help-label": "page_ludt_help"
        }
      }),
      _c("ValidationObserver", {
        ref: "localUnitDetailsObserver",
        staticClass: "v-observer",
        attrs: { tag: "div" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var invalid = ref.invalid
              return [
                _c("LocalUnitEditAddress", {
                  attrs: {
                    "local-unit": _vm.localUnit,
                    "enterprise-unit": _vm.enterpriseUnit
                  }
                }),
                _c("FooterBarRightButtonProxy", {
                  attrs: { id: "ButtonNextProxy", disabled: invalid }
                })
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }