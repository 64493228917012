var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { staticClass: "footer" }, [
    _c("div", { staticClass: "container" }, [
      _c("h2", { staticClass: "sr-only" }, [_vm._v(" Footer ")]),
      _c("div", { staticClass: "container-fluid footer-service" }, [
        _c(
          "h3",
          [
            _vm._v(" " + _vm._s(_vm.$t("allg_bfs_name")) + " – "),
            _c("span", { attrs: { title: _vm.UserInfo } }, [
              _vm._v(
                _vm._s(_vm.$t("allg_application_name")) +
                  " " +
                  _vm._s(_vm.AppVersion)
              )
            ]),
            _vm.AppState
              ? _c(
                  "span",
                  {
                    staticClass: "footer-spaced-item",
                    attrs: { title: _vm.AppStateDetails }
                  },
                  [_vm._v(_vm._s(_vm.AppState))]
                )
              : _vm._e(),
            _c("SaveIndicator")
          ],
          1
        ),
        _c("nav", [
          _c("ul", [
            _vm.BackendCaseDetailUrl
              ? _c("li", [
                  _c(
                    "a",
                    {
                      staticClass: "text-nowrap",
                      attrs: { href: _vm.BackendCaseDetailUrl }
                    },
                    [_vm._v(_vm._s(_vm.$t("nav_backend")))]
                  )
                ])
              : _vm._e(),
            _c(
              "li",
              [
                _c(
                  "router-link",
                  { attrs: { to: { name: _vm.ACCOUNT_CONTACT } } },
                  [_vm._v(" " + _vm._s(_vm.$t("nav_contact")) + " ")]
                )
              ],
              1
            ),
            _c("li", [
              _c(
                "a",
                {
                  attrs: {
                    href:
                      "https://www.bfs.admin.ch/bfs/de/home/bfs/bundesamt-statistik/rechtliche-hinweise.html",
                    target: "_blank"
                  }
                },
                [_vm._v(_vm._s(_vm.$t("nav_legal")))]
              )
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "footer-address" }, [
        _c(
          "span",
          { staticClass: "hidden-xs" },
          [
            _vm._v(_vm._s(_vm.$t("allg_bfs_name")) + " – "),
            _c("span", { attrs: { title: _vm.UserInfo } }, [
              _vm._v(
                _vm._s(_vm.$t("allg_application_name")) +
                  " " +
                  _vm._s(_vm.AppVersion)
              )
            ]),
            _vm.AppState
              ? _c(
                  "span",
                  {
                    staticClass: "footer-spaced-item",
                    attrs: { title: _vm.AppStateDetails }
                  },
                  [_vm._v(_vm._s(_vm.AppState))]
                )
              : _vm._e(),
            _c("SaveIndicator")
          ],
          1
        ),
        _c("nav", { staticClass: "hidden-xs pull-right" }, [
          _c("ul", [
            _vm.BackendCaseDetailUrl
              ? _c("li", [
                  _c(
                    "a",
                    {
                      staticClass: "text-nowrap",
                      attrs: { href: _vm.BackendCaseDetailUrl }
                    },
                    [_vm._v(_vm._s(_vm.$t("nav_backend")))]
                  )
                ])
              : _vm._e(),
            _c(
              "li",
              [
                _c(
                  "router-link",
                  { attrs: { to: { name: _vm.ACCOUNT_CONTACT } } },
                  [_vm._v(" " + _vm._s(_vm.$t("nav_contact")) + " ")]
                )
              ],
              1
            ),
            _c("li", [
              _c(
                "a",
                {
                  attrs: { href: this.$t("legal_link_url"), target: "_blank" }
                },
                [_vm._v(_vm._s(_vm.$t("nav_legal")) + " ")]
              )
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }