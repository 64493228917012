var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h1", [_vm._v(_vm._s(_vm.$t("page_prc_title")))]),
      _c("div", { domProps: { innerHTML: _vm._s(_vm.$t("page_prc_info")) } }),
      _c("ValidationObserver", {
        ref: "observer",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var invalid = ref.invalid
              var handleSubmit = ref.handleSubmit
              return [
                _c(
                  "form",
                  {
                    staticClass: "form-horizontal",
                    attrs: { role: "form" },
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return handleSubmit(_vm.requestRegistrationWrapper)
                      }
                    }
                  },
                  [
                    _c(
                      "fieldset",
                      [
                        _c("NowhowTextbox", {
                          directives: [{ name: "focus", rawName: "v-focus" }],
                          attrs: {
                            id: "username-textbox",
                            label: "allg_user_login_id",
                            validate: "required"
                          },
                          model: {
                            value: _vm.username,
                            callback: function($$v) {
                              _vm.username = $$v
                            },
                            expression: "username"
                          }
                        }),
                        _c("div", { staticClass: "col-sm-offset-3 col-sm-9" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              attrs: {
                                type: "submit",
                                role: "button",
                                disabled: invalid
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("btn_send")) + " ")]
                          )
                        ])
                      ],
                      1
                    )
                  ]
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }