var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h1", [_vm._v(_vm._s(_vm.$t("page_con_title")))]),
    _c("table", { staticClass: "table table-naked" }, [
      _c("tbody", [
        _c("tr", [
          _c("th", { staticClass: "table-col-slim" }, [
            _vm._v(_vm._s(_vm.$t("allg_organisation")))
          ]),
          _c("td", [_vm._v(_vm._s(_vm.contactInfo.organisation))])
        ]),
        _c("tr", [
          _c("th", { staticClass: "table-col-slim" }, [
            _vm._v(_vm._s(_vm.$t("allg_street")))
          ]),
          _c("td", [
            _vm._v(
              _vm._s(_vm.contactInfo.street) +
                " " +
                _vm._s(_vm.contactInfo.houseNumber)
            )
          ])
        ]),
        _c("tr", [
          _c("th", { staticClass: "table-col-slim" }, [
            _vm._v(_vm._s(_vm.$t("allg_zip")))
          ]),
          _c("td", [_vm._v(_vm._s(_vm.contactInfo.zip))])
        ]),
        _c("tr", [
          _c("th", { staticClass: "table-col-slim" }, [
            _vm._v(_vm._s(_vm.$t("allg_town")))
          ]),
          _c("td", [_vm._v(_vm._s(_vm.contactInfo.town))])
        ]),
        _c("tr", [
          _c("th", { staticClass: "table-col-slim" }, [
            _vm._v(_vm._s(_vm.$t("allg_email")))
          ]),
          _c("td", [
            _c(
              "a",
              {
                staticClass: "text-nowrap",
                attrs: { href: "mailto:" + _vm.contactInfo.email }
              },
              [_vm._v(_vm._s(_vm.contactInfo.email))]
            )
          ])
        ]),
        _c("tr", [
          _c("th", { staticClass: "table-col-slim" }, [
            _vm._v(_vm._s(_vm.$t("allg_phone_number")))
          ]),
          _c("td", [_vm._v(_vm._s(_vm.contactInfo.phoneNumber))])
        ])
      ])
    ]),
    _vm.loggedIn
      ? _c("div", { staticClass: "col-sm-12" }, [
          _c("div", { staticClass: "text-right" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-secondary",
                attrs: { role: "button" },
                on: {
                  click: function($event) {
                    return _vm.goBack()
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("btn_wzrd_back")) + " ")]
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }