var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container container-main" },
    [
      _c("Header"),
      _c("Navigation"),
      _c("LoadingSpinner"),
      _c("PortalTarget", { attrs: { name: "modals" } }),
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          this.isMenuRequired()
            ? _c(
                "div",
                { staticClass: "col-sm-4 col-md-3 col-lg-2" },
                [_c("LeftNavigation")],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              class: {
                "col-sm-8 col-md-9 col-lg-10": this.isMenuRequired(),
                "col-sm-12": !this.isMenuRequired()
              },
              attrs: { id: "content" }
            },
            [_c("HeaderNotifcation"), _c("router-view")],
            1
          )
        ])
      ]),
      _c("FooterBar"),
      _c("Footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }