import { MutationTree } from 'vuex';
import {
    ICaseFullDataViewModel,
    CaseFullDataViewModel,
    ICaseContactDataViewModel,
    IValidationErrorViewModel,
    CaseApprovalDto,
    ICaseStepInfoViewModel
} from '@/api-viewmodel';
import { IEntryState } from './state';

/** Weist die vollen Falldaten zu
 *  @remarks Aktualisiert den gesamten "caseFullData" Zustand.*/
export const MAPCASEFULLDATA = 'mapCaseFullData';

/** Weist die Kontakt-Daten eines Falls zu */
export const MAPCASECONTACTDATA = 'mapCaseContactData';

/** Weist die Validations-Meldungen eines Falls zu */
export const MAPCASEVALIDATIONMESSAGES = 'mapCaseValidationMessages';

/** Weist eine (aktualisierte) Step-Info einem Falls zu
 * @remarks Die Step-Info ist ein Subset der vollen Fall-Daten
 */
export const MAPCASESTEPINFO = 'mapCaseStepInfo';

/** Entfernt die bisherigen Daten eines Falles, in dem den Falldaten null zugewiesen wird
 * @remarks Aktualisiert alle entsprechenden Teile Zustandes.
 */
export const WIPECASEDATA = 'wipeCaseData';

export const mutations: MutationTree<IEntryState> = {
    [MAPCASEFULLDATA](state, value: ICaseFullDataViewModel) {
        state.caseFullData = value;
    },
    [MAPCASECONTACTDATA](state, value: ICaseContactDataViewModel) {
        state.caseFullData.generalEnterpriseContactDataObject = value;
    },

    [MAPCASESTEPINFO](state, value: ICaseStepInfoViewModel) {
        state.caseFullData.caseStepInfo = value;
    },

    /** Übernimmt die Validierungsmeldungen
     * @remarks Zweifache Slashes werden '//' als Zeilenumbruch interpretiert
     */
    [MAPCASEVALIDATIONMESSAGES](state, value: IValidationErrorViewModel) {
        if (value.errorMessages) {
            //Die (einzige) Meldung mit leerem Key übernehmen.
            const validationMessage = value.errorMessages[''][0];

            //Enthaltene Einzel-Meldungen aufteilen und übernehmen
            const brokenValidationMessage = validationMessage.replace(/\/\//g, '<br />');
            const validationLines = brokenValidationMessage.split('<br />');
            state.caseValidationMessages = validationLines;
        } else {
            //Keine Validierungsmeldungen
            state.caseValidationMessages = null;
        }
    },
    [WIPECASEDATA](state) {
        console.debug('WIPECASEDATA');

        //Ursprungszustand wiederherstellen
        state.caseValidationMessages = null;
        state.caseFullData = new CaseFullDataViewModel();
        state.caseApproval = new CaseApprovalDto();
    }
};
