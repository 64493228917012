var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationProvider", {
    ref: "provider",
    attrs: { name: _vm.label, rules: _vm.validate, vid: _vm.id },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var errors = ref.errors
          var required = ref.required
          return [
            _c(
              "div",
              { class: { "form-group": true, "has-warning": errors[0] } },
              [
                _vm.label
                  ? _c(
                      "label",
                      {
                        staticClass: "col-sm-3 control-label",
                        attrs: { for: _vm.id }
                      },
                      [
                        _vm._v(" " + _vm._s(_vm.$t(_vm.label)) + " "),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: required,
                                expression: "required"
                              }
                            ]
                          },
                          [_vm._v("*")]
                        )
                      ]
                    )
                  : _vm._e(),
                _c("div", { staticClass: "col-sm-9" }, [
                  _c("textarea", {
                    key: _vm.id,
                    ref: "inputfield",
                    staticClass: "form-control",
                    attrs: {
                      id: _vm.id,
                      name: _vm.id,
                      autocomplete: _vm.autocomplete,
                      disabled: _vm.disabled,
                      readonly: _vm.readonly,
                      placeholder: _vm.$t(_vm.placeholder),
                      rows: _vm.rows,
                      cols: _vm.cols,
                      "data-cy": _vm.cy
                    },
                    domProps: { value: _vm.value },
                    on: {
                      keyup: [
                        _vm.onChange,
                        function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.onEnter($event)
                        }
                      ],
                      focusout: _vm.onFocusout,
                      input: function($event) {
                        return _vm.onInput($event.target.value)
                      }
                    }
                  }),
                  errors[0]
                    ? _c(
                        "span",
                        {
                          staticClass: "help-block",
                          attrs: { id: _vm.id + "-error", role: "alert" }
                        },
                        [_vm._v(" " + _vm._s(errors[0]) + " ")]
                      )
                    : _vm._e()
                ])
              ]
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }