import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import { store } from '@/store';

import { NAME as NAME_ACCOUNT } from '@/areas/account/store';
import { IS_LOGGED_IN } from '@/areas/account/store/getters';

export const HOME = 'home';
export const ACCOUNT_LOGIN = 'account.login';
export const ACCOUNT_CONTACT = 'account.contact';
export const ACCOUNT_REGISTER = 'account.register';
export const ACCOUNT_RECOVERY = 'account.recovery';
export const ENTRY_WIZARD = 'entry.wizard';

/** Route für den Info-Step (Index 0) im Entry-Wizard */
export const ENTRY_WIZARD_INFO = 'entry.wizard.info';

/** Druckansicht für einen Fall.
 * @remarks Die Fall-Id wird im Query-Parameter "CaseId" mitgegeben
 */
export const ENTRY_PRINTOUT = 'entry.printout';
export const ENTRY_WIZARD_ENTERPRISE = 'entry.wizard.enterprise';
export const ENTRY_WIZARD_CONTACT = 'entry.wizard.contact';
export const ENTRY_WIZARD_LOCAL = 'entry.wizard.local';
export const ENTRY_WIZARD_CONFIRMATION = 'entry.wizard.confirmation';
export const ENTRY_WIZARD_ACTIVITY = 'entry.wizard.activity';
export const ENTRY_WIZARD_CENSUS = 'entry.wizard.census';

/** Route für den Info-Screen nach erfolgreichem Abschluss des Falles im Entry-Wizard s*/
export const ENTRY_WIZARD_CONFIRMATION_FINISH = 'entry.wizard.confirmation.finish';

export const DEVELOPMENT = 'development';
export const SWISSTOPO = 'SWISSTOPO';

import Login from '@/areas/account/views/Login.vue';
import Contact from '@/areas/account/views/Contact.vue';
import Register from '@/areas/account/views/Register.vue';
import Recovery from '@/areas/account/views/Recovery.vue';
import Wizard from '@/areas/entry/views/Wizard.vue';
import WizardStepInfo from '@/areas/entry/components/StepInfo.vue';
import WizardPrintout from '@/areas/entry/components/Printout.vue';
import WizardStepConfirmation from '@/areas/entry/components/StepConfirmation.vue';
import WizardStepCensus from '@/areas/entry/components/StepCensus.vue';
import WizardStepActivity from '@/areas/entry/components/StepActivity.vue';
import WizardStepContact from '@/areas/entry/components/StepContact.vue';
import WizardStepLocal from '@/areas/entry/components/StepLocal.vue';
import WizardStepEnterprise from '@/areas/entry/components/StepEnterprise.vue';
import WizardStepFinish from '@/areas/entry/components/StepFinish.vue';
import Development from '@/areas/development/views/Development.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        component: Login,
        name: ACCOUNT_LOGIN,
        path: '/login'
    },
    {
        component: Register,
        name: ACCOUNT_REGISTER,
        path: '/register'
    },
    {
        component: Recovery,
        name: ACCOUNT_RECOVERY,
        path: '/recovery'
    },
    {
        component: Contact,
        name: ACCOUNT_CONTACT,
        path: '/contact',
        meta: {
            // Die Kontakt-Angaben sind öffentlich
            requiresAuth: false
        }
    },
    {
        component: Wizard,
        name: ENTRY_WIZARD,
        path: '/wizard',
        meta: {
            requiresAuth: true
        },
        children: [
            { path: '/', redirect: { name: ENTRY_WIZARD_INFO } },
            { path: 'info', name: ENTRY_WIZARD_INFO, component: WizardStepInfo, alias: '' },
            { path: 'contact', name: ENTRY_WIZARD_CONTACT, component: WizardStepContact },
            { path: 'enterprise', name: ENTRY_WIZARD_ENTERPRISE, component: WizardStepEnterprise },
            { path: 'local', name: ENTRY_WIZARD_LOCAL, component: WizardStepLocal },
            {
                path: 'activity',
                name: ENTRY_WIZARD_ACTIVITY,
                component: WizardStepActivity
            },
            {
                path: 'census',
                name: ENTRY_WIZARD_CENSUS,
                component: WizardStepCensus
            },
            {
                path: 'confirm',
                name: ENTRY_WIZARD_CONFIRMATION,
                component: WizardStepConfirmation
            }
        ]
    },

    /** Letzter Info-Screen nach dem Abschluss
     * @remarks Zu diesem Zeitpunkt ist der externe Benutzer bereits ausgeloggt.
     * @devdoc Ausserhalb des Wizards, weil kein eigener Step und die Wizard-Daten nicht benötigt werden
     */
    {
        path: 'wizard/finish',
        name: ENTRY_WIZARD_CONFIRMATION_FINISH,
        component: WizardStepFinish,
        meta: {
            requiresAuth: false,
            requiresMenu: false
        }
    },

    /** Druck-Ansicht auf dem Confirm-Step
     * @devdoc Ausserhalb des Wizards, weil kein eigener Step (Lädt die benötigten Daten selbständig)
     */
    {
        path: '/printout',
        name: ENTRY_PRINTOUT,
        component: WizardPrintout,
        meta: {
            requiresMenu: false,
            requiresServiceNavigation: false
        }
    },

    { name: HOME, path: '', redirect: { name: ENTRY_WIZARD } },
    { path: '*', redirect: { name: ENTRY_WIZARD } }
];

if (process.env.NODE_ENV === 'development') {
    //Testseite für Komponenten, ist nur zugänglich wen im Modus "development" gebuildet wird.
    routes.push({
        component: Development,
        name: DEVELOPMENT,
        path: '/development',
        meta: {
            requiresAuth: false
        }
    });
}

const router = new VueRouter({
    routes
});

router.beforeEach(async (to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        console.debug('index::to', to);

        //HINT: Die Prüfung auf den Logged-In-Status wäre korrekter, wenn
        //das auth-Cookie geprüft würde, statt auf IS_LOGGED_IN im Account-Store
        //Damit würde beim Aufruf aus dem klassischen Backend für BFS-Benutzer
        //das Redirect über den Login-Screen hier entfallen, da diese ja bereits
        //eingeloggt sind (Auth-Cookie schon vorhanden)
        //Der Einfachheit halber wird dies hier jedoch aktuell nicht umgesetzt
        const loggedIn = store.getters[`${NAME_ACCOUNT}/${IS_LOGGED_IN}`];
        //Ist ein Login für die Ziel-URL nötig?
        if (!loggedIn) {
            //Das notwendige Login vornehmen und dir Rücksprung-Adresse als Query-Parameter ergänzen
            console.log('not logged in');

            //Die bestehende Query übernehmen und mit der returnUrl ergänzen
            const returningQuery = to.query;
            returningQuery['returnUrl'] = to.path;
            console.log('returningQuery', to.path);
            next({
                name: ACCOUNT_LOGIN,
                query: returningQuery
            });
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
