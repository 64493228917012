import { IEnvironmentViewModel } from '@/api-viewmodel';

/** Definiert den Zustand für das Environment
 * @interface
 */
export interface IEnvironmentState {
    /** Das Environment */
    environment: IEnvironmentViewModel;
}

/** Stellt den Zustand für das Environment zur Verfügung.
 */
export const state: IEnvironmentState = {
    /** Das Environment */
    environment: {
        vueAppEnvironment: ''
    }
};
