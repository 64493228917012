



















































































































































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { GET_VALIDATION_KEY } from '@/store/modules/validation/getters';

import NowhowTextbox from '@/components/NowhowTextbox.vue';
import NowhowTextline from '@/components/NowhowTextline.vue';
import NowhowTextarea from '@/components/NowhowTextarea.vue';
import NowhowUInt32Input from '@/components/NowhowUInt32Input.vue';
import NowhowCheckbox from '@/components/NowhowCheckbox.vue';
import NowhowRequiredInfoline from '@/components/NowhowRequiredInfoline.vue';

import { NAME } from '../store/index';
const modul = namespace(NAME);

import { NAME as NAME_VALIDATION } from '@/store/modules/validation';
const validationModul = namespace(NAME_VALIDATION);

import { SUBMIT_LOCAL_UNIT_CENSUS_DATA } from '../store/actions';
import { CASE_ID } from '../store/getters';

import { ILocalUnitDataObject } from '@/api-viewmodel';

@Component({
    components: {
        NowhowTextline,
        NowhowTextbox,
        NowhowTextarea,
        NowhowUInt32Input,
        NowhowCheckbox,
        NowhowRequiredInfoline
    }
})

/** Bereich für die Bearbeitung der Census-Details einer Arbeitsstätte
 * @remarks Implementier die Anforderungen aus https://collaboration.nowhow.ch/erst/foswiki/bin/view/Erst/DatenErfassungLocalCensusScreen#Verhalten
 */
export default class LocalUnitEditCensus extends Vue {
    //Getters
    @modul.Getter(CASE_ID) caseId!: bigint;
    @validationModul.Getter(GET_VALIDATION_KEY) getValidation!: (key: string) => string;

    //Actions
    @modul.Action(SUBMIT_LOCAL_UNIT_CENSUS_DATA) submitlocalUnitCensusData!: Function;

    @Prop({ required: true, type: Object })
    private localUnit!: ILocalUnitDataObject;

    mounted() {
        //Aktuelles Token der unveränderte Daten für die spätere, erstmalige Prüfung merken
        this.lastSubmittedDataToken = this.submittableDataToken;
    }

    beforeDestroy() {
        this.saveStepData();
    }

    /** Behandelt die Änderung für die AHV-Pflicht
     * @remarks Implementiert die Anforderung 010 gemäss DatenErfassungLocalCensusScreen
     */
    @Watch('localUnit.hasNoAhvObligation', { deep: false, immediate: true })
    private onAhvObligationChanged(newVal: boolean, oldVal: boolean) {
        //Nicht mehr pflichtig?
        if (newVal === true && oldVal === false) {
            if (this.localUnit) {
                this.$nextTick(() => {
                    //Beschäftigung auf zero setzen, aber erst (next tick), nachdem die aktualisierten Rules in vee-validator angewendet wurden
                    this.localUnit.fullMale = 0;
                    this.localUnit.fullFemale = 0;
                    this.localUnit.part1Male = 0;
                    this.localUnit.part1Female = 0;
                    this.localUnit.part2Male = 0;
                    this.localUnit.part2Female = 0;
                    this.localUnit.part3Male = 0;
                    this.localUnit.part3Female = 0;
                    this.localUnit.fullTimeEquivalentMale = 0;
                    this.localUnit.fullTimeEquivalentFemale = 0;
                });
            }
        }
        //Neu pflichtig?
        if (newVal === false && oldVal === true) {
            if (this.localUnit) {
                //Beschäftigung auf leer, Angabe erzwingen
                this.localUnit.fullMale = null;
                this.localUnit.fullFemale = null;
                this.localUnit.part1Male = null;
                this.localUnit.part1Female = null;
                this.localUnit.part2Male = null;
                this.localUnit.part2Female = null;
                this.localUnit.part3Male = null;
                this.localUnit.part3Female = null;
                this.localUnit.fullTimeEquivalentMale = null;
                this.localUnit.fullTimeEquivalentFemale = null;
            }
        }
    }

    /** Liefert die Validierungsregel den Census-Reason-Text
     */
    get getCensusReasonTextValidationRule(): string {
        if (this.isOccupationZero) {
            return 'required';
        } else {
            return '';
        }
    }

    /** Bestimmt, ob das Gesamttotal der Beschäftigten Personen null (zero) ist, bzw. gibt an, ob die Angabe eines Grundes verlangt ist.
     * @remarks Implementiert Teil der Anforderung 040 und 010 in DatenErfassungLocalCensusScreen
     * Das Feld wird nur angezeigt, wenn alle Felder exakt 0 (Zero), sind
     * @devdoc Wird für die Steuerung von Einblendungen sowie für das Setzen von Default-Werten verwendet.
     */
    get isOccupationZero(): boolean {
        const isOccupationZero =
            this.localUnit &&
            this.localUnit.fullMale === 0 &&
            this.localUnit.fullFemale === 0 &&
            this.localUnit.part1Male === 0 &&
            this.localUnit.part1Female === 0 &&
            this.localUnit.part2Male === 0 &&
            this.localUnit.part2Female === 0 &&
            this.localUnit.part3Male === 0 &&
            this.localUnit.part3Female === 0 &&
            this.localUnit.fullTimeEquivalentMale === 0 &&
            this.localUnit.fullTimeEquivalentFemale === 0;

        if (!isOccupationZero) {
            this.localUnit.censusReasonText = null;
        }
        return isOccupationZero;
    }

    get fullPersons(): number {
        return Number(this.localUnit.fullMale) + Number(this.localUnit.fullFemale);
    }
    get part1Persons(): number {
        return Number(this.localUnit.part1Male) + Number(this.localUnit.part1Female);
    }
    get part2Persons(): number {
        return Number(this.localUnit.part2Male) + Number(this.localUnit.part2Female);
    }
    get part3Persons(): number {
        return Number(this.localUnit.part3Male) + Number(this.localUnit.part3Female);
    }

    get totalPersons(): number {
        return (
            Number(this.fullPersons) + Number(this.part1Persons) + Number(this.part2Persons) + Number(this.part3Persons)
        );
    }

    /** Liefert das Total der Vollzeit-Äquivalenten
     * @remarks Der Wert wird auf 2 Dezimalstellen gerunden
     * @devdoc Siehe https://stackoverflow.com/a/11832950/79485 für die Rundungsfunktion
     */
    get totalFte(): number {
        const totalFte = Math.round(
            Number(this.localUnit.fullTimeEquivalentMale) +
                Number(this.localUnit.fullTimeEquivalentFemale) +
                Number.EPSILON
        );
        return totalFte;
    }

    /** Liefert die Validierungsregel für das untere Limit der Vollzeitäquivalenten der Männer in ganzzahligen [Prozent]
     * @devdoc Siehe auch die analoge server-seitige Implementation in GetCensusBottomLimit
     */
    get getLowerMaleFteLimitValidationRule(): string {
        if (this.localUnit.hasNoAhvObligation) {
            return ''; //Kein Limit, wenn keine Angabe
        }
        const lowerLimitMale =
            Number(this.localUnit.fullMale) * 90 +
            Number(this.localUnit.part1Male) * 50 +
            Number(this.localUnit.part2Male) * 15 +
            Number(this.localUnit.part3Male) * 1;
        return 'min_value:' + lowerLimitMale.toString();
    }

    /** Liefert die Validierungsregel für das obere Limit der Vollzeitäquivalenten der Männer in ganzzahligen [Prozent]
     * @devdoc Siehe auch die analoge server-seitige Implementation in GetCensusUpperLimit
     */
    get getUpperMaleFteLimitValidationRule(): string {
        if (this.localUnit.hasNoAhvObligation) {
            return ''; //Kein Limit, wenn keine Angabe
        }
        const upperLimitMale =
            Number(this.localUnit.fullMale) * 100 +
            Number(this.localUnit.part1Male) * 89 +
            Number(this.localUnit.part2Male) * 49 +
            Number(this.localUnit.part3Male) * 14;
        return 'max_value:' + upperLimitMale.toString();
    }

    /** Liefert die Validierungsregel für das untere Limit der Vollzeitäquivalenten der Frauen in ganzzahligen [Prozent]
     * @devdoc Siehe auch die analoge server-seitige Implementation in GetCensusBottomLimit
     */
    get getLowerFemaleFteLimitValidationRule(): string {
        if (this.localUnit.hasNoAhvObligation) {
            return ''; //Kein Limit, wenn keine Angabe
        }
        const lowerLimitFemale =
            Number(this.localUnit.fullFemale) * 90 +
            Number(this.localUnit.part1Female) * 50 +
            Number(this.localUnit.part2Female) * 15 +
            Number(this.localUnit.part3Female) * 1;
        return 'min_value:' + lowerLimitFemale.toString();
    }

    /** Liefert die Validierungsregel für das obere Limit der Vollzeitäquivalenten der Frauen in ganzzahligen [Prozent]
     * @devdoc Siehe auch die analoge server-seitige Implementation in GetCensusUpperLimit
     */
    get getUpperFemaleFteLimitValidationRule(): string {
        if (this.localUnit.hasNoAhvObligation) {
            return ''; //Kein Limit, wenn keine Angabe
        }
        const upperLimitFemale =
            Number(this.localUnit.fullFemale) * 100 +
            Number(this.localUnit.part1Female) * 89 +
            Number(this.localUnit.part2Female) * 49 +
            Number(this.localUnit.part3Female) * 14;
        return 'max_value:' + upperLimitFemale.toString();
    }

    /** Speichert die aktuell eingegebenen Werte auf das Backend
     * @remarks Die Daten werden nur versendet, wenn diese seit dem letzten Versand oder erstmalig geändert haben
     * Für diese Prüfung werden alle Eingabewerte dieser Komponente verwendet
     * @devdoc Diese Funktion soll beim Verlassen des Bearbeitungsformulars aufgerufen werden
     * Hinweis: Es kann aktuell nicht die Funktionalität der ValidationObserver-Komponente verwendet werden,
     * weil diese keinen zwischenzeitlichen Reset der "Dirty-Flags" erlaubt.
     * Hinweis2: Es wird nicht ein "dirty-tracking" via "changed"-Event implementiert, um nur zum Zeitpunkt des
     * Versandes effektiv veränderte Daten zu versenden.
     */
    saveStepData() {
        console.debug('::saveStepData');

        const submittableDataToken = this.submittableDataToken;
        if (submittableDataToken !== this.lastSubmittedDataToken) {
            this.submitlocalUnitCensusData({
                caseId: this.caseId,
                data: this.localUnit
            });
            this.lastSubmittedDataToken = submittableDataToken;
        }
    }

    /** Zwischenspeicher für die Prüfung, ob ein erneuter Versand der Daten notwendig ist */
    lastSubmittedDataToken: string = null;

    /** Liefert ein Token für die versendbaren Daten zur späteren Prüfung, ob ein erneuter Versand notwendig ist.
     * @devdoc Für den effektiven Versand an die API wird das Objekt aus dem Zustand verwendet,
     *  nicht dieses interne DTO.
     */
    get submittableDataToken() {
        return JSON.stringify([
            this.localUnit.fullMale,
            this.localUnit.fullFemale,
            this.localUnit.part1Male,
            this.localUnit.part1Female,
            this.localUnit.part2Male,
            this.localUnit.part2Female,
            this.localUnit.part3Male,
            this.localUnit.part3Female,
            this.localUnit.fullTimeEquivalentMale,
            this.localUnit.fullTimeEquivalentFemale,
            this.localUnit.censusReasonText,
            this.localUnit.hasNoAhvObligation
        ]);
    }
}
