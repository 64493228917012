var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationProvider", {
    ref: "provider",
    attrs: { name: _vm.label, rules: _vm.validate, vid: _vm.id },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var errors = ref.errors
          var required = ref.required
          return [
            _c(
              "div",
              { class: { "form-group": true, "has-warning": errors[0] } },
              [
                _c(
                  "label",
                  {
                    staticClass: "col-sm-3 control-label",
                    attrs: { id: _vm.id + "-group", for: _vm.id }
                  },
                  [
                    _vm._v(_vm._s(_vm.$t(_vm.label)) + " "),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: required,
                            expression: "required"
                          }
                        ]
                      },
                      [_vm._v("*")]
                    )
                  ]
                ),
                _c("div", { staticClass: "col-sm-9" }, [
                  _c(
                    "select",
                    {
                      key: _vm.id,
                      ref: "inputfield",
                      staticClass: "form-control",
                      attrs: {
                        id: _vm.id,
                        disabled: _vm.disabled,
                        name: _vm.id
                      },
                      domProps: { value: _vm.value },
                      on: {
                        change: function($event) {
                          return _vm.onChange($event.target.value)
                        },
                        focusout: _vm.onFocusout
                      }
                    },
                    _vm._l(_vm.items, function(obj, index) {
                      return _c(
                        "option",
                        {
                          key: index,
                          attrs: {
                            name: _vm.id,
                            id: _vm.id + "-" + obj.value,
                            checked: obj.selected,
                            disabled: obj.disabled || _vm.disabled
                          },
                          domProps: { value: obj.value }
                        },
                        [_vm._v(_vm._s(_vm.$t(obj.text)))]
                      )
                    }),
                    0
                  ),
                  errors[0]
                    ? _c(
                        "span",
                        {
                          staticClass: "help-block",
                          attrs: { id: _vm.id + "-error", role: "alert" }
                        },
                        [_vm._v(" " + _vm._s(errors[0]) + " ")]
                      )
                    : _vm._e()
                ])
              ]
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }