var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "alert alert-warning", attrs: { role: "alert" } },
        [
          _c("span", { staticClass: "sr-only" }, [_vm._v("warning:")]),
          _c("div", {
            domProps: {
              innerHTML: _vm._s(_vm.$t("page_enconf_print_reminder"))
            }
          })
        ]
      ),
      this.caseId
        ? _c("UnFrame", {
            attrs: {
              url: _vm.BackendPrintoutUrl,
              "select-element-id": "printDisplay"
            },
            on: {
              complete: function($event) {
                return _vm.printAfter(1000)
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }