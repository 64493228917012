/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable camelcase */
import Vue from 'vue';
import i18n from '@/plugins/i18n';

import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import {
    is,
    regex,
    required,
    email,
    min,
    max,
    numeric,
    double,
    confirmed,
    min_value,
    max_value
} from 'vee-validate/dist/rules';

/** Validierungsfunktionen für Vee-Validate
 * @devdoc Der Name des Feldes wird aktuell nicht in allen Meldung ausgegeben. Falls gewünscht, müssen die Ressourcen-Texte einen Platzhalter beinhalten

*/
export default class VeeValidate {
    constructor() {
        this.extendIs();
        this.extendRegex();
        this.extendRequired();
        this.extendEmail();
        this.extendMin();
        this.extendMax();
        this.extendMinValue();
        this.extendMaxValue();
        this.extendNumeric();
        this.extendDouble();
        this.extendDecimalNumber();
        this.extendConfirmed();

        //Mit Angabe eines Custom-Labels
        this.extendLabeledMinValue();
        this.extendLabeledMaxValue();
        this.extendLabeledRequired();
        this.extendLabeledRegex();

        this.initializeComponents();
    }

    private extendIs() {
        extend('is', {
            ...is,
            message: (_field_, args) => i18n.t('validations.is', [i18n.t(_field_), args.other]) as string
        });
    }

    /** Validierung mit einem Regex
     */
    private extendRegex() {
        extend('regex', {
            ...regex,
            message: (_field_, args) => i18n.t('allg_validate_invalidchars', [i18n.t(_field_), args.regex]) as string
        });
    }

    private extendMin() {
        //HINT: Für Textfelder wird die Angabe als Textlänge angenommen, für numerische Felder als Wert.
        extend('min', {
            ...min,
            message: (_field_, args) => i18n.t('allg_validate_min_length', [i18n.t(_field_), args.length]) as string
        });
    }

    private extendMax() {
        //HINT: Für Textfelder wird die Angabe als Textlänge angenommen, für numerische Felder als Wert.
        extend('max', {
            ...max,
            message: (_field_, args) => i18n.t('allg_validate_max_length', [i18n.t(_field_), args.length]) as string
        });
    }

    private extendMinValue() {
        extend('min_value', {
            ...min_value,
            message: (_field_, args) => i18n.t('allg_validate_min_value', [i18n.t(_field_), args.min]) as string
        });
    }

    private extendMaxValue() {
        extend('max_value', {
            ...max_value,
            message: (_field_, args) => i18n.t('allg_validate_max_value', [i18n.t(_field_), args.max]) as string
        });
    }

    private extendNumeric() {
        extend('numeric', {
            ...numeric,
            message: (_field_, args) => i18n.t('allg_validate_number', [i18n.t(_field_), args.length]) as string
        });
    }

    /** Valdiert einen Double-Wert */
    private extendDouble() {
        extend('double', {
            ...double,
            message: (_field_, args) => i18n.t('allg_validate_number', [i18n.t(_field_), args.length]) as string
        });
    }

    /** Validiert eine Dezimalzahl, mit zusätlich erlaubtem Ganzzahligem Wert (ohne Dezimalstellen) */
    private extendDecimalNumber() {
        extend('decimalNumber', {
            message: (_field_, args) => i18n.t('allg_validate_number', [i18n.t(_field_), args.length]) as string,
            validate: (value, params) => {
                const isDouble = double.validate(value, params);
                if (isDouble === false) {
                    //Als numeric (Ganzzahl)
                    return numeric.validate(value);
                }
                return isDouble;
            }
        });
    }
    private extendRequired() {
        extend('required', {
            ...required,
            message: _field_ => i18n.t('allg_validate_pflichtfeld', [i18n.t(_field_)]) as string
        });
    }

    private extendEmail() {
        extend('email', {
            ...email,
            message: _field_ => i18n.t('allg_validate_email', [i18n.t(_field_)]) as string
        });
    }

    private extendConfirmed() {
        extend('confirmed', {
            ...confirmed,
            message: (_field_, args) =>
                i18n.t('allg_validate_password_not_equal', [i18n.t(_field_), args.other]) as string
        });
    }

    /** Validierung auf einen Maximalwert, mit Angabe eines eigenen Labels (ohne Platzhalter) für die Fehlermeldung.
     */
    private extendLabeledMaxValue() {
        extend('max_value_labeled', {
            ...max_value,
            message: (_field_, args) => i18n.t(args.label, [i18n.t(_field_), args.max]) as string,
            params: ['max', 'label']
        });
    }

    /** Validierung auf einen Minimalwert, mit Angabe eines eigenen Labels (ohne Platzhalter) für die Fehlermeldung.
     */
    private extendLabeledMinValue() {
        extend('min_value_labeled', {
            ...min_value,
            message: (_field_, args) => i18n.t(args.label, [i18n.t(_field_), args.min]) as string,
            params: ['min', 'label']
        });
    }

    /** Validierung auf ein Pflichtfeld, mit Angabe eines eigenen Labels (ohne Platzhalter) für die Fehlermeldung.
     */
    private extendLabeledRequired() {
        extend('required_labeled', {
            ...required,
            message: (_field_, args) => i18n.t(args.label) as string,
            params: ['label']
        });
    }

    /** Validierung auf einen Regex, mit Angabe eines eigenen Labels (ohne Platzhalter) für die Fehlermeldung.
     */
    private extendLabeledRegex() {
        extend('regex_labeled', {
            ...regex,
            message: (_field_, args) => i18n.t(args.label) as string,
            params: ['regex', 'label'],
            validate: (value, params) => {
                const regex = params['regex'];
                return new RegExp(regex).test(value);
            }
        });
    }

    private initializeComponents() {
        Vue.component('ValidationObserver', ValidationObserver);
        Vue.component('ValidationProvider', ValidationProvider);
    }
}
