

































































import Vue from 'vue';
import { namespace } from 'vuex-class';
import { Component } from 'vue-property-decorator';
import { ENTRY_WIZARD_CONTACT } from '@/router';
import { InfoStepIndex } from '../views/Wizard.vue';
import StepHeader from './StepHeader.vue';

import { NAME } from '../store/index';
const modul = namespace(NAME);

//Getters und Actions
import { CASE_FULL_DATA } from '../store/getters';
import { SUBMIT_CASE_STEPINFO } from '../store/actions';
import { CaseStepInfoViewModel, ICaseFullDataViewModel } from '@/api-viewmodel';

//Footer-Bar und -Buttons
import { NAME as NAME_FOOTERBAR } from '@/store/modules/footerBar';
const footerBarModul = namespace(NAME_FOOTERBAR);
import { NEW_BUTTON, CLOSE } from '@/store/modules/footerBar/actions';
import { FooterButtonType, IFooterButton } from '@/store/modules/footerBar/state';

@Component({
    components: {
        StepHeader
    }
})

/** Info-Step im Entry Wizard
 * @remarks Implementiert den {@link:https://collaboration.nowhow.ch/erst/foswiki/bin/view/Erst/DatenErfassungInfoScreen}
 */
export default class StepInfo extends Vue {
    //Getters
    @modul.Getter(CASE_FULL_DATA) caseFullData!: ICaseFullDataViewModel;

    //Actions
    @modul.Action(SUBMIT_CASE_STEPINFO) submitStepInfo!: Function;
    @footerBarModul.Action(NEW_BUTTON) newFooterBar!: Function;
    @footerBarModul.Action(CLOSE) closeFooterBar!: Function;

    mounted() {
        //Den aktuellen Step sofort melden
        this.submitStepInfo({
            caseId: this.caseFullData.caseId,
            data: new CaseStepInfoViewModel(InfoStepIndex, null)
        });

        this.showStepNavigation();
    }

    /** Liefert den Namen der Anwendung aus den Ressourcen */
    applicationName(): string {
        return this.$t('allg_application_name').toString();
    }

    /** Gibt an, ob der aktuelle Fall ein öffentlich-rechtliches Unternehmen betrifft */
    isOeffentlichRechtlich(): boolean {
        // HINT: Der strikte Vergleich mit === funktioniert hier aus unbekannten Gründen nicht (immer false)
        // eslint-disable-next-line eqeqeq
        return this.caseFullData?.caseClassificationCd == 2;
    }

    /** Gibt an, ob der aktuelle Fall ein privat-rechtliches Unternehmen betrifft */
    isPrivatRechtlich(): boolean {
        // HINT: Der strikte Vergleich mit === funktioniert hier aus unbekannten Gründen nicht (immer false)
        // eslint-disable-next-line eqeqeq
        return this.caseFullData?.caseClassificationCd == 1;
    }

    /** Definiert die Wizard-Navigations-Buttons und zeigt diese an */
    showStepNavigation() {
        console.debug('StepInfo.vue::showStepNavigation');

        const buttonRight: Array<IFooterButton> = [
            {
                onClick: () => {
                    this.gotoNextStep();
                },
                label: 'btn_wzrd_next',
                type: FooterButtonType.primary
            }
        ];
        this.closeFooterBar();
        this.newFooterBar({ buttonRight });
    }

    /** Navigiert zum nächsten Step im Entry-Wizard. */
    gotoNextStep() {
        this.$router.push({ name: ENTRY_WIZARD_CONTACT });
    }
}
