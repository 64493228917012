var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { "form-group": true, "has-warning": _vm.hasErrors } },
    [
      _c(
        "label",
        {
          staticClass: "col-sm-3 control-label",
          attrs: { id: _vm.id + "-group" }
        },
        [
          _vm._v(_vm._s(_vm.$t(_vm.label)) + " "),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.required,
                  expression: "required"
                }
              ]
            },
            [_vm._v("*")]
          )
        ]
      ),
      _c(
        "div",
        { staticClass: "col-sm-9" },
        [
          _vm._l(_vm.items, function(obj, index) {
            return _c(
              "div",
              { key: index, class: { "checkbox-inline": _vm.inline } },
              [
                _c("NowhowCheckbox", {
                  attrs: { label: obj.text, id: _vm.id + "-" + obj.value },
                  on: { change: _vm.onChange, focusout: _vm.onFocusout },
                  model: {
                    value: obj.selected,
                    callback: function($$v) {
                      _vm.$set(obj, "selected", $$v)
                    },
                    expression: "obj.selected"
                  }
                })
              ],
              1
            )
          }),
          _c("ValidationProvider", {
            ref: "provider",
            attrs: {
              name: _vm.label,
              rules: _vm.validate,
              vid: _vm.id,
              "skip-if-empty": !_vm.required
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var errors = ref.errors
                  return [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.value,
                          expression: "value"
                        }
                      ],
                      attrs: { type: "hidden" },
                      domProps: { value: _vm.value },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.value = $event.target.value
                        }
                      }
                    }),
                    errors[0]
                      ? _c(
                          "span",
                          {
                            staticClass: "help-block",
                            attrs: { id: _vm.id + "-error", role: "alert" }
                          },
                          [_vm._v(" " + _vm._s(errors[0]) + " ")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }