import { ICaseFullDataViewModel, CaseFullDataViewModel, CaseApprovalDto, ICaseApprovalDto } from '@/api-viewmodel';

/** Definiert den Zustand für die Bearbeitung im Entry-Wizard
 * @interface
 */
export interface IEntryState {
    /** Die Validations-Meldungen zum aktuell bearbeiteten Fall
     * @remarks Diese Meldungen werden im Confirm-Step via API abgeholt
     */
    caseValidationMessages: string[];

    /**Die vollenDaten zum aktuell bearbeiteten Fall*/
    caseFullData: ICaseFullDataViewModel;

    /** Die Zustimmung zum Abschluss des Falles
     * @remarks Enthält auch die E-Mail-Adresse für den allfälligen nächsten Kontakt.
     */
    caseApproval: ICaseApprovalDto;
}

/** Stellt den Zustand für die Bearbeitung im Entry-Wizard zur Verfügung.
 */
export const state: IEntryState = {
    caseValidationMessages: null,
    caseFullData: new CaseFullDataViewModel(),
    caseApproval: new CaseApprovalDto()
};
