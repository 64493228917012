var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.value ? "visible overlay-backdrop" : "overlay-backdrop" },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          {
            staticClass: "col col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3"
          },
          [
            _c(
              "div",
              {
                staticClass: "overlay-window well clearfix",
                attrs: { role: "overlay" }
              },
              [
                _c("div", { staticClass: "clearfix" }, [
                  _c(
                    "form",
                    {
                      staticClass: "form-horizontal",
                      attrs: { role: "form" },
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.close()
                        }
                      }
                    },
                    [
                      _c("fieldset", [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("span", { staticClass: "sr-only" }, [
                              _vm._v("info:")
                            ]),
                            _vm.text
                              ? _c("div", [_vm._v(_vm._s(_vm.text))])
                              : _vm._e(),
                            !_vm.text
                              ? _vm._t("default", [
                                  _vm._v(" Dies ist der Standard Overlay Body ")
                                ])
                              : _vm._e(),
                            _c("hr"),
                            _c("p")
                          ],
                          2
                        )
                      ]),
                      _c("fieldset", [
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              attrs: {
                                type: "submit",
                                name: "OverlayOkButton",
                                id: _vm.id,
                                role: "button"
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("allg_ok")) + " ")]
                          )
                        ])
                      ])
                    ]
                  )
                ])
              ]
            )
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }