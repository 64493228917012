var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "header-notification" }, [
    _vm.alertVisible
      ? _c(
          "div",
          { class: _vm.cssClass, attrs: { role: "alert" } },
          [
            _c("span", { staticClass: "sr-only" }, [
              _vm._v(_vm._s(_vm.alertType) + ":")
            ]),
            _vm._l(this.alertTextLines(), function(element, index) {
              return _c("span", { key: element }, [
                index !== 0 ? _c("br") : _vm._e(),
                _vm._v(" " + _vm._s(element) + " ")
              ])
            }),
            _vm.isDismissible
              ? _c("span", {
                  staticClass: "glyphicon pull-right glyphicon-remove",
                  on: { click: _vm.close }
                })
              : _vm._e()
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }