







































































//Imports für dieses Modul
import Vue from 'vue';
import { namespace } from 'vuex-class';
import { NAME } from '../store/index';
import { NAME as NAME_VALIDATION } from '@/store/modules/validation';
import { Component, Prop } from 'vue-property-decorator';
import NowhowTextbox from '@/components/NowhowTextbox.vue';
import NowhowTextline from '@/components/NowhowTextline.vue';
import { GET_VALIDATION_KEY } from '@/store/modules/validation/getters';
import { ACCOUNT_CONTACT } from '@/router';

const modul = namespace(NAME);
const validationModul = namespace(NAME_VALIDATION);

//Getters und Actions
import { SURVEY_NAME } from '../store/getters';
import { RegistrationDataViewModel } from '@/api-viewmodel';
import { REGISTER, GET_ASSIGNED_SURVEY_NAME } from '../store/actions';

@Component({
    components: {
        NowhowTextbox,
        NowhowTextline
    }
})

/** Implementiert den Schritt 1 der Registrierung: Eingabe des Zugriffscodes
 * @devdoc Enthält diversen Javascript Code im Zusammenhang mit der Eingabe des Access-Codes
 */
export default class Step2Password extends Vue {
    private ACCOUNT_CONTACT = ACCOUNT_CONTACT;

    registrationData = new RegistrationDataViewModel();
    @Prop({ required: false, default: '' }) private accessCode!: string;

    @modul.Getter(SURVEY_NAME) surveyName!: string;
    @modul.Action(GET_ASSIGNED_SURVEY_NAME) getAssignedSurveyName!: Function;
    @modul.Action(REGISTER) register!: Function;

    @validationModul.Getter(GET_VALIDATION_KEY) getValidation!: (key: string) => string;

    /** Wrapper für eine Form-Submit-Aktion, um das Standard-Submit-Verhalten im Zusammenspiel mit vee-validate zu unterbinden.
     * @devdoc Wird hier benötigt, da sich die zu versendenden Daten nicht im Store befinden.
     */
    private registerWrapper() {
        //Accesscode aus dem Model dieser Komponente in die Registrations-Daten übernehmen, da nicht hier vom Benutzer explizit eingegeben
        this.registrationData.accessCode = this.accessCode;
        this.register(this.registrationData);
    }
}
