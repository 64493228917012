var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { staticClass: "noselect" }, [
    this.isServiceNavigationRequired()
      ? _c("div", { staticClass: "clearfix hidden-print" }, [
          _c("section", { staticClass: "nav-services clearfix" }, [
            _c("h2", { staticClass: "sr-only" }, [_vm._v("Sprachwahl")]),
            _c("nav", { staticClass: "nav-lang" }, [
              _c("ul", [
                _c("li", [
                  _c(
                    "a",
                    {
                      class: _vm.DeCss,
                      on: {
                        click: function($event) {
                          return _vm.setLanguage("de")
                        }
                      }
                    },
                    [_vm._v("DE")]
                  )
                ]),
                _c("li", [
                  _c(
                    "a",
                    {
                      class: _vm.FrCss,
                      on: {
                        click: function($event) {
                          return _vm.setLanguage("fr")
                        }
                      }
                    },
                    [_vm._v("FR")]
                  )
                ]),
                _c("li", [
                  _c(
                    "a",
                    {
                      class: _vm.ItCss,
                      on: {
                        click: function($event) {
                          return _vm.setLanguage("it")
                        }
                      }
                    },
                    [_vm._v("IT")]
                  )
                ])
              ])
            ]),
            _c("nav", { staticClass: "nav-service" }, [
              _c("ul", [
                _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      { attrs: { to: { name: _vm.ACCOUNT_CONTACT } } },
                      [_vm._v(" " + _vm._s(_vm.$t("nav_contact")) + " ")]
                    )
                  ],
                  1
                ),
                _vm.loggedIn
                  ? _c("li", [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: { click: _vm.handleLogout }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("nav_logout")) + " ")]
                      )
                    ])
                  : _vm._e(),
                !_vm.loggedIn
                  ? _c(
                      "li",
                      [
                        _c(
                          "router-link",
                          { attrs: { to: { name: _vm.ACCOUNT_LOGIN } } },
                          [_vm._v(" " + _vm._s(_vm.$t("nav_login")) + " ")]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.loggedIn
                  ? _c(
                      "li",
                      [
                        _c(
                          "router-link",
                          { attrs: { to: { name: _vm.ACCOUNT_REGISTER } } },
                          [_vm._v(" " + _vm._s(_vm.$t("nav_register")) + " ")]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ])
            ])
          ])
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "clearfix mod mod-logo hidden-print" },
      [
        _c("router-link", {
          attrs: { to: { name: _vm.ENTRY_WIZARD_INFO } },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(ref) {
                var href = ref.href
                var navigate = ref.navigate
                return [
                  _c(
                    "a",
                    {
                      staticClass: "brand hidden-xs",
                      attrs: { href: href, id: "logo", title: "Startseite" },
                      on: { click: navigate }
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("../../public/swiss-styleguide/img/logo-CH.svg")
                        }
                      }),
                      _c("h1", [_vm._v(_vm._s(_vm.$t("allg_bfs_name")))])
                    ]
                  )
                ]
              }
            }
          ])
        }),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.AppEnviroment !== "production",
                expression: "AppEnviroment !== 'production'"
              }
            ],
            staticClass: "environmentinfo pull-left"
          },
          [_vm._v(_vm._s(_vm.AppEnviroment))]
        )
      ],
      1
    ),
    _c("div", { staticClass: "clearfix mod mod-logo visible-print" }, [
      _c("span", { staticClass: "brand hidden-xs", attrs: { id: "logo" } }, [
        _c("img", {
          attrs: {
            src: require("../../public/swiss-styleguide/img/logo-CH.svg")
          }
        }),
        _c("h1", [_vm._v(_vm._s(_vm.$t("allg_bfs_name")))])
      ]),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.AppEnviroment !== "production",
              expression: "AppEnviroment !== 'production'"
            }
          ],
          staticClass: "environmentinfo pull-left"
        },
        [_vm._v(_vm._s(_vm.AppEnviroment))]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }