var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationObserver", { ref: "localUnitActivityObserver" }, [
    _c(
      "form",
      { staticClass: "form-horizontal", attrs: { role: "form" } },
      [
        _c(
          "fieldset",
          [
            _c("legend", {
              directives: [
                {
                  name: "t",
                  rawName: "v-t",
                  value: "edit_lu_tit_lu_activity",
                  expression: "'edit_lu_tit_lu_activity'"
                }
              ]
            }),
            _c("NowhowDropdownYesNoNull", {
              directives: [{ name: "focus", rawName: "v-focus" }],
              attrs: {
                id: "localUnitActivityIsActive",
                name: _vm.$t("lu_is_active"),
                "label-yes": "allg_ja_since_dots",
                "label-no": "allg_nein",
                label: "lu_is_active",
                validate: _vm.getValidation(
                  "activityDataObject.isActivityBegin"
                )
              },
              model: {
                value: _vm.activity.isActivityBegin,
                callback: function($$v) {
                  _vm.$set(_vm.activity, "isActivityBegin", $$v)
                },
                expression: "activity.isActivityBegin"
              }
            }),
            _c("NowhowDropdown", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isInactive,
                  expression: "isInactive"
                }
              ],
              attrs: {
                id: "localUnitActivityInactivityReason",
                items: _vm.dropdownInactivityReasonItems,
                label: "page_muc_lunode_reason",
                validate:
                  _vm.getValidation("activityDataObject.inactivityReason") +
                  _vm.validationIsInactivityReasonRequired
              },
              model: {
                value: _vm.activity.inactivityReason,
                callback: function($$v) {
                  _vm.$set(_vm.activity, "inactivityReason", $$v)
                },
                expression: "activity.inactivityReason"
              }
            }),
            _vm.isDateRequired
              ? [
                  _c("NowhowUInt32Box", {
                    attrs: {
                      id: "localUnitActivityDateMonth",
                      label: "allg_date_monthpart",
                      validate:
                        _vm.getValidation(
                          "activityDataObject.activityDateMonth"
                        ) +
                        "|" +
                        _vm.monthValidation +
                        "|required|"
                    },
                    model: {
                      value: _vm.activity.activityDateMonth,
                      callback: function($$v) {
                        _vm.$set(_vm.activity, "activityDateMonth", $$v)
                      },
                      expression: "activity.activityDateMonth"
                    }
                  }),
                  _c("NowhowUInt32Box", {
                    attrs: {
                      id: "localUnitActivityDateYear",
                      label: "allg_date_yearpart",
                      validate:
                        _vm.getValidation(
                          "activityDataObject.activityDateYear"
                        ) +
                        "|" +
                        _vm.yearValidation +
                        "|required"
                    },
                    model: {
                      value: _vm.activity.activityDateYear,
                      callback: function($$v) {
                        _vm.$set(_vm.activity, "activityDateYear", $$v)
                      },
                      expression: "activity.activityDateYear"
                    }
                  })
                ]
              : _vm._e(),
            _vm.isActive
              ? _c("NowhowTextarea", {
                  attrs: {
                    id: "localUnitActivityDescription",
                    label: "lu_noga_descr",
                    rows: 2,
                    validate:
                      _vm.getValidation(
                        "localUnitDataObject.activityDescription"
                      ) + "|required"
                  },
                  model: {
                    value: _vm.localUnit.activityDescription,
                    callback: function($$v) {
                      _vm.$set(_vm.localUnit, "activityDescription", $$v)
                    },
                    expression: "localUnit.activityDescription"
                  }
                })
              : _vm._e(),
            _c("NowhowDropdownYesNoNull", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isInactive,
                  expression: "!isInactive"
                }
              ],
              attrs: {
                id: "localUnitActivityIsMerchant",
                name: _vm.$t("lu_is_merchant"),
                "label-yes": "allg_ja",
                "label-no": "allg_nein",
                label: "lu_is_merchant",
                validate: _vm.getValidation("activityDataObject.isMerchant")
              },
              model: {
                value: _vm.activity.isMerchant,
                callback: function($$v) {
                  _vm.$set(_vm.activity, "isMerchant", $$v)
                },
                expression: "activity.isMerchant"
              }
            }),
            _vm.isMerchant
              ? _c("NowhowDropdown", {
                  attrs: {
                    id: "localUnitActivityMerchantKind",
                    items: _vm.dropdownMerchantKindItems,
                    label: "lu_merchant_kind",
                    validate:
                      _vm.getValidation("activityDataObject.merchantKind") +
                      "|required"
                  },
                  model: {
                    value: _vm.activity.merchantKind,
                    callback: function($$v) {
                      _vm.$set(_vm.activity, "merchantKind", $$v)
                    },
                    expression: "activity.merchantKind"
                  }
                })
              : _vm._e(),
            _c("NowhowDropdownYesNoNull", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isInactive,
                  expression: "!isInactive"
                }
              ],
              attrs: {
                id: "localUnitActivityIsSeasonalActivity",
                name: _vm.$t("lu_is_seasonal_activity"),
                "label-yes": "allg_ja",
                "label-no": "allg_nein",
                label: "lu_is_seasonal_activity",
                validate: _vm.getValidation(
                  "activityDataObject.isSeasonActivity"
                )
              },
              model: {
                value: _vm.activity.isSeasonActivity,
                callback: function($$v) {
                  _vm.$set(_vm.activity, "isSeasonActivity", $$v)
                },
                expression: "activity.isSeasonActivity"
              }
            }),
            _vm.isSeasonActivity
              ? _c("NowhowSeasonPicker", {
                  attrs: {
                    id: "localUnitActivitySeasonalActivityPeriodPicker",
                    label: "lu_seasonal_activity_period",
                    items: _vm.checkboxSeasonalActivityPeriodItems,
                    validate: "required"
                  },
                  model: {
                    value: _vm.activity.seasonSelection,
                    callback: function($$v) {
                      _vm.$set(_vm.activity, "seasonSelection", $$v)
                    },
                    expression: "activity.seasonSelection"
                  }
                })
              : _vm._e(),
            _c("NowhowDropdown", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isInactive,
                  expression: "!isInactive"
                }
              ],
              attrs: {
                id: "localUnitActivityCreationReason",
                items: _vm.dropdownCreationReasonItems,
                label: "lu_creation_question",
                validate:
                  _vm.getValidation(
                    "activityDataObject.creationReasonSelection"
                  ) + "|required"
              },
              model: {
                value: _vm.activity.creationReasonSelection,
                callback: function($$v) {
                  _vm.$set(_vm.activity, "creationReasonSelection", $$v)
                },
                expression: "activity.creationReasonSelection"
              }
            }),
            _vm.isCreationReasonDescriptionRequired
              ? _c("NowhowTextarea", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isInactive,
                      expression: "!isInactive"
                    }
                  ],
                  attrs: {
                    id: "localUnitActivityOtherCreationReasonDescription",
                    label: "allg_beschreibung",
                    rows: 2,
                    validate:
                      _vm.getValidation(
                        "activityDataObject.creationReasonDescription"
                      ) + "|required"
                  },
                  model: {
                    value: _vm.activity.creationReasonDescription,
                    callback: function($$v) {
                      _vm.$set(_vm.activity, "creationReasonDescription", $$v)
                    },
                    expression: "activity.creationReasonDescription"
                  }
                })
              : _vm._e(),
            _vm.isPredecessorRequired
              ? _c("NowhowTextarea", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isInactive,
                      expression: "!isInactive"
                    }
                  ],
                  attrs: {
                    id: "localUnitActivityPredecessorAddress",
                    label: "lu_predecessor_address",
                    rows: 3,
                    validate:
                      _vm.getValidation(
                        "activityDataObject.nameActivityBefore"
                      ) + "|required"
                  },
                  model: {
                    value: _vm.activity.nameActivityBefore,
                    callback: function($$v) {
                      _vm.$set(_vm.activity, "nameActivityBefore", $$v)
                    },
                    expression: "activity.nameActivityBefore"
                  }
                })
              : _vm._e(),
            _c("NowhowDropdown", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isInactive,
                  expression: "!isInactive"
                }
              ],
              attrs: {
                id: "localUnitActivityLocalUnitKind",
                items: _vm.dropdownKindItems,
                label: "lu_kind_question",
                validate: _vm.getValidation("activityDataObject.kind")
              },
              model: {
                value: _vm.activity.kind,
                callback: function($$v) {
                  _vm.$set(_vm.activity, "kind", $$v)
                },
                expression: "activity.kind"
              }
            }),
            _c("NowhowDropdown", {
              attrs: {
                id: "localUnitActivityFoundersComposition",
                items: _vm.dropdownFoundersCompositionItems,
                label: "lu_founders_question",
                validate: _vm.getValidation(
                  "activityDataObject.foundersComposition"
                )
              },
              model: {
                value: _vm.activity.foundersComposition,
                callback: function($$v) {
                  _vm.$set(_vm.activity, "foundersComposition", $$v)
                },
                expression: "activity.foundersComposition"
              }
            })
          ],
          2
        ),
        _c("NowhowRequiredInfoline")
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }