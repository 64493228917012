



















import Vue from 'vue';
import { namespace } from 'vuex-class';
import { Component } from 'vue-property-decorator';
import UnFrame from './UnFrame.vue';

import { NAME } from '../store/index';
const modul = namespace(NAME);

//Getters und Actions
import { CASE_ID } from '../store/getters';
import { GET_CASE_FULL_DATA } from '../store/actions';

//Footer-Bar und -Buttons
import { NAME as NAME_FOOTERBAR } from '@/store/modules/footerBar';
const footerBarModul = namespace(NAME_FOOTERBAR);
import { NEW_BUTTON, CLOSE } from '@/store/modules/footerBar/actions';
import { FooterButtonType, IFooterButton } from '@/store/modules/footerBar/state';

@Component({
    components: { UnFrame }
})

/** Print im Entry Wizard
 * @remarks Implementiert den {@link:https://collaboration.nowhow.ch/erst/foswiki/bin/view/Erst/DatenErfassungDruckAnsichtScreen}
 * Führt ein automatisches Print aus, wenn die Falldaten verfügbar sind, gemäss Anforderung 003
 */
export default class Printout extends Vue {
    protected WEBFORMS_BASE_URL: string | undefined = process.env.VUE_APP_WEBFORMS_BACKEND_URL;

    constructor() {
        super();
        //Hint den Fallback zentral lösen
        if (!this.WEBFORMS_BASE_URL) {
            //Fallback für den Fall dass keine Backend-URL verfügbar ist
            const url = window.location.href;
            const searchParam = 'redesign/';
            const length = url.toLowerCase().indexOf(searchParam) - 1; //ohne 'redesign/' Pfad
            this.WEBFORMS_BASE_URL = url.substr(0, length);
            console.debug('Printout::constructor (with fallback URL)', this.WEBFORMS_BASE_URL);
        } else {
            console.debug('Printout::constructor', this.WEBFORMS_BASE_URL);
        }
    }

    @modul.Getter(CASE_ID) caseId!: bigint;
    @modul.Action(GET_CASE_FULL_DATA) getCaseFullData!: Function;

    @footerBarModul.Action(NEW_BUTTON) newFooterBar!: Function;
    @footerBarModul.Action(CLOSE) closeFooterBar!: Function;

    mounted() {
        console.debug('Printout.vue::mounted');

        this.showStepNavigation();

        //Daten zum anzuzeigenden Fall holen (Da diese Seite ausserhalb des Wizards aufgerufen wird)
        //Für BFS-Benutzer via zwingenden URL-Query-Parameter holen
        //Für Extere Benutzer (falls ohne URL-Query-Parameter) automatisch serverseitig, anhand der Zuordnung via Benutzername holen
        this.getCaseFullData(this.$route.query.CaseId);
    }

    /** URL für den Inhalt der Druckansicht für den Entry-Wizard
     * @remarks Die Druckansicht wird fast unverändert von der bisherigen Web-Forms-Lösung übernommen
     */
    private get BackendPrintoutUrl() {
        return this.WEBFORMS_BASE_URL + '/entry/DataSetPrintableView.aspx?CaseId=' + this.caseId;
    }

    /** Definiert die Wizard-Navigations-Buttons und zeigt diese an */
    showStepNavigation() {
        console.debug('StepInfo.vue::showStepNavigation');

        const buttonLeft: Array<IFooterButton> = [
            {
                onClick: () => {
                    window.close();
                },
                label: 'page_enconf_print_close',
                type: FooterButtonType.secondary
            }
        ];

        const buttonCenter: Array<IFooterButton> = [
            {
                onClick: () => {
                    this.print();
                },
                label: 'page_enconf_print_title',
                type: FooterButtonType.primary
            }
        ];
        this.closeFooterBar();
        this.newFooterBar({ buttonLeft, buttonCenter });
    }

    /** Löst den Druck aus
     */
    print() {
        console.debug('Printout::print');
        window.print();
    }

    /** Löst den Druck mit leicher Verzögerung aus
     * @devdoc mit leichter Verzögerung, um den Seitenaufbau, insbesondere die Resourcen-Darstellung zu ermöglichen
     */
    printAfter(delayInMilliseconds: number) {
        setTimeout(function() {
            print();
        }, delayInMilliseconds);
    }
}
