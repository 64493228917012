import { MutationTree } from 'vuex';
import { IAccountViewModel, IContactInfoViewModel, IRegistrationInfoViewModel } from '@/api-viewmodel';
import { IAccountState } from './state';

/** Weist ein Benutzerprofil zu */
export const MAPUSERPROFILE = 'mapUserProfile';

/** Weist einen Survey-Namen zu */
export const MAPSURVEYNAME = 'mapSurveyName';

/** Weist das Resultat (Erfolg) einer Registration zu */
export const MAPREGISTRATIONRESULT = 'mapRegistrationResult';

/** Weist das Resultat (Erfolg) vom Check isLoggedIn dem State zu */
export const MAPISLOGGEDIN = 'mapIsLoggedIn';

/** Weist Kontakt-Informationen zu */
export const MAPCONTACTINFO = 'mapContactInfo';

export const mutations: MutationTree<IAccountState> = {
    [MAPUSERPROFILE](state, value: IAccountViewModel) {
        console.debug('username', value.username);
        console.debug('roles', value.roles);
        state.userProfile = value;
        state.isLoggedIn = (value.username ?? '').length > 0;
    },
    [MAPSURVEYNAME](state, value: IRegistrationInfoViewModel) {
        state.registrationInfo = value;
    },
    [MAPCONTACTINFO](state, value: IContactInfoViewModel) {
        state.contactInfo = value;
    },
    [MAPISLOGGEDIN](state, value: boolean) {
        state.isLoggedIn = value;
    },
    [MAPREGISTRATIONRESULT](state, value: boolean) {
        state.registrationResult = value;
    }
};
