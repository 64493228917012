var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationProvider", {
    ref: "provider",
    attrs: { name: _vm.label, rules: _vm.validate, vid: _vm.id },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var errors = ref.errors
          var required = ref.required
          return [
            _c(
              "div",
              { class: { "form-group": true, "has-warning": errors[0] } },
              [
                _vm.formLabel
                  ? _c(
                      "label",
                      {
                        staticClass: "col-sm-3 control-label",
                        attrs: { for: _vm.id }
                      },
                      [
                        _vm._v(" " + _vm._s(_vm.$t(_vm.formLabel)) + " "),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: required,
                                expression: "required"
                              }
                            ]
                          },
                          [_vm._v("*")]
                        )
                      ]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    class: _vm.formLabel
                      ? "col-sm-9"
                      : "col-sm-offset-3 col-sm-9"
                  },
                  [
                    _c("div", { staticClass: "checkbox checkbox--left" }, [
                      _c(
                        "label",
                        { attrs: { id: _vm.id + "-label", for: _vm.id } },
                        [
                          _c("input", {
                            key: _vm.id,
                            ref: "checkbox",
                            staticClass: "form-control",
                            attrs: {
                              type: "checkbox",
                              id: _vm.id,
                              name: _vm.$t(_vm.label),
                              readonly: _vm.readonly,
                              disabled: _vm.disabled,
                              "data-cy": _vm.cy
                            },
                            domProps: {
                              checked: _vm.isSelected,
                              value: _vm.isSelected
                            },
                            on: {
                              change: _vm.onChange,
                              focusout: _vm.onFocusout
                            }
                          }),
                          _vm._v(" " + _vm._s(_vm.$t(_vm.label)) + " ")
                        ]
                      ),
                      errors[0]
                        ? _c(
                            "span",
                            {
                              staticClass: "help-block",
                              attrs: { id: _vm.id + "-error", role: "alert" }
                            },
                            [_vm._v(" " + _vm._s(errors[0]) + " ")]
                          )
                        : _vm._e()
                    ])
                  ]
                )
              ]
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }