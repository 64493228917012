








import Vue from 'vue';

import { Component } from 'vue-property-decorator';

/** Ein Zeile mit Label, welche eine Information zum Asterisk als Pflichtfeldangabe enthält.
 */
@Component
export default class NowhowRequiredInfoline extends Vue {}
