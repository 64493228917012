var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "component-header clearfix" },
    [
      _c("h1", { staticClass: "component-heading" }, [
        _vm._v(_vm._s(_vm.$t(_vm.headerLabel)) + _vm._s(_vm.headerText))
      ]),
      _c("div", { staticClass: "heading-label label label-help" }, [
        _c(
          "button",
          {
            staticClass: "btn",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                _vm.showHelp = !_vm.showHelp
              }
            }
          },
          [
            _c("span", {
              staticClass: "icon icon--info",
              attrs: { "aria-hidden": "true" }
            }),
            _c("span", { staticClass: "icon-addornment" }, [
              _vm._v(_vm._s(_vm.$t("allg_help")))
            ])
          ]
        )
      ]),
      _c(
        "NowhowModal",
        {
          attrs: {
            id: "modal-help",
            visible: _vm.showHelp,
            title: _vm.$t("allg_help")
          },
          on: { "update:visible": _vm.visibilityChanged }
        },
        [
          _c("template", { slot: "body" }, [
            _c(
              "form",
              { staticClass: "form-horizontal", attrs: { role: "form" } },
              [
                _c("fieldset", [
                  _c("div", { staticClass: "form-group" }, [
                    _c("span", {
                      domProps: { innerHTML: _vm._s(_vm.$t(_vm.helpLabel)) }
                    })
                  ])
                ]),
                _c("fieldset", [
                  _c("div", { staticClass: "form-group button-group" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { type: "button" },
                        on: { click: _vm.closeModal }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("allg_ok")) + " ")]
                    )
                  ])
                ])
              ]
            )
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }