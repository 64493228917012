var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("StepHeader", {
        attrs: {
          "header-label": "page_enconf_title",
          "help-label": "page_enconf_help"
        }
      }),
      _c("ValidationObserver", {
        ref: "observer",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var invalid = ref.invalid
              var handleSubmit = ref.handleSubmit
              return [
                _c(
                  "form",
                  {
                    staticClass: "form-horizontal",
                    attrs: { role: "form" },
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return handleSubmit(_vm.approvalWrapper)
                      }
                    }
                  },
                  [
                    _c("fieldset", [
                      _vm.hasCaseValidationMessages
                        ? _c("div", { staticClass: "alert has-warning" }, [
                            _c(
                              "div",
                              {
                                staticClass: "help-block",
                                attrs: { role: "alert" }
                              },
                              [
                                _c("span", { staticClass: "sr-only" }, [
                                  _vm._v("warning:")
                                ]),
                                _c(
                                  "ul",
                                  _vm._l(_vm.caseValidationMessages, function(
                                    element
                                  ) {
                                    return _c("li", { key: element }, [
                                      _vm._v(" " + _vm._s(element) + " ")
                                    ])
                                  }),
                                  0
                                )
                              ]
                            )
                          ])
                        : _vm._e(),
                      _c("hr"),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-offset-1 col-sm-1" }, [
                          _c("span", {
                            staticClass: "icon icon--print",
                            attrs: { alt: "info" }
                          })
                        ]),
                        _c("div", { staticClass: "col-sm-10" }, [
                          _c("p", { staticClass: "lead" }, [
                            _vm._v(_vm._s(_vm.$t("page_enconf_print_title")))
                          ]),
                          _c("p", [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("page_enconf_print_info")))
                            ])
                          ]),
                          _c(
                            "a",
                            {
                              staticClass: "icon icon--before icon--print",
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  return _vm.gotoPrintPage()
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("page_enconf_link_print")))]
                          ),
                          _c("br")
                        ])
                      ]),
                      _c("hr"),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-offset-1 col-sm-1" }, [
                          _c("span", {
                            staticClass: "icon icon--external",
                            attrs: { alt: "info" }
                          })
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-sm-10" },
                          [
                            _c("p", { staticClass: "lead" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("page_enconf_approve_title")) +
                                  " "
                              )
                            ]),
                            _c("NowhowCheckbox", {
                              staticClass: "form-group-unlabeled",
                              attrs: {
                                id: "caseConfirmationApprovalConsent",
                                label: "page_enconf_consent_text",
                                required: "true",
                                validate: { required: { allowFalse: false } }
                              },
                              model: {
                                value: _vm.caseApproval.consent,
                                callback: function($$v) {
                                  _vm.$set(_vm.caseApproval, "consent", $$v)
                                },
                                expression: "caseApproval.consent"
                              }
                            }),
                            _c("p", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$t("page_enconf_approve_info")
                                )
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-sm-offset-2 col-sm-10" },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary",
                                attrs: {
                                  type: "submit",
                                  name: "ConfirmButton",
                                  id: "ConfirmButton",
                                  role: "button",
                                  disabled:
                                    invalid || _vm.hasCaseValidationMessages
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("page_enconf_btn_confirm")) +
                                    " "
                                )
                              ]
                            )
                          ]
                        )
                      ])
                    ])
                  ]
                )
              ]
            }
          }
        ])
      }),
      _c(
        "NowhowModal",
        {
          attrs: {
            id: "modal-confirmation",
            visible: _vm.showConfirmationMessage,
            title: _vm.$t("page_enconf_title")
          },
          on: { "update:visible": _vm.visibilityChanged }
        },
        [
          _c("template", { slot: "body" }, [
            _c(
              "form",
              { staticClass: "form-horizontal", attrs: { role: "form" } },
              [
                _c("fieldset", [
                  _c("div", { staticClass: "form-group" }, [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(_vm.$t("page_enconf_overall_info"))
                      }
                    })
                  ])
                ]),
                _c("fieldset", [
                  _c("div", { staticClass: "form-group button-group" }, [
                    _c(
                      "button",
                      {
                        directives: [{ name: "focus", rawName: "v-focus" }],
                        staticClass: "btn btn-primary",
                        attrs: { type: "button" },
                        on: { click: _vm.closeModal }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("allg_ok")) + " ")]
                    )
                  ])
                ])
              ]
            )
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }