




































































import Vue from 'vue';
import { namespace } from 'vuex-class';

import { NAME as NAME_VALIDATION } from '@/store/modules/validation';
const validationModul = namespace(NAME_VALIDATION);

import { Component, Prop, Watch } from 'vue-property-decorator';
import { GET_VALIDATION_KEY } from '@/store/modules/validation/getters';

@Component

/** Eine Textbox mit Label und kompletter Validierung, welche via zusätzlicher Checkbox verworfen werden kann.
 * @remarks Rendert je eine Spalte für das Label und das Eingabefeld (sm-3 und sm-9 im Bootstrap-Grid)
 * @remarks Wird die Eingabe verworfen, wird der Eingabewert auf null festgelegt (Dies setzt jedoch voraus, dass null ein gültiger Eingabewert ist)
 * und die Validierung wird ausgesetzt (d.h. ist immer erfolgreich)
 */
export default class NowhowDismissableTextbox extends Vue {
    /** Validierung via Validations-Modul */
    @validationModul.Getter(GET_VALIDATION_KEY) getValidation!: (key: string) => string;

    @Prop({ required: true, type: String }) private id!: string;

    @Prop({ required: false, type: String, default: 'text' }) private inputType!: string;

    @Prop({ required: false, type: String, default: '' }) private label!: string;

    /** Das Label für die Dismiss-Checkbox */
    @Prop({ required: false, type: String, default: '' }) private checkboxLabel!: string;

    @Prop({ required: false, type: String, default: '' }) private placeholder!: string;

    @Prop({ required: false, type: String, default: '' }) private autocomplete!: string;

    @Prop({ required: false, type: Boolean, default: false }) private disabled!: boolean;

    @Prop({ required: false, type: Boolean, default: false }) private readonly!: boolean;

    /** Wenn festgelegt, verzögert die Validierung um die angegebene Anzahl Millisekunden
     * @devdoc Kann verwendet werden, um Probleme mit Layoutverschiebungen aufgrund von Validierungs-Meldungen bei
     * Mausklicks auf andere Elemente zu entschärfen
     * @devdoc  Siehe https://vee-validate.logaretm.com/v2/guide/components/validation-provider.html#props
     */
    @Prop({ required: false, type: Number, default: 0 }) private validationDelay!: number;

    @Prop({ default: '', type: [String, Object] }) validate!: string | object;

    @Prop({ required: true, default: '' }) private value!: any;

    @Prop({ required: false, type: Boolean, default: false }) private isDismissed!: boolean;

    @Prop({ required: false, type: String, default: '' })
    private cy!: string;

    /* eslint-disable-next-line no-empty, @typescript-eslint/no-empty-function */
    @Prop({ required: false, type: Function, default: function() {} })
    private onEnter!: Function;

    private provider: any;
    private isSelected = false;

    created() {
        //Den Dismissed-Zustand bereits früh zuweisen, damit die intiale Validierung bereits die zutreffenden Rules anwendet
        this.isSelected = this.isDismissed;
    }

    mounted() {
        const inputElement: any = document.getElementById(String(this.id));
        inputElement.value = this.value;

        this.provider = this.$refs['provider'];
    }

    /** Liefert die aktuell gültigen Validation-Rules
     * @remarks Fall dismissed, sind die Rules immer leer, sonst gelten die festgelegten Rules.
     */
    private get currentValidationRules(): string | object {
        const rules = this.isSelected ? '' : this.validate;
        //console.debug('NowhowDismissableTextbox::validationRules:rules:', rules);
        return rules;
    }

    public focus() {
        (this.$refs.inputfield as HTMLInputElement).focus();
    }

    private onChange(value) {
        this.$emit('change', value);
    }

    /** Behandelt das Auswählen des "Dismissed"-Zustandes
     * @remarks Stösst ein "change" und  "input"-Event aus, wenn dissmissed auf true ist
     * @remarks Stösst ein "dismissed"-Event aus, mit dem dismissed-Wert als Argument
     */
    private onDismissedChange() {
        this.isSelected = !this.isSelected;
        if (this.isSelected === true) {
            this.onInput(null);
            this.onChange(null);
        }

        //Änderung des dismissed-Zustandes melden
        this.$emit('dismissed', this.isSelected);
    }

    private onFocusout(value) {
        this.$emit('focusout', value);
    }

    private onInput(value) {
        this.$emit('input', value);
    }
}
