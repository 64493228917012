import { IRootState } from '@/store';
import { GetterTree } from 'vuex';
import { IResourceState } from './state';

export const GET_LANGUAGE = 'language';
export const GET_LOADEDLANGUAGES = 'loadedLanguages';
export const getters: GetterTree<IResourceState, IRootState> = {
    [GET_LANGUAGE]: state => state.language,
    [GET_LOADEDLANGUAGES]: state => state.loadedLanguages
};
