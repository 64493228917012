var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationObserver", { ref: "observer" }, [
    _c(
      "form",
      { staticClass: "form-horizontal", attrs: { role: "form" } },
      [
        _c("fieldset", [
          _c("legend", {
            directives: [
              {
                name: "t",
                rawName: "v-t",
                value: "edit_lu_tit_lu_census",
                expression: "'edit_lu_tit_lu_census'"
              }
            ]
          }),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("NowhowCheckbox", {
                attrs: {
                  id: "localUnitAhvObligation",
                  label: "lu_census_no_ahv_obligatory_persons",
                  "form-label": "allg_ahv_obligation"
                },
                model: {
                  value: _vm.localUnit.hasNoAhvObligation,
                  callback: function($$v) {
                    _vm.$set(_vm.localUnit, "hasNoAhvObligation", $$v)
                  },
                  expression: "localUnit.hasNoAhvObligation"
                }
              })
            ],
            1
          ),
          _c("table", { staticClass: "table table-naked text-right" }, [
            _c("caption", { staticClass: "sr-only" }, [
              _vm._v(" " + _vm._s(_vm.$t("edit_lu_tit_census")) + " ")
            ]),
            _c("thead", [
              _c("tr", [
                _c("th", {
                  staticClass: "text-right column-header column-label"
                }),
                _c("th", { staticClass: "striped column-data" }, [
                  _vm._v(_vm._s(_vm.$t("edit_lu_worker_male")))
                ]),
                _c("th", { staticClass: "column-data" }, [
                  _vm._v(_vm._s(_vm.$t("edit_lu_worker_female")))
                ]),
                _c("th", { staticClass: "striped total column-data" }, [
                  _vm._v(_vm._s(_vm.$t("entry_census_col_total")))
                ])
              ])
            ]),
            _c("tbody", [
              _c("tr", [
                _c("th", { staticClass: "text-right column-label" }, [
                  _vm._v(_vm._s(_vm.$t("lu_census_worker100")))
                ]),
                _c(
                  "td",
                  { staticClass: "striped" },
                  [
                    _c("NowhowUInt32Input", {
                      attrs: {
                        id: "localDetailCensusMaleWorker90",
                        readonly: _vm.localUnit.hasNoAhvObligation,
                        validate: _vm.getValidation(
                          "localUnitDataObject.fullMale"
                        )
                      },
                      model: {
                        value: _vm.localUnit.fullMale,
                        callback: function($$v) {
                          _vm.$set(_vm.localUnit, "fullMale", $$v)
                        },
                        expression: "localUnit.fullMale"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "td",
                  [
                    _c("NowhowUInt32Input", {
                      attrs: {
                        id: "localDetailCensusFemaleWorker90",
                        readonly: _vm.localUnit.hasNoAhvObligation,
                        validate: _vm.getValidation(
                          "localUnitDataObject.fullFemale"
                        )
                      },
                      model: {
                        value: _vm.localUnit.fullFemale,
                        callback: function($$v) {
                          _vm.$set(_vm.localUnit, "fullFemale", $$v)
                        },
                        expression: "localUnit.fullFemale"
                      }
                    })
                  ],
                  1
                ),
                _c("td", { staticClass: "striped total" }, [
                  _vm._v(_vm._s(_vm.fullPersons))
                ])
              ]),
              _c("tr", [
                _c("th", { staticClass: "text-right column-label" }, [
                  _vm._v(_vm._s(_vm.$t("lu_census_worker_50_90")))
                ]),
                _c(
                  "td",
                  { staticClass: "striped" },
                  [
                    _c("NowhowUInt32Input", {
                      attrs: {
                        id: "localDetailCensusMaleWorker50_90",
                        readonly: _vm.localUnit.hasNoAhvObligation,
                        validate: _vm.getValidation(
                          "localUnitDataObject.part1Male"
                        )
                      },
                      model: {
                        value: _vm.localUnit.part1Male,
                        callback: function($$v) {
                          _vm.$set(_vm.localUnit, "part1Male", $$v)
                        },
                        expression: "localUnit.part1Male"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "td",
                  [
                    _c("NowhowUInt32Input", {
                      attrs: {
                        id: "localDetailCensusFemaleWorker50_90",
                        readonly: _vm.localUnit.hasNoAhvObligation,
                        validate: _vm.getValidation(
                          "localUnitDataObject.part1Female"
                        )
                      },
                      model: {
                        value: _vm.localUnit.part1Female,
                        callback: function($$v) {
                          _vm.$set(_vm.localUnit, "part1Female", $$v)
                        },
                        expression: "localUnit.part1Female"
                      }
                    })
                  ],
                  1
                ),
                _c("td", { staticClass: "striped total" }, [
                  _vm._v(_vm._s(_vm.part1Persons))
                ])
              ]),
              _c("tr", [
                _c("th", { staticClass: "text-right column-label" }, [
                  _vm._v(_vm._s(_vm.$t("lu_census_worker_50")))
                ]),
                _c(
                  "td",
                  { staticClass: "striped" },
                  [
                    _c("NowhowUInt32Input", {
                      attrs: {
                        id: "localDetailCensusMaleWorker50",
                        readonly: _vm.localUnit.hasNoAhvObligation,
                        validate: _vm.getValidation(
                          "localUnitDataObject.part2Male"
                        )
                      },
                      model: {
                        value: _vm.localUnit.part2Male,
                        callback: function($$v) {
                          _vm.$set(_vm.localUnit, "part2Male", $$v)
                        },
                        expression: "localUnit.part2Male"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "td",
                  [
                    _c("NowhowUInt32Input", {
                      attrs: {
                        id: "localDetailCensusFemaleWorker50",
                        readonly: _vm.localUnit.hasNoAhvObligation,
                        validate: _vm.getValidation(
                          "localUnitDataObject.part2Female"
                        )
                      },
                      model: {
                        value: _vm.localUnit.part2Female,
                        callback: function($$v) {
                          _vm.$set(_vm.localUnit, "part2Female", $$v)
                        },
                        expression: "localUnit.part2Female"
                      }
                    })
                  ],
                  1
                ),
                _c("td", { staticClass: "striped total" }, [
                  _vm._v(_vm._s(_vm.part2Persons))
                ])
              ]),
              _c("tr", [
                _c("th", { staticClass: "text-right column-label" }, [
                  _vm._v(_vm._s(_vm.$t("lu_census_worker_15")))
                ]),
                _c(
                  "td",
                  { staticClass: "striped" },
                  [
                    _c("NowhowUInt32Input", {
                      attrs: {
                        id: "localDetailCensusMaleWorker15",
                        readonly: _vm.localUnit.hasNoAhvObligation,
                        validate: _vm.getValidation(
                          "localUnitDataObject.part3Male"
                        )
                      },
                      model: {
                        value: _vm.localUnit.part3Male,
                        callback: function($$v) {
                          _vm.$set(_vm.localUnit, "part3Male", $$v)
                        },
                        expression: "localUnit.part3Male"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "td",
                  [
                    _c("NowhowUInt32Input", {
                      attrs: {
                        id: "localDetailCensusFemaleWorker15",
                        readonly: _vm.localUnit.hasNoAhvObligation,
                        validate: _vm.getValidation(
                          "localUnitDataObject.part3Female"
                        )
                      },
                      model: {
                        value: _vm.localUnit.part3Female,
                        callback: function($$v) {
                          _vm.$set(_vm.localUnit, "part3Female", $$v)
                        },
                        expression: "localUnit.part3Female"
                      }
                    })
                  ],
                  1
                ),
                _c("td", { staticClass: "striped" }, [
                  _vm._v(_vm._s(_vm.part3Persons))
                ])
              ]),
              _c("tr", [
                _c("th", { staticClass: "text-right column-label total" }, [
                  _vm._v(_vm._s(_vm.$t("edit_lu_tit_total")))
                ]),
                _c("td", { staticClass: "striped total" }),
                _c("td", { staticClass: "total" }),
                _c("td", { staticClass: "striped total" }, [
                  _vm._v(" " + _vm._s(_vm.totalPersons) + " ")
                ])
              ]),
              _c("tr", [
                _c("th", {
                  staticClass: "text-right column-label",
                  domProps: {
                    innerHTML: _vm._s(_vm.$t("edit_lu_worker_vz_eq"))
                  }
                }),
                _c(
                  "td",
                  { staticClass: "striped" },
                  [
                    _c("NowhowUInt32Input", {
                      attrs: {
                        id: "localDetailCensusMaleFte",
                        label: "edit_lu_worker_vz_eq",
                        readonly: _vm.localUnit.hasNoAhvObligation,
                        validate:
                          _vm.getValidation(
                            "localUnitDataObject.fullTimeEquivalentMale"
                          ) +
                          "|" +
                          _vm.getLowerMaleFteLimitValidationRule +
                          "|" +
                          _vm.getUpperMaleFteLimitValidationRule
                      },
                      model: {
                        value: _vm.localUnit.fullTimeEquivalentMale,
                        callback: function($$v) {
                          _vm.$set(_vm.localUnit, "fullTimeEquivalentMale", $$v)
                        },
                        expression: "localUnit.fullTimeEquivalentMale"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "td",
                  [
                    _c("NowhowUInt32Input", {
                      attrs: {
                        id: "localDetailCensusFemaleFte",
                        label: "edit_lu_worker_vz_eq",
                        readonly: _vm.localUnit.hasNoAhvObligation,
                        validate:
                          _vm.getValidation(
                            "localUnitDataObject.fullTimeEquivalentFemale"
                          ) +
                          "|" +
                          _vm.getLowerFemaleFteLimitValidationRule +
                          "|" +
                          _vm.getUpperFemaleFteLimitValidationRule
                      },
                      model: {
                        value: _vm.localUnit.fullTimeEquivalentFemale,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.localUnit,
                            "fullTimeEquivalentFemale",
                            $$v
                          )
                        },
                        expression: "localUnit.fullTimeEquivalentFemale"
                      }
                    })
                  ],
                  1
                ),
                _c("td", { staticClass: "striped total" }, [
                  _vm._v(" " + _vm._s(_vm.totalFte) + " ")
                ])
              ])
            ])
          ])
        ]),
        _vm.isOccupationZero
          ? _c("fieldset", [
              _c("legend"),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("NowhowTextarea", {
                    attrs: {
                      id: "localDetailCensusReasonText",
                      label: "edit_lu_censusreasontext",
                      "input-type": "text",
                      validate:
                        _vm.getValidation(
                          "localUnitDataObject.censusReasonText"
                        ) +
                        "|" +
                        _vm.getCensusReasonTextValidationRule
                    },
                    model: {
                      value: _vm.localUnit.censusReasonText,
                      callback: function($$v) {
                        _vm.$set(_vm.localUnit, "censusReasonText", $$v)
                      },
                      expression: "localUnit.censusReasonText"
                    }
                  })
                ],
                1
              )
            ])
          : _vm._e(),
        _c("NowhowRequiredInfoline")
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }