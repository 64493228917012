var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationObserver", { ref: "localUnitAddressObserver" }, [
    _c(
      "form",
      { staticClass: "form-horizontal", attrs: { role: "form" } },
      [
        _c(
          "fieldset",
          [
            _c("legend", {
              directives: [
                {
                  name: "t",
                  rawName: "v-t",
                  value: "page_end_editluadr_title",
                  expression: "'page_end_editluadr_title'"
                }
              ]
            }),
            _c("NowhowTextline", {
              attrs: {
                id: "localDetailBurId",
                label: "lu_nr",
                text: this.localUnit.prefixedBurLocalUnitId
              }
            }),
            _c("NowhowTextarea", {
              directives: [{ name: "focus", rawName: "v-focus" }],
              ref: "localDetailName",
              attrs: {
                id: "localDetailName",
                label: "lu_name",
                rows: 2,
                "input-type": "text",
                validate: _vm.getValidation("localUnitDataObject.name")
              },
              model: {
                value: _vm.localUnit.name,
                callback: function($$v) {
                  _vm.$set(_vm.localUnit, "name", $$v)
                },
                expression: "localUnit.name"
              }
            }),
            _c("NowhowTextbox", {
              attrs: {
                id: "localDetailBusinessName",
                label: "lu_busname",
                "input-type": "text",
                validate: _vm.getValidation("localUnitDataObject.nameBusiness")
              },
              model: {
                value: _vm.localUnit.nameBusiness,
                callback: function($$v) {
                  _vm.$set(_vm.localUnit, "nameBusiness", $$v)
                },
                expression: "localUnit.nameBusiness"
              }
            }),
            _c("NowhowDismissableTextbox", {
              attrs: {
                id: "localDetailPhoneNumber",
                label: "lu_phonenumber",
                "checkbox-label": "lu_phonenumber_none",
                "is-dismissed": _vm.localUnit.isPhoneNumberDismissed,
                "input-type": "text",
                validate:
                  _vm.getValidation("localUnitDataObject.phoneNumber") +
                  "|required"
              },
              on: { dismissed: _vm.onPhoneNumberDismissed },
              model: {
                value: _vm.localUnit.phoneNumber,
                callback: function($$v) {
                  _vm.$set(_vm.localUnit, "phoneNumber", $$v)
                },
                expression: "localUnit.phoneNumber"
              }
            }),
            _c("NowhowDismissableTextbox", {
              attrs: {
                id: "localDetailEmailAddress",
                label: "lu_email_address",
                "checkbox-label": "lu_email_address_none",
                "is-dismissed": _vm.localUnit.isEmailAddressDismissed,
                "input-type": "text",
                validate:
                  _vm.getValidation("localUnitDataObject.emailAddress") +
                  "|required"
              },
              on: { dismissed: _vm.onEmailAddressDismissed },
              model: {
                value: _vm.localUnit.emailAddress,
                callback: function($$v) {
                  _vm.$set(_vm.localUnit, "emailAddress", $$v)
                },
                expression: "localUnit.emailAddress"
              }
            }),
            _c("NowhowDismissableTextbox", {
              attrs: {
                id: "localDetailWebsiteAddress",
                label: "lu_website_address",
                "checkbox-label": "lu_website_address_none",
                "is-dismissed": _vm.localUnit.isWebsiteAddressDismissed,
                "input-type": "text",
                validate:
                  _vm.getValidation("localUnitDataObject.websiteAddress") +
                  "|required"
              },
              on: { dismissed: _vm.onWebsiteAddressDismissed },
              model: {
                value: _vm.localUnit.websiteAddress,
                callback: function($$v) {
                  _vm.$set(_vm.localUnit, "websiteAddress", $$v)
                },
                expression: "localUnit.websiteAddress"
              }
            })
          ],
          1
        ),
        _c(
          "fieldset",
          [
            _c("legend", {
              directives: [
                {
                  name: "t",
                  rawName: "v-t",
                  value: "allg_address",
                  expression: "'allg_address'"
                }
              ]
            }),
            _c("NowhowTextbox", {
              attrs: {
                id: "localDetailCareOf",
                label: "adr_adrline1",
                "input-type": "text",
                validate: _vm.getValidation("localUnitDataObject.careOf")
              },
              model: {
                value: _vm.localUnit.careOf,
                callback: function($$v) {
                  _vm.$set(_vm.localUnit, "careOf", $$v)
                },
                expression: "localUnit.careOf"
              }
            }),
            _c("br"),
            !_vm.isLocalSwisstopoUnavailable
              ? _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-3 control-label",
                      attrs: { for: "localDetailSwisstopoSearchbox" }
                    },
                    [_vm._v(_vm._s(_vm.$t("address_searchbox_with_swisstopo")))]
                  ),
                  _c(
                    "div",
                    { staticClass: "col-sm-9" },
                    [
                      _c("SwisstopoFeatureSearch", {
                        attrs: { id: "localDetailSwisstopoSearchbox" },
                        on: {
                          featureSelected: _vm.onLocalSwisstopoFeatureSelected,
                          error: _vm.onLocalSwisstopoUnavailable
                        }
                      }),
                      _c("div", { staticClass: "checkbox checkbox--left" }, [
                        _c(
                          "label",
                          {
                            attrs: {
                              for: "localDetailSwisstopoSearchbox-dismissed"
                            }
                          },
                          [
                            _c("input", {
                              key: "localDetailSwisstopoSearchbox-dismissed",
                              ref: "swisstopoDismissalCheckbox",
                              staticClass: "form-control",
                              attrs: {
                                type: "checkbox",
                                id: "localDetailSwisstopoSearchbox-dismissed",
                                name: _vm.$t("address_manual_entry")
                              },
                              domProps: {
                                checked: _vm.localUnit.isSwisstopoDismissed
                              },
                              on: {
                                change: _vm.onLocalSwisstopoDismissedChange
                              }
                            }),
                            _vm._v(
                              " " + _vm._s(_vm.$t("address_manual_entry")) + " "
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  )
                ])
              : _vm._e(),
            _c("NowhowTextbox", {
              attrs: {
                id: "localDetailStreet",
                label: "allg_street",
                "input-type": "text",
                readonly: !_vm.localUnit.isSwisstopoDismissed,
                validate: _vm.getValidation("localUnitDataObject.street")
              },
              on: { input: _vm.onManualLocalAddressInput },
              model: {
                value: _vm.localUnit.street,
                callback: function($$v) {
                  _vm.$set(_vm.localUnit, "street", $$v)
                },
                expression: "localUnit.street"
              }
            }),
            _c("NowhowTextbox", {
              attrs: {
                id: "localDetailHouseNumber",
                label: "allg_street_nr",
                "input-type": "text",
                readonly: !_vm.localUnit.isSwisstopoDismissed,
                validate: _vm.getValidation("localUnitDataObject.houseNumber")
              },
              on: { input: _vm.onManualLocalAddressInput },
              model: {
                value: _vm.localUnit.houseNumber,
                callback: function($$v) {
                  _vm.$set(_vm.localUnit, "houseNumber", $$v)
                },
                expression: "localUnit.houseNumber"
              }
            }),
            _c("NowhowTextbox", {
              attrs: {
                id: "localDetailAddressAdditionalText",
                label: "adr_additional_text",
                "input-type": "text",
                validate: _vm.getValidation("localUnitDataObject.additionalTxt")
              },
              model: {
                value: _vm.localUnit.additionalTxt,
                callback: function($$v) {
                  _vm.$set(_vm.localUnit, "additionalTxt", $$v)
                },
                expression: "localUnit.additionalTxt"
              }
            }),
            _c("NowhowUInt32Box", {
              attrs: {
                id: "localDetailZipCode",
                label: "allg_zip",
                readonly: !_vm.localUnit.isSwisstopoDismissed,
                validate: _vm.getValidation("localUnitDataObject.zipCode")
              },
              on: { input: _vm.onManualLocalAddressInput },
              model: {
                value: _vm.localUnit.zipCode,
                callback: function($$v) {
                  _vm.$set(_vm.localUnit, "zipCode", $$v)
                },
                expression: "localUnit.zipCode"
              }
            }),
            _c("NowhowTextbox", {
              attrs: {
                id: "localDetailTown",
                label: "allg_ort",
                "input-type": "text",
                readonly: !_vm.localUnit.isSwisstopoDismissed,
                validate: _vm.getValidation("localUnitDataObject.town")
              },
              on: { input: _vm.onManualLocalAddressInput },
              model: {
                value: _vm.localUnit.town,
                callback: function($$v) {
                  _vm.$set(_vm.localUnit, "town", $$v)
                },
                expression: "localUnit.town"
              }
            }),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: false,
                    expression: "false"
                  }
                ]
              },
              [
                _vm._v(" GDEID:" + _vm._s(_vm.localUnit.gdeId)),
                _c("br"),
                _vm._v(" ESTRID:" + _vm._s(_vm.localUnit.estrId)),
                _c("br"),
                _vm._v(" EGID:" + _vm._s(_vm.localUnit.egId)),
                _c("br"),
                _vm._v(" EDID:" + _vm._s(_vm.localUnit.edId)),
                _c("br"),
                _vm._v(" ESID:" + _vm._s(_vm.localUnit.esId)),
                _c("br")
              ]
            ),
            _c("br"),
            _c("NowhowTextbox", {
              attrs: {
                id: "localDetailPoBoxNumber",
                label: "allg_postbox_nr",
                "input-type": "number",
                validate:
                  _vm.getValidation("localUnitDataObject.postOfficeBoxNumber") +
                  "|" +
                  _vm.getPoBoxTogetherRule
              },
              model: {
                value: _vm.localUnit.postOfficeBoxNumber,
                callback: function($$v) {
                  _vm.$set(_vm.localUnit, "postOfficeBoxNumber", $$v)
                },
                expression: "localUnit.postOfficeBoxNumber"
              }
            }),
            _c("NowhowUInt32Box", {
              attrs: {
                id: "localDetailPoBoxZipCode",
                label: "allg_postbox_zip",
                validate:
                  _vm.getValidation(
                    "localUnitDataObject.postOfficeBoxZipCode"
                  ) +
                  "|" +
                  _vm.getPoBoxTogetherRule
              },
              model: {
                value: _vm.localUnit.postOfficeBoxZipCode,
                callback: function($$v) {
                  _vm.$set(_vm.localUnit, "postOfficeBoxZipCode", $$v)
                },
                expression: "localUnit.postOfficeBoxZipCode"
              }
            }),
            _c("NowhowTextbox", {
              attrs: {
                id: "localDetailPoBoxTown",
                label: "allg_postbox_town",
                "input-type": "text",
                validate:
                  _vm.getValidation("localUnitDataObject.postOfficeBoxTown") +
                  "|" +
                  _vm.getPoBoxTogetherRule
              },
              model: {
                value: _vm.localUnit.postOfficeBoxTown,
                callback: function($$v) {
                  _vm.$set(_vm.localUnit, "postOfficeBoxTown", $$v)
                },
                expression: "localUnit.postOfficeBoxTown"
              }
            })
          ],
          1
        ),
        _c("NowhowRequiredInfoline")
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }