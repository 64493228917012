




























































import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import { namespace } from 'vuex-class';
import { NAME as NAME_FOOTERBAR } from '@/store/modules/footerBar';
const footerBarModul = namespace(NAME_FOOTERBAR);
import {
    IS_BAR_VISIBLE,
    GET_TEXT,
    GET_CLOSEDELAY,
    GET_CLOSEAFTERDELAY,
    GET_DISMISSIBLE,
    GET_BUTTONLEFT,
    GET_BUTTONCENTER,
    GET_BUTTONRIGHT
} from '@/store/modules/footerBar/getters';
import { IFooterButton } from '@/store/modules/footerBar/state';

@Component
export default class FooterButton extends Vue {
    @footerBarModul.Getter(IS_BAR_VISIBLE) isBarVisible!: boolean;
    @footerBarModul.Getter(GET_TEXT) label!: string;
    @footerBarModul.Getter(GET_CLOSEDELAY) closeDelay!: number;
    @footerBarModul.Getter(GET_CLOSEAFTERDELAY) closeAfterDelay!: boolean;
    @footerBarModul.Getter(GET_DISMISSIBLE) isDismissible!: boolean;
    @footerBarModul.Getter(GET_BUTTONLEFT) buttonLeft!: Array<IFooterButton>;
    @footerBarModul.Getter(GET_BUTTONCENTER) buttonCenter!: Array<IFooterButton>;
    @footerBarModul.Getter(GET_BUTTONRIGHT) buttonRight!: Array<IFooterButton>;

    footerClass(type: string) {
        return 'btn btn-' + type;
    }

    isDisabled(button: IFooterButton) {
        return button.disabled === true;
    }
}
