









































import Vue from 'vue';

import { Component, Prop, Watch } from 'vue-property-decorator';

import { ISelectListItem, SelectListItem } from '@/types/clienttypes';
import { ProviderInstance } from 'vee-validate/dist/types/types';

/** Implementiert eine Radio-Button-Group mit Label und kompletter Validierung
 * @remarks Rendert je eine Spalte für das Label und die Radio-Buttons (sm-3 und sm-9 im Bootstrap-Grid)
 * @remarks Unterstützt ein 'group-focusout'-Event, welches kurz nach dem Verlassen der Gruppe feuert.
 * @devdoc Das Event beim Verlassen der Gruppe wird mit einer Kombination von focusin und focusout, mit einem kurzen Timeout realisiert.
 */
@Component({})
export default class NowhowRadioList extends Vue {
    /** Die Id für die Radio-Gruppe
     * @devdoc Eine eindeutige Id ist für das korrekte interne Event-Handling zwingend
     */
    @Prop({ required: true, type: String }) private id!: string;

    @Prop({ required: false, type: String }) private name!: string;

    @Prop({ required: false, type: String, default: '' }) private label!: string;

    @Prop({ required: false, type: Boolean, default: false }) private disabled!: boolean;

    @Prop({ required: false, type: Boolean, default: false }) private readonly!: boolean;

    @Prop({ required: false, type: Boolean, default: false }) private inline!: boolean;

    @Prop({ default: '', type: [String, Object] }) validate!: string | object;

    @Prop({ required: true, type: Array as () => Array<ISelectListItem>, default: () => [] }) items!: ISelectListItem[];

    @Prop({ required: false, type: [String, Number], default: '' }) value!: string | number | null;

    @Prop({ required: false, type: String, default: '' })
    private cy!: string;

    private isMounted = false;
    private provider: ProviderInstance;

    mounted() {
        //console.debug('NowhowRadioList.vue::mounted#' + this.id);

        this.provider = this.$refs['provider'] as ProviderInstance;

        //Definierte Werte als String, null als null
        if (this.value) {
            this.updateRadiobuttons(this.value.toString());
        } else {
            this.updateRadiobuttons(null);
        }

        //Silent validation, um den Validation-Provider (für den "Weiter" Button im Step) bereits korrekt initialisiert zu erhalten, ohne jedoch das GUI zu stören
        this.provider.validateSilent();

        this.isMounted = true;
    }

    beforeDestroy() {
        //console.debug('NowhowRadioList.vue::beforeDestroy' + this.id);
        //Falls noch ein Emit ausstehend ist, den timer sofort canceln
        if (this.focusoutTimeoutHandle) {
            //console.debug('NowhowRadioList.vue::beforeDestroy->clearTimeout' + this.id);
            clearTimeout(this.focusoutTimeoutHandle);
            //TODO sollte das Event hier noch ausgestossen werden?
        }
    }

    get hasErrors() {
        if (this.isMounted) {
            return this.provider.errors.length > 0;
        } else {
            return false;
        }
    }
    get required() {
        const isString = typeof this.validate === 'string' || this.validate instanceof String;
        // eslint-disable-next-line no-prototype-builtins
        return isString ? this.validate.toString().indexOf('required') >= 0 : this.validate.hasOwnProperty('required');
    }

    updateRadiobuttons(value: string) {
        //console.debug('updateRadiobuttons-value#' + this.id, value);

        // Find item in array
        //console.debug('updateRadiobuttons-items#' + this.id, this.items);
        const i = this.items
            .map(function(e) {
                //Problem da number und bei IndexOf auf string geprüft wird. => ergibt immer false
                const mappableValue = e.value?.toString();
                return mappableValue;
            })
            .indexOf(value);

        // Allenfalls gefundenes, passendes Item auf die Selektion anwenden
        let hasChange = false;
        for (let position = 0; position < this.items.length; position++) {
            const itemAtPostition = this.items[position];

            //Selektion anwenden, wenn notwendig
            const newValue = position === i;
            if (itemAtPostition.selected !== newValue) {
                itemAtPostition.selected = position === i;
                hasChange = true;
            }
        }

        if (hasChange === true) {
            this.provider.validate(value).then(() => {
                //console.debug('updateRadiobuttons validated then emit#' + this.id, value);
                //TODO falls numerisch, als numerisch ausgeben, ansonsten wird erst nach Serverseitigen Roundtrip der Wert als Numersich verarbeitet

                this.$emit('input', value);
                this.$emit('change', value);
            });
        }
    }

    @Watch('value')
    valueChanged(newVal, oldVal) {
        // console.debug('valueChanged:newVal', newVal);
        // console.debug('valueChanged:oldVal', oldVal);
        if (oldVal !== newVal) {
            //Nur effektiv veränderte Werte verarbeiten
            //Definierte Werte als String, null als null
            if (newVal) {
                this.updateRadiobuttons(newVal.toString());
            } else {
                this.updateRadiobuttons(null);
            }
        }
    }

    @Watch('items')
    itemsChanged(newVal, oldVal) {
        // console.debug('itemsChanged:newVal', newVal);
        // console.debug('itemsChanged:oldVal', oldVal);
        //if (oldVal !== newVal) {
        //Nur effektiv veränderte Werte verarbeiten
        //Definierte Werte als String, null als null
        if (this.value) {
            this.updateRadiobuttons(this.value.toString());
        } else {
            this.updateRadiobuttons(null);
        }
        //}
    }

    /** Behandelt das Focus-In eines Radio-Inputs.
     * @devdoc Cancelt das absetzen des Gruppen-Events wenn es eines der enthaltenen Controls betrifft.
     */
    private onInputFocusIn(value: FocusEvent) {
        //console.debug('NowhowRadioList.vue::onInputFocusIn', value);

        //Prüfen, ob das relatedTarget eines der eigenen Controls ist, anhand der Id
        const target = value.target;
        if (target !== null) {
            const targetElement = target as HTMLInputElement;
            //console.debug('target targetElement ', targetElement);
            if (targetElement) {
                for (const obj of this.items) {
                    const itemId = this.getRadioItemId(obj);
                    //console.log('itemId', itemId);
                    if (itemId === targetElement.id) {
                        console.debug('FocusIn on internal control, canceling group-focusout');
                        //Kein event ausstossen, da es ein Control der Gruppe betrifft
                        clearTimeout(this.focusoutTimeoutHandle);
                        return;
                    }
                }
            }
        }
    }

    /** Behandelt das Focus-Out eines Radio-Inputs. Zusätzlich wird ein Event im Zusammenhang mit der Gruppe angestossen.
     * @remarks Ein Group-Event wird nur ausgestossen, wenn die Gruppe verlassen wird
     * @devdoc Ein Mausklick auf die Labels wird leider als verlassen der Gruppe bewertet, weil das relatedTarget in
     * diesem Fall null ist. Mithilfe einer Verzögerung und nachfolgender Behandlung des focusin-Events wird das Event
     * für die Gruppe nur ausgestossen, wenn das Timeout ohne cancel abläuft.
     */
    private onInputFocusout(event: FocusEvent) {
        //console.debug('NowhowRadioList.vue::onInputFocusout', event);

        this.$emit('focusout', event);

        //Prüfen, ob das relatedTarget eines der eigenen Controls ist, anhand der Id
        if (event.relatedTarget !== null) {
            const targetElement = event.relatedTarget as HTMLInputElement;
            //console.debug('target targetElement ', targetElement);
            if (targetElement) {
                for (const obj of this.items) {
                    const itemId = this.getRadioItemId(obj);
                    //console.log('itemId', itemId);
                    if (itemId === targetElement.id) {
                        //console.debug('Click on internal control');
                        //Kein event ausstossen, da es ein Control der Gruppe betrifft
                        return;
                    }
                }
            }
        }

        console.debug('Click, scheduling group-focusout emit now....');
        //Das Timeout ist so gewählt dass ein üblicher Mausklick (down und up durch einen durchschnittlich schnellen Benutzer) gesamthaft ablaufen kann
        this.focusoutTimeoutHandle = setTimeout(() => {
            //Falls kein focusin in dieser Zeit, EMIT!
            console.debug('group-focusout emit!', event);
            this.$emit('group-focusout', event);
            this.provider.validate(this.value);
        }, 500);
    }

    /** Liefert eine Id für ein Element zu einem Radio-Item
     * @remarks Die Id ist vom Wert abhängig, um eindeutige Id's zu erhalten.
     */
    private getRadioItemId(selectListItem: ISelectListItem): string {
        return this.id + '-' + selectListItem.value;
    }

    /** Ein Handle zu einem Timeout für das Ausstossen des Gruppen-Focusout */
    focusoutTimeoutHandle;
}
