export interface IFooterBarState {
    visible: boolean;
    text: string;
    closeAfterDelay: boolean;
    closeDelay: number;
    isDismissible: boolean;
    buttonLeft: Array<IFooterButton>;
    buttonCenter: Array<IFooterButton>;
    buttonRight: Array<IFooterButton>;
}

export const state: IFooterBarState = {
    visible: false,
    text: 'placeholder',
    closeAfterDelay: false,
    closeDelay: 0,
    isDismissible: true,
    buttonLeft: new Array<IFooterButton>(),
    buttonCenter: new Array<IFooterButton>(),
    buttonRight: new Array<IFooterButton>()
};

/**
 * Beschreibt die einzelnen Buttons mit Text, Styling und deren Funktionalität
 */
export interface IFooterButton {
    onClick: Function;
    label: string;

    type: FooterButtonType;

    /** Gibt optional an, ob der Button disabled ist.
     * @remarks Falls disabled, wird ein fixer Hinweis-Text als Tooltip angezeigt
     */
    disabled?: boolean;

    /** Gibt optional an, ob der Button sichtbar ist. Wenn nicht false, wird der Button gerendert. */
    visible?: boolean;
}

export enum FooterButtonType {
    primary = 'primary',
    secondary = 'secondary',
    link = 'link',
    unstyled = 'unstyled'
}
