var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      class: { "save-indicator": true, "save-indicator-active": _vm.isActive }
    },
    [_vm._v(" " + _vm._s(_vm.$t("allg_saving")) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }