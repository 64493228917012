import { IRootState } from '@/store';
import { GetterTree } from 'vuex';
import { IEntryState } from './state';

/** Liefert die Id des aktuell im Entry-Wizard bearbeiteten Falles oder null, wenn kein Fall bearbeitet wird. */
export const CASE_ID = 'caseId';

/** Liefert die Unternehmen des aktuell im Entry-Wizard bearbeiteten Falles oder null, wenn kein Fall bearbeitet wird. */
export const CASE_ENTERPRISE_UNITS = 'caseEnterpriseUnits';

/** Liefert die UID (des Father-Unternehmens) des aktuell im Entry-Wizard bearbeiteten Falles oder null, wenn kein Fall bearbeitet wird. */
export const CASE_UID = 'caseUid';

/** Liefert den Namen des Falls des aktuell im Entry-Wizard bearbeiteten Falles oder null, wenn kein Fall bearbeitet wird.
 * @remarks Dies entspricht dem Namen der befragten Unternehmung oder dem Name der befragten Unternehmens-Gruppe.
 */
export const CASE_NAME = 'caseName';

/** Liefert aktuelle StepInfo für den aktuell im Entry-Wizard bearbeiteten Falles */
export const CASE_STEPINFO = 'caseStepInfo';

/** Liefert die Kontaktdaten zu einem Fall
 * @remarks Die Kontaktdaten sind ein Subset der vollen Daten zu einem Fall.
 */
export const CASE_CONTACT_DATA = 'caseContactData';

/** Liefert die (server-seitigen) Validierungs-Meldungen zu einem Fall */
export const CASE_VALIDATION_MESSAGES = 'caseValidationMessages';

/** Liefert die vollen Daten zu einem Fall */
export const CASE_FULL_DATA = 'caseFullData';

/** Liefert die Angaben zur Zustimmung zum Abschluss eines Falles */
export const CASE_APPROVAL = 'caseApproval';

/** Liefert die zu bearbeitende Activity */
export const CASE_ACIVITY = 'caseActivity';

/** Liefert die Angaben zur LocalUnit */
export const CASE_LOCAL_UNIT = 'caseLocalUnit';

/** Liefert die Angaben zur EnterpriseUnit */
export const CASE_ENTERPRISE_UNIT = 'caseEnterpriseUnit';

export const getters: GetterTree<IEntryState, IRootState> = {
    [CASE_ID]: state => state.caseFullData?.caseId,
    [CASE_UID]: state => state.caseFullData?.caseUid,
    [CASE_NAME]: state => state.caseFullData?.caseName,
    [CASE_CONTACT_DATA]: state => state.caseFullData?.generalEnterpriseContactDataObject,
    [CASE_FULL_DATA]: state => state.caseFullData,
    [CASE_ENTERPRISE_UNITS]: state => state.caseFullData.userDataSet.enterpriseUnits,
    [CASE_APPROVAL]: state => state.caseApproval,
    [CASE_VALIDATION_MESSAGES]: state => state.caseValidationMessages,

    /** Liefert die typisierte StepInfo als ICaseStepInfoViewModel */
    [CASE_STEPINFO]: state => state.caseFullData.caseStepInfo,

    /**
     * In ERST kann nur eine EnterpriseUnit mit einer LocalUnit verarbeitet werden, deshalb wird hier fix die erste LU der ersten EU verwendet
     */
    [CASE_ACIVITY]: state => {
        return state.caseFullData.userDataSet.enterpriseUnits[0].localUnits[0].activity;
    },

    /**
     * In ERST kann nur eine EnterpriseUnit mit einer LocalUnit verarbeitet werden, deshalb wird hier fix die erste LU der ersten EU verwendet
     */
    [CASE_LOCAL_UNIT]: state => {
        return state.caseFullData.userDataSet.enterpriseUnits[0].localUnits[0];
    },

    /**
     * In ERST kann nur eine EnterpriseUnit mit einer LocalUnit verarbeitet werden, deshalb wird hier fix die erste EU verwendet
     */
    [CASE_ENTERPRISE_UNIT]: state => {
        return state.caseFullData.userDataSet.enterpriseUnits[0];
    }
};
