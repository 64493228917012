






import Vue from 'vue';
import { namespace } from 'vuex-class';

import { NAME as NAME_SAVEINDICATOR } from '@/store/modules/saveIndicator';
const saveIndicatorModul = namespace(NAME_SAVEINDICATOR);
import { GET_ACTIVE } from '@/store/modules/saveIndicator/getters';

import { Component, Prop, Watch } from 'vue-property-decorator';

@Component
export default class SaveIndicator extends Vue {
    @saveIndicatorModul.Getter(GET_ACTIVE) isActive!: boolean;
}
