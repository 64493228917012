import { IAccountViewModel, IContactInfoViewModel, IRegistrationInfoViewModel } from '@/api-viewmodel';

/** Definiert den Zustand für einen Account
 * @interface
 */
export interface IAccountState {

    /** Das Benutzerprofil */
    userProfile: IAccountViewModel;

    /** Ist der aktuelle Benutzer angemeldet */
    isLoggedIn: boolean;

    /** Die Kontaktinformationen*/
    contactInfo: IContactInfoViewModel;

    /* Informationen zur Befragung und dem Fall für die sich ein externer Benutzer registriert */
    registrationInfo: IRegistrationInfoViewModel;

    /* Resultat einer Registration eines externen Benutzers */
    registrationResult: boolean;
}

/** Stellt den Zustand für einen Account zur Verfügung.
 */
export const state: IAccountState = {
    /**Das Benutzerprofil*/
    userProfile: {
        /** Authentisierter Benutzername */
        username: '',

        /**Authorisierte Rollen */
        roles: []
    },

    isLoggedIn: false,

    /** Die Kontaktinformationen*/
    contactInfo: {
        organisation: '',
        street: '',
        houseNumber: '',
        zip: '',
        town: '',
        email: '',
        phoneNumber: '',
        faxNumber: ''
    },

    /* Informationen zur Befragung und dem Fall für die sich ein externer Benutzer registriert */
    registrationInfo: {
        /**Der Name der Survey, für die sich der externe Benutzer registriert */
        registeredSurveyName: ''
    },

    /* Resultat einer Registration eines externen Benutzers */
    registrationResult: null
};
