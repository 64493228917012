import { render, staticRenderFns } from "./NowhowTextbox.vue?vue&type=template&id=6f72e064&"
import script from "./NowhowTextbox.vue?vue&type=script&lang=ts&"
export * from "./NowhowTextbox.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\jenkins\\workspace\\ERST-10-Deploy\\WebGui\\Erst.Web\\redesign\\clientapp\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6f72e064')) {
      api.createRecord('6f72e064', component.options)
    } else {
      api.reload('6f72e064', component.options)
    }
    module.hot.accept("./NowhowTextbox.vue?vue&type=template&id=6f72e064&", function () {
      api.rerender('6f72e064', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/NowhowTextbox.vue"
export default component.exports