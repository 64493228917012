var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: { "form-group": true } }, [
    _vm.label
      ? _c(
          "label",
          { staticClass: "col-sm-3 control-label", attrs: { for: _vm.id } },
          [
            _vm._v(" " + _vm._s(_vm.$t(_vm.label)) + " "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.required,
                    expression: "required"
                  }
                ]
              },
              [_vm._v("*")]
            )
          ]
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "col-sm-9" },
      [
        _c("imask-input", {
          staticClass: "form-control accesscode-part",
          attrs: {
            value: _vm.maskedValue,
            mask: _vm.patternMask,
            radix: ".",
            lazy: false,
            overwrite: false,
            placeholder: "____ - ____ - ____"
          },
          on: { accept: _vm.onAccept }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }