var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.isSurveyNameAssigned && !this.isRegistrationDone
      ? _c(
          "div",
          [
            _c("Step1AccessCode", {
              attrs: { "access-code": _vm.accessCode },
              on: { onAccessCodeInput: _vm.onAccesCodeInput }
            })
          ],
          1
        )
      : _vm._e(),
    _vm.isSurveyNameAssigned && !this.isRegistrationDone
      ? _c(
          "div",
          [_c("Step2Password", { attrs: { "access-code": _vm.accessCode } })],
          1
        )
      : _vm._e(),
    this.isRegistrationDone ? _c("div", [_c("Step3Result")], 1) : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }