/** Definiert das ViewModel für die Lieferung von Environment-Angaben an den Client.
 * @interface
 */
export interface IEnvironmentViewModel {
    /** Liefert oder definiert den Namen des Environments für die Vue-App. */
    vueAppEnvironment: string;
}

/** Definiert das ViewModel eines Accounts für einen authentisierten Benutzer
 * @interface
 */
export interface IAccountViewModel {
    /** Authentisierter Benutzername */
    username: string;

    /**Authorisierte Rollen */
    roles: string[];
}

/** Definiert das ViewModel für die Kontakt-Informationen
 * @interface
 */
export interface IContactInfoViewModel {
    organisation: string;
    street: string;
    houseNumber: string;
    zip: string;
    town: string;
    email: string;
    phoneNumber: string;
    faxNumber: string;
}

/** Definiert das ViewModel für die StepInfo
 * @interface
 */
export interface ICaseStepInfoViewModel {
    /** Step-Index vom äusseren (Overall) Wizard (0 indexiert) */
    overallStepIndex: number;

    /** Step-Index vom inneren (Detail) Wizard (0 indexiert) */
    detailStepIndex: number;
}

/** Definiert das ViewModel für die Informationen zu einer Befragung zu der sich ein externer Benutzer registriert
 * @interface
 */
export interface IRegistrationInfoViewModel {
    /**Der Name der zur Registration passenden Survey */
    registeredSurveyName: string;
}

/** Definiert das ViewModel für die Daten zu einer Registrierung eines externen Benutzers
 * @interface
 */

export interface IRegistrationDataViewModel {
    /**Der Access-Code für die Registration */
    accessCode: string;

    /**Das E-Mail für die Registration */
    eMailAddress: string;

    /** Das Passwort für die Registration */
    password: string;

    /**Das bestätigte Passwort für die Registration */
    passwordConfirmation: string;
}

/** Definiert die Zugangsdaten für einen Login-Versuch
 * @interface
 */
export interface ICredentialsDto {
    /** Passwort zum Login */
    password: string;

    /** Benutzername zum Login */
    username: string;
}

/** Definiert die Angaben zum Bestätigen der Falldaten
 */
export interface ICaseApprovalDto {
    /* Die Zustimmung zum Abschluss des Falles */
    consent: boolean;
}

/** Definiert ein Dictionary mit einem String-Wert als Key
 */
interface IDictionary<T> {
    [Key: string]: T;
}

/** Definiert die Struktur von Fehlermeldungen zu Validierungen
 */
export interface IValidationErrorViewModel {
    /* Ob die Validierung erfolgreich war */
    isValid: boolean;

    /** Die allfällig vorhandenen Fehlermeldungen */
    errorMessages: IDictionary<string>;
}

/** Implementiert die Struktur von Fehlermeldungen zu Validierungen
 */
export class ValidationErrorViewModel implements IValidationErrorViewModel {
    /** @constructor */
    constructor() {
        this.isValid = false;
        this.errorMessages = {};
    }

    /* Ob die Validierung erfolgreich war */
    isValid: boolean;

    /** Die allfällig vorhandenen Fehlermeldungen */
    errorMessages: IDictionary<string>;
}

/** Implementiert die Zugangsdaten für einen Login-Versuch
 */
export class CredentialsDto implements ICredentialsDto {
    /** @constructor */
    constructor() {
        this.password = '';
        this.username = '';
    }
    password: string;
    username: string;
}

/** Implementiert die Angaben zum Bestätigen der Falldaten
 */
export class CaseApprovalDto implements ICaseApprovalDto {
    /** @constructor */
    constructor() {
        this.consent = false;
    }

    /* Die Zustimmung zum Abschluss des Falles */
    consent: boolean;
}

/** Implementiert die Daten zu einer Registrierung eines externen Benutzers
 */
export class RegistrationDataViewModel implements IRegistrationDataViewModel {
    /** @constructor */
    constructor() {
        this.accessCode = '';
        this.eMailAddress = '';
        this.password = '';
        this.passwordConfirmation = '';
    }

    /**Der Access-Code für die Registration */
    accessCode: string;

    /**Das E-Mail für die Registration */
    eMailAddress: string;

    /** Das Passwort für die Registration */
    password: string;

    /**Das bestätigte Passwort für die Registration */
    passwordConfirmation: string;
}

/** Definiert das ViewModel für die Kontaktdaten für einen Fall
 * @interface
 */
export interface ICaseContactDataViewModel {
    /** The bur enterprise unit identifier */
    burEnterpriseUnitId: number;

    /**  Liefert oder definiert die E-Mail-Adresse (ohne Platzhalter, d.h. leer wenn die Angabe durch die Übersteuerung ausgelassen wird)
     */
    emailAddress: string;

    /** Übersteuerung E-Mail Adresse. Falls true ist keine E-Mail für diesen Kontakt bekannt. Dient dem BFS-Benutzer für Kunden, die keine E-Mail Adresse
     *  besitzen und somit keine Befragung online ausfüllen können.
     */
    emailAddressOverride: boolean;

    /**  Liefert oder definiert die Funktion (Role).
     */
    role: string;

    /**Gets or sets the first name.
     */
    firstName: string;

    /**Gets or sets the language cd.
     * @remarks Kann auch null sein.
     */
    languageCd: number;

    /**Gets or sets the last name.
     */
    lastName: string;

    /**Gets or sets the phone number.
     */
    phoneNumber: string;

    /**Liefert oder definiert den Kommentar des Bemutzers.
     */
    comment: string;

    /**Liefert oder definiert die Bemerkungen des BFS.
     */
    remarksBfs: string;

    /**Gets or sets the salutation cd.
     * @remarks Kann auch null sein.
     */
    salutationCd: number;
}

/** Implementiert das ViewModel für die StepInfo
 * @interface
 */
export class CaseStepInfoViewModel implements ICaseStepInfoViewModel {
    /** @constructor Für Steps mit optionalem Detail-Step
     * @param {number} overallStepIndex Der Overall-Step-Index.
     * @param {number} detailStepIndex Der Detail-Step-Index.
     */
    constructor(overallStepIndex: number, detailStepIndex: number) {
        //Start im Info-Screen
        this.overallStepIndex = overallStepIndex;
        this.detailStepIndex = detailStepIndex;
    }

    /**@inheritdoc */
    overallStepIndex: number;

    /**@inheritdoc */
    detailStepIndex: number;
}

/** Implementiert das ViewModel für die Kontaktdaten für einen Fall
 */
export class CaseContactDataViewModel implements ICaseContactDataViewModel {
    /** @constructor */
    constructor() {
        this.burEnterpriseUnitId = null;
        this.emailAddressOverride = false;
        this.emailAddress = '';
        this.role = '';
        this.firstName = '';
        this.languageCd = null;
        this.lastName = '';
        this.phoneNumber = '';
        this.comment = '';
        this.remarksBfs = '';
        this.salutationCd = null;
        this.lastName = '';
        this.lastName = '';
    }

    /**@inheritdoc */
    burEnterpriseUnitId: number;

    /**
         Übersteuerung E-Mail Adresse. Falls true ist keine E-Mail für diesen Kontakt bekannt. Dient dem BFS-Benutzer für Kunden, die keine E-Mail Adresse
         besitzen und somit keine Befragung online ausfüllen können.
      */
    emailAddressOverride: boolean;

    /**  Liefert oder definiert die E-Mail-Adresse (ohne Platzhalter, d.h. leer wenn die Angabe durch die Übersteuerung ausgelassen wird)
     */
    emailAddress: string;

    /**@inheritdoc */
    role: string;

    /**Gets or sets the first name.
     */
    firstName: string;

    /**Gets or sets the language cd.
     * @remarks Kann auch null sein.
     */
    languageCd: number;

    /**Gets or sets the last name.
     */
    lastName: string;

    /**Gets or sets the phone number.
     */
    phoneNumber: string;

    /**Liefert oder definiert den Kommentar des Bemutzers.
     */
    comment: string;

    /**Liefert oder definiert die Bemerkungen des BFS.
     */
    remarksBfs: string;

    /**Gets or sets the salutation cd.
     * @remarks Kann auch null sein.
     */
    salutationCd: number;
}

/**
 * Datenset mit Angaben.
 */
export interface IDataSetDataObject {
    /**
     * Liefert die Liste der EnterpriseUnits.
     */
    enterpriseUnits: Array<IEnterpriseUnitDataObject>;
}

/** implementiert IDataSetDataObject */
export class DataSetDataObject implements IDataSetDataObject {
    /** @constructor */
    constructor() {
        this.enterpriseUnits = null;
    }

    /** @inheritdoc */
    enterpriseUnits: Array<IEnterpriseUnitDataObject>;
}

//TODO für EnterpriseUnitDeleteReason und LocalUnitDeleteReason könnte zur Präzisierung noch eine Enum definiert werden

/** Mögliche Edit-Stati einer EnterpriseUnit gemäss Tabelle <see cref="TypeErstEnterpriseUnitEditStatus" /> */
export enum EnterpriseUnitStatus {
    Unknown = 0,
    NoChange = 1,
    New = 2,
    Deleted = 3,
    Changed = 4
}

/**   Mögliche Edit-Stati einer LocalUnit gemäss Tabelle <see cref="TypeErstLocalUnitEditStatus"/>
 * @remarks Siehe https://collaboration.nowhow.ch/erst/foswiki/bin/view/Erst/DesignStatusUebergaengeLocalUnit
 */
export enum LocalUnitStatus {
    /** Unbekannter Status */
    Unknown = 0,

    /**NoChange (Unveränderte LU aus SBER) */
    NoChange = 1,

    /** New (Neu angelegte LU) */
    New = 2,

    /** Gelöscht */
    Deleted = 3,

    /** Deactivated (Existierende LU welche deaktiviert wurde) */
    Deactivated = 4,

    /** Changed (Existierende LU an welcher Daten angepasst wurden) */
    Changed = 5
}

export interface IEnterpriseUnitDataObject {
    /** Liefert oder definiert das CareOf (c/o) */
    careOf: string;

    /** Liefert oder definiert die allfällig vorhandene BUR Enterprise Unit Id während dem Bearbeiten einer im ERST Case neu erstellten Enterprise Unit. */
    burEnterpriseUnitId: number | null;
    commentText: string;

    /** Liefert oder definiert den Edit-Reason Code (Grund). */
    editReasonCd: number | null;
    editReasonText: string;
    editStatus: EnterpriseUnitStatus;
    editStatusCd: number;

    /** Liefert oder definiert die EnterpriseUnitId (Datenbank-Id, Primary Key)
     * @remarks     Dies ist nicht die BUR-EnterpriseUnitId, welche einen Fremdschlüssel im SBER-Umsystem darstellt.
     * @devdoc Diese Id kann zu Referenzierungs-Zwecken im Client verwendet werden
     */

    enterpriseUnitId: number;

    /** Liefert oder definiert den Namen
     * @remarks  Dies ist der in ERST verwendete Name für die Eingabe des Externen Benutzers.
     */
    legalUnitName: string;

    /** Liefert oder definiert den Geschäftsnamen (Ergänzung des Namens) des Unternehmens. */
    nameBusiness: string;
    postOfficeBoxNumber: number | null;
    postOfficeBoxTown: string;

    /** Liefert oder definiert die PLZ des Postfachs. */
    postOfficeBoxZipCode: number | null;
    street: string;

    /** Liefert oder definiert die Hausnummer */
    houseNumber: string;

    /** Liefert oder definiert den Adresszusatz */
    additionalTxt: string;

    /** Liefert oder definiert den Ort */
    town: string;

    /** Liefert oder definiert die Gemeinde */
    municipality: string;

    /** Liefert oder definiert den Kantonsbezeichner */
    canton: string;

    /** Liefert oder definiert die EgId */
    egId: number | null;

    /** Liefert oder definiert die EdId */
    edId: number | null;

    /** Liefert oder definiert die EstrId
     * @remarks Die EstrId stammt aus SBER (und ist nicht via Swisstopo erhältlich)
     */
    estrId: number | null;

    /** Liefert oder definiert die EsId
     * @remarks Die EsId stammt aus Swisstopo und wird als StreetId an SBER gemeldet.
     */
    esId: number | null;

    /** Liefert oder definiert die GdeId */
    gdeId: number | null;

    /** Liefert oder definiert den Landesbezeichner */
    country: string;

    /** Liefert oder definiert die UID des Unternehmens */
    uidId: string;

    /** Liefert oder definiert die PLZ des Postfachs. */
    zipCode: number | null;

    /** Gibt an, ob die Angabe der Adressdaten via Swisstopo abgesagt wird
     */
    isSwisstopoDismissed: boolean;

    /** Liefert alle zugehörigen LocalUnits (als flache Liste, ohne Tree-Information.) */
    localUnits: Array<ILocalUnitDataObject>;
}

export interface ILocalUnitDataObject {
    /** Gibt an, ob für diesen Betrieb eine AHV-Pflicht besteht.
     * Diese Angabe ist nur informell, die Nutzdaten sind in den Census-Angaben enthalten.
     * @remarks Implementiert die Anforderung 010 aus DatenErfassungLocalCensusScreen
     */
    hasNoAhvObligation: boolean;

    /** Liefert oder definiert das CareOf (c/o) */
    careOf: string;

    /** Liefert oder definiert die BUR-LocalUnitId (Fremdschlüssel in SBER)
     * @devdoc Dies ist nicht der Datenbank-PrimaryKey
     */
    burLocalUnitId: number;

    /** Liefert oder definiert den Prefix zur BUR-LocalUnitId (Fremdschlüssel in SBER)
     * @devdoc Dies ist nicht der Datenbank-PrimaryKey
     */
    burLocalUnitIdPrefix: string;

    /** Liefert oder definiert den vollständige zur BUR-LocalUnitIdentifier (Prefix mit Id) (Fremdschlüssel in SBER)
     * @devdoc Dies ist nicht der Datenbank-PrimaryKey
     */
    prefixedBurLocalUnitId: string;

    /** Liefert oder definiert den Grund, zuhanden Erst, für die Eingabe der Census-Daten. */
    censusReasonText: string;

    /** Liefert oder definiert den generellen Kommentar. */
    commentText: string;

    /** Liefert oder definiert den Edit-Reason Code (Grund). */
    editReasonCd: number | null;

    /** Liefert oder definiert den Grund für die Bearbeitung. */
    editReasonText: string;

    /** Liefert den Edit-Status. */
    editStatus: LocalUnitStatus;

    /** Liefert oder definiert den Bearbeitungsstatus der LocalUnit. */
    editStatusCd: number;

    /** Liefert oder definiert die BUR-EnterpriseUnitId (Fremdschlüssel in SBER)
     * @remarks Wenn dieses Objekt aus SBER-Daten erstellt wird, wird der SBER-Fremdschlüssel aus <see cref="SberEnterpriseUnit.EnterpriseUnitId" /> verwendet.
     * Wenn dieses Objekt aus Erst-Daten erstellt wird, wird das Feld <see cref="ErstEnterpriseUnit.BurEnterpriseUnitId" /> verwendet.
     * Die Angabe kann auch leer sein, für LocalUnits die einer in ERST neu erstellten EnterpriseUnit untergeordnet sind.
     * @devdoc Dies ist nicht der Datenbank-PrimaryKey
     */
    enterpriseUnitId: number | null;

    /** Gets or sets the 100% female */
    fullFemale: number | null;

    /** Gets or sets the 100% male */
    fullMale: number | null;
    fullTimeEquivalentFemale: number | null;
    fullTimeEquivalentMale: number | null;

    /** Liefert die Datenbank-Id (Primary Key innerhalb von Erst) */
    localUnitId: number;

    /**  Liefert oder definiert den Namen der Arbeitsstätte. */
    name: string;

    /** Liefert oder definiert den Geschäftsnamen (Ergänzung des Namens) der Arbeitsstätte. */
    nameBusiness: string;

    /** Liefert oder definiert den MainActivityNogaCode
     * @remarks Der NOGA-Code kann in der Entry-Box nicht verändert werden
     */
    mainActivityNogaCode: number | null;

    /** Liefert oder definiert die Eingabe des Externen Benutzers zum Beschrieb der Tätigkeit. */
    activityDescription: string;

    /** Gets or sets the 50-90% female. */
    part1Female: number | null;

    /** Gets or sets the 50-90% male. */
    part1Male: number | null;

    /** Gets or sets the less than 50% female. */
    part2Female: number | null;

    /** Gets or sets the less than 50% male. */
    part2Male: number | null;

    /** Gets or sets the less than 50% female. */
    part3Female: number | null;

    /** Gets or sets the less than 50% male. */
    part3Male: number | null;
    postOfficeBoxNumber: number | null;
    postOfficeBoxTown: string;
    postOfficeBoxZipCode: number | null;
    postOfficeBoxZipCodeAddOn: number | null;

    street: string;
    houseNumber: string;

    /** Liefert oder definiert den Adresszusatz */
    additionalTxt: string;
    town: string;
    typeCd: number | null;
    zipCode: number | null;
    zipCodeAddOn: number | null;

    /** Liefert oder definiert die Gemeinde */
    municipality: string;

    /** Liefert oder definiert den Kantonsbezeichner */
    canton: string;

    /** Liefert oder definiert die EgId */
    egId: number | null;

    /** Liefert oder definiert die EdId */
    edId: number | null;

    /** Liefert oder definiert die EstrId
     * @remarks Die EstrId stammt aus SBER (und ist nicht via Swisstopo erhältlich)
     */
    estrId: number | null;

    /** Liefert oder definiert die EsId
     * @remarks Die EsId stammt aus Swisstopo und wird als StreetId an SBER gemeldet.
     */
    esId: number | null;

    /** Liefert oder definiert die GdeId */
    gdeId: number | null;

    /** Liefert oder definiert den Landesbezeichner */
    country: string;

    /** Liefert oder definiert den Aktivitätsdaten */
    activity: IActivityDataObject;

    /** Liefert oder definiert die Telefonnummer des Betriebs */
    phoneNumber: string | null;

    /** Liefert oder definiert die Email-Adresse des Betriebs. */
    emailAddress: string | null;

    /** Liefert oder definiert die Website des Betriebs */
    websiteAddress: string | null;

    /** Gibt an, ob die Eingabe der Telefonnummer des Betriebs abgesagt wird
     */
    isPhoneNumberDismissed: boolean;

    /** Gibt an, ob die Eingabe der Email-Adresse des Betriebs abgesagt wird
     */
    isEmailAddressDismissed: boolean;

    /** Gibt an, ob die Eingabe der Website des Betriebs abgesagt wird
     */
    isWebsiteAddressDismissed: boolean;

    /** Gibt an, ob die Angabe der Adressdaten via Swisstopo abgesagt wird
     */
    isSwisstopoDismissed: boolean;
}

export interface IActivityDataObject {
    /** Liefert oder definiert die Eingabe des Externen Benutzers zu "Ist der Betrieb aktiv" */
    isActivityBegin: boolean | null;

    /** Liefert oder definiert die Eingabe des Externen Benutzers zu "Im Handel tätig" */
    isMerchant: boolean | null;

    /** Liefert oder definiert die Auswahl des Externen Benutzers zu "Handelstätigkeit"
     * @remarks null=Keine Auswahl, 1=Im Detailhandel, 2=Im Grosshandel
     */
    merchantKind: number | null;

    /** Liefert oder definiert die Eingabe des Externen Benutzers zu "Handelt es sich um eine saisonale Tätigkeit" */
    isSeasonActivity: boolean | null;

    /** Liefert oder definiert die Auswahl des Externen Benutzers zu "Um was handelt es sich bei diesem Betrieb"
     * @remarks null=Keine Auswahl,
     * 1=Neugründung,
     * 2=Rechtsformänderung,
     * 3=Übernahme / Pächterwechsel,
     * 4=Reaktivierung,
     * 5=Zusammenlegung / Fusion,
     * 6=Aufspaltung / Splitting,
     * 7=Andere
     */
    creationReasonSelection: number | null;

    /** Liefert oder definiert die Auswahl des Externen Benutzers zu "Um welche Art handelt es sich bei diesem Betrieb"
     * @remarks null=Keine Auswahl,
     * 1=Einzelbetrieb,
     * 2=Betrieb mit mehreren Arbeitsstätten
     */
    kind: number | null;

    /** Liefert oder definiert die Auswahl des Externen Benutzers zu "Von wem wurde der Betrieb gegründet"
     * @remarks  null=Keine Auswahl,
     * 1=Nur Männer,
     * 2=Nur Frauen,
     * 3=Männer und Frauen
     */
    foundersComposition: number | null;

    /** Liefert oder definiert die Auswahl des Externen Benutzers zu "Grund" bei inaktivität
     * @remarks  null=Keine Auswahl,
     * 1=Tätigkeitsaufnahme geplant für ...,
     * 2=Aufgabe der Tätigkeit per ...,
     * 3=Tätigkeitsaufnahme nicht vorgesehen
     */
    inactivityReason: number | null;

    /** Liefert oder definiert die Eingabe des Externen Benutzers für den Monat zum "Datum" für die Aktivität
     * @remarks Bedeutung ist abhängig von anderen Angaben. Die Angabe wird aus Konsistenzgründen mit den Umsystemen nicht als Datumstyp erfasst.
     */
    activityDateMonth: number | null;

    /** Liefert oder definiert die Eingabe des Externen Benutzers für das Jahr zum "Datum" für die Aktivität
     * @remarks Bedeutung ist abhängig von anderen Angaben. Die Angabe wird aus Konsistenzgründen mit den Umsystemen nicht als Datumstyp erfasst.
     */
    activityDateYear: number | null;

    /** Liefert oder definiert die Angabe des Externen Benutzers zu "Name und Adresse des früheren Betriebs / Pächters" */
    nameActivityBefore: string | null;

    /** Liefert oder definiert die Angabe des Externen Benutzers zu "Beschreibung" für die Auswahl zur Gründung des Betriebs  */
    creationReasonDescription: string | null;

    /** Liefert oder definiert die Auswahl des Externen Benutzers zu "Zeitraum der saisonalen Tätigkeit"
     * @remarks Die Angabe wird als int mit 12bit gespeichert, für jeden Monat ein Bit */
    seasonSelection: Array<string>;
}

/** Definiert das ViewModel für die vollen Daten für einen Fall
 * @interface
 */
export interface ICaseFullDataViewModel {
    /** Liefert oder definiert die Klassifikation des Falles.
     * @remarks Diese Angabe ist read-only, die Klassifikation wird beim Erstellen des Falles aus Angaben von SBER bestimmt.
     */
    caseClassificationCd: number;

    /** Liefert oder definiert die Fall-Id
     * @remarks Diese Angabe ist read-only, der Wert wird einmalig beim Erstellen des Falles festgelegt.
     * */
    caseId: bigint;

    /** Liefert oder definiert den Namen des Falls
     * @remarks Dies entspricht dem Namen der befragten Unternehmung oder dem Name der befragten Unternehmens-Gruppe.
     * Diese Angabe ist read-only, der Wert wird einmalig beim Erstellen des Falles festgelegt. Diese Angabe ist nur zur Information für den Benutzer.
     */
    caseName: string;

    /** Liefert oder definiert den den aktuellen Wizard step.
     */
    caseStepInfo: ICaseStepInfoViewModel;

    /** Die UID des Father-Unternehmen für diesen Fall
     * */
    caseUid: string;

    /** Liefert oder definiert die Kontaktdaten welche zum Unternehmen des bearbeiteten Falles gehören.
     */
    generalEnterpriseContactDataObject: ICaseContactDataViewModel;

    /** DataSetDataObject */
    userDataSet: IDataSetDataObject;
}

/** Implementiert das ViewModel für die vollen Daten für einen Fall
 */
export class CaseFullDataViewModel implements ICaseFullDataViewModel {
    /** @constructor */
    constructor() {
        this.caseClassificationCd = null;
        this.caseId = null;
        this.caseName = '';
        this.caseStepInfo = new CaseStepInfoViewModel(0, 0);
        this.caseUid = '';
        this.generalEnterpriseContactDataObject = new CaseContactDataViewModel();
        this.sumFullTimeEquivalent = null;
        this.userDataSet = new DataSetDataObject();
    }

    /** Liefert oder definiert die Klassifikation des Falles.
     * @remarks Diese Angabe ist read-only, die Klassifikation wird beim Erstellen des Falles aus Angaben von SBER bestimmt.
     */
    caseClassificationCd: number;

    /** Liefert oder definiert die Fall-Id
     * @remarks Diese Angabe ist read-only, der Wert wird einmalig beim Erstellen des Falles festgelegt.
     * */
    caseId: bigint;

    /** Liefert oder definiert den Namen des Falls
     * @remarks Dies entspricht dem Namen der befragten Unternehmung oder dem Name der befragten Unternehmens-Gruppe.
     * Diese Angabe ist read-only, der Wert wird einmalig beim Erstellen des Falles festgelegt. Diese Angabe ist nur zur Information für den Benutzer.
     */
    caseName: string;

    /** Liefert oder definiert den den aktuellen Wizard step.
     */
    caseStepInfo: ICaseStepInfoViewModel;

    /** Die UID des Father-Unternehmen für diesen Fall
     * */
    caseUid: string;

    /** Liefert oder definiert die Kontaktdaten welche zum Unternehmen des bearbeiteten Falles gehören.
     */
    generalEnterpriseContactDataObject: ICaseContactDataViewModel;

    /** @inheritdoc */
    userDataSet: IDataSetDataObject;

    /** @inheritdoc */
    sumFullTimeEquivalent: number;
}
