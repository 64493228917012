


































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import NowhowModal from '@/components/NowhowModal.vue';

@Component({
    components: {
        NowhowModal
    }
})

/** Ein Titel für einen Wizard-Step. Ermöglicht die Darstellung eines Hilfetextextes als Zusatz zur Überschrift
 * @remarks Implementiert die "Zusatzinformationen für jeden Prozessschritt" mittels eines simplen Overlays
 */
export default class StepHeader extends Vue {
    /** Das Label (Ressource) für den Hilfetext (kann auch HTML-Code enthalten) */
    @Prop({ required: true, default: '', type: String }) private helpLabel!: string;

    /** Das Label (Ressource) für den Titel */
    @Prop({ required: false, default: '', type: String }) private headerLabel!: string;

    /** Text für den Titel (wird nach dem Label angezeigt)
     * @remarks Als default wird ein leerer String verwendet
     */
    @Prop({ required: false, type: [String], default: '' }) private headerText!: string;

    @Prop({ required: false, type: String, default: 'stepHeader' }) private id!: string;

    /** Nur auf Verlangen anzeigen */
    showHelp = false;

    mounted() {
        console.debug('StepHeader.vue::mounted');
    }

    closeModal() {
        this.showHelp = false;
    }

    visibilityChanged(value) {
        this.showHelp = value;
    }
}
