var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("StepHeader", {
        attrs: {
          "header-label": "page_enc_wzrdtitle",
          "help-label": "page_enc_help"
        }
      }),
      _c("ValidationObserver", {
        ref: "observer",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var invalid = ref.invalid
              return [
                _c(
                  "form",
                  { staticClass: "form-horizontal", attrs: { role: "form" } },
                  [
                    _c("fieldset", [
                      _c("legend", {
                        directives: [
                          {
                            name: "t",
                            rawName: "v-t",
                            value: "page_enc_legendtitle",
                            expression: "'page_enc_legendtitle'"
                          }
                        ]
                      }),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("NowhowDropdown", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: false,
                                expression: "false"
                              },
                              { name: "focus", rawName: "v-focus" }
                            ],
                            attrs: {
                              id: "caseContactDataSalutationCd",
                              items: _vm.dropdownSalutationItems,
                              label: "contact_salutation",
                              validate: _vm.getValidation(
                                "contactDataObject.salutationCd"
                              )
                            },
                            model: {
                              value: _vm.caseContactData.salutationCd,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.caseContactData,
                                  "salutationCd",
                                  $$v
                                )
                              },
                              expression: "caseContactData.salutationCd"
                            }
                          }),
                          _c("NowhowTextbox", {
                            attrs: {
                              id: "caseContactDataFirstName",
                              label: "contact_firstname",
                              validate: _vm.getValidation(
                                "contactDataObject.firstName"
                              )
                            },
                            model: {
                              value: _vm.caseContactData.firstName,
                              callback: function($$v) {
                                _vm.$set(_vm.caseContactData, "firstName", $$v)
                              },
                              expression: "caseContactData.firstName"
                            }
                          }),
                          _c("NowhowTextbox", {
                            attrs: {
                              id: "caseContactDataLastName",
                              label: "contact_lastname",
                              validate: _vm.getValidation(
                                "contactDataObject.lastName"
                              )
                            },
                            model: {
                              value: _vm.caseContactData.lastName,
                              callback: function($$v) {
                                _vm.$set(_vm.caseContactData, "lastName", $$v)
                              },
                              expression: "caseContactData.lastName"
                            }
                          }),
                          _c("NowhowTextbox", {
                            attrs: {
                              id: "caseContactDataRole",
                              label: "contact_role",
                              validate: _vm.getValidation(
                                "contactDataObject.role"
                              )
                            },
                            model: {
                              value: _vm.caseContactData.role,
                              callback: function($$v) {
                                _vm.$set(_vm.caseContactData, "role", $$v)
                              },
                              expression: "caseContactData.role"
                            }
                          }),
                          _c("br"),
                          _c("NowhowTextbox", {
                            attrs: {
                              id: "caseContactDataPhoneNumber",
                              label: "contact_phone",
                              validate: _vm.getValidation(
                                "contactDataObject.phoneNumber"
                              )
                            },
                            model: {
                              value: _vm.caseContactData.phoneNumber,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.caseContactData,
                                  "phoneNumber",
                                  $$v
                                )
                              },
                              expression: "caseContactData.phoneNumber"
                            }
                          }),
                          _vm.isExternalUser ||
                          (!_vm.isExternalUser &&
                            !_vm.caseContactData.emailAddressOverride)
                            ? _c("NowhowTextbox", {
                                attrs: {
                                  id: "caseContactDataEmailAddress",
                                  label: "contact_email",
                                  validate:
                                    _vm.getValidation(
                                      "contactDataObject.emailAddress"
                                    ) + "|required"
                                },
                                model: {
                                  value: _vm.caseContactData.emailAddress,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.caseContactData,
                                      "emailAddress",
                                      $$v
                                    )
                                  },
                                  expression: "caseContactData.emailAddress"
                                }
                              })
                            : _vm._e(),
                          !_vm.isExternalUser
                            ? _c("NowhowCheckbox", {
                                attrs: {
                                  id: "caseContactDataEmailAddressOverride",
                                  label: "contact_hint_noemailaddress"
                                },
                                model: {
                                  value:
                                    _vm.caseContactData.emailAddressOverride,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.caseContactData,
                                      "emailAddressOverride",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "caseContactData.emailAddressOverride"
                                }
                              })
                            : _vm._e(),
                          _c("br"),
                          _c("NowhowDropdown", {
                            attrs: {
                              id: "caseContactDataLanguageCd",
                              items: _vm.dropdownLanguageItems,
                              label: "allg_language",
                              validate: _vm.getValidation(
                                "contactDataObject.languageCd"
                              )
                            },
                            model: {
                              value: _vm.caseContactData.languageCd,
                              callback: function($$v) {
                                _vm.$set(_vm.caseContactData, "languageCd", $$v)
                              },
                              expression: "caseContactData.languageCd"
                            }
                          }),
                          _c("br"),
                          _c("NowhowTextarea", {
                            attrs: {
                              id: "caseContactDataComment",
                              label: "contact_comment",
                              validate: _vm.getValidation(
                                "contactDataObject.comment"
                              )
                            },
                            model: {
                              value: _vm.caseContactData.comment,
                              callback: function($$v) {
                                _vm.$set(_vm.caseContactData, "comment", $$v)
                              },
                              expression: "caseContactData.comment"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("NowhowRequiredInfoline")
                  ],
                  1
                ),
                _c("FooterBarRightButtonProxy", {
                  attrs: { id: "ButtonNextProxy", disabled: invalid }
                })
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }