











































































































































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Pikaday from 'pikaday';
import { GET_VALIDATION_KEY } from '@/store/modules/validation/getters';

import NowhowTextbox from '@/components/NowhowTextbox.vue';
import NowhowDatebox from '@/components/NowhowDatebox.vue';
import NowhowTextline from '@/components/NowhowTextline.vue';
import NowhowTextarea from '@/components/NowhowTextarea.vue';
import NowhowRadioList from '@/components/NowhowRadioList.vue';
import NowhowCheckboxList from '@/components/NowhowCheckboxList.vue';
import NowhowSeasonPicker from '@/components/NowhowSeasonPicker.vue';
import NowhowDropdown from '@/components/NowhowDropdown.vue';
import NowhowDropdownYesNoNull from '@/components/NowhowDropdownYesNoNull.vue';
import NowhowUInt32Box from '@/components/NowhowUInt32Box.vue';
import NowhowRequiredInfoline from '@/components/NowhowRequiredInfoline.vue';
import { ISelectListItem } from '@/types/clienttypes';

import { NAME } from '../store/index';
const modul = namespace(NAME);

import { NAME as NAME_VALIDATION } from '@/store/modules/validation';
const validationModul = namespace(NAME_VALIDATION);

import { SUBMIT_LOCAL_UNIT_ACTIVITY_DATA, SUBMIT_LOCAL_UNIT_ADDRESS_DATA } from '../store/actions';
import { CASE_ID } from '../store/getters';

import { ILocalUnitDataObject, IActivityDataObject } from '@/api-viewmodel';

@Component({
    components: {
        NowhowTextline,
        NowhowTextbox,
        NowhowDatebox,
        NowhowTextarea,
        NowhowRadioList,
        NowhowCheckboxList,
        NowhowSeasonPicker,
        NowhowDropdown,
        NowhowDropdownYesNoNull,
        NowhowUInt32Box,
        NowhowRequiredInfoline
    }
})

/** Bereich für die Bearbeitung der Adress-Details einer Arbeitsstätte*/
export default class LocalUnitEditActivity extends Vue {
    //Getters
    @modul.Getter(CASE_ID) caseId!: bigint;
    @validationModul.Getter(GET_VALIDATION_KEY) getValidation!: (key: string) => string;

    //Actions
    @modul.Action(SUBMIT_LOCAL_UNIT_ACTIVITY_DATA) submitLocalUnitActivityData!: Function;
    @modul.Action(SUBMIT_LOCAL_UNIT_ADDRESS_DATA) submitLocalUnitAddressData!: Function;

    @Prop({ required: true, type: Object })
    private activity!: IActivityDataObject;

    @Prop({ required: true, type: Object })
    private localUnit!: ILocalUnitDataObject;

    dropdownDeleteReasonItems = [];

    mounted(): void {
        //Aktuelles Token der unveränderte Daten für die spätere, erstmalige Prüfung merken
        this.lastSubmittedActivityDataToken = this.submittableActivityDataToken;
        this.lastSubmittedLocalUnitAddressDataToken = this.submittableLocalUnitAddressDataToken;

        //Pikaday einrichten für das benötigte Feld
        new Pikaday({ field: document.getElementById('datepicker') });
    }

    beforeDestroy() {
        this.saveStepData();
    }

    /** Behandelt die Änderung von Feld "Ist der Betrieb zurzeit aktiv" auf "Ja"
     * @remarks Implementiert Anforderung 006 und 010 in https://collaboration.nowhow.ch/erst/foswiki/bin/view/Erst/DatenErfassungLocalTaetigkeitScreen
     * @devdoc Siehe auch Bug #14049 Datenerfassung Tätigkeit Screen: Bei Aktiv=NEIN kommt der Benutzer nicht [Weiter]
     * @devdoc Siehe auch Bug #13797 Datenerfassung Tätigkeit: Eingegebene Werte verschwinden auf dem GUI nach [WEITER] und [ZURÜCK]
     */
    @Watch('activity.isActivityBegin', { deep: false, immediate: false })
    private onIsActivityBeginChanged(newVal: boolean | null, oldVal: boolean | null) {
        console.debug(`LocalUnitEditActivity::onIsActivityBeginChanged:newVal:='${newVal}';oldVal:=${oldVal}`);

        //Neu auf Ja?
        if (newVal === true && oldVal !== true) {
            if (this.activity) {
                //Alles auf leer / null / zero setzen
                this.activity.inactivityReason = null;
                this.activity.activityDateMonth = null;
                this.activity.activityDateYear = null;
                this.localUnit.activityDescription = null;
                this.activity.isMerchant = null;
                this.activity.merchantKind = null;
                this.activity.isSeasonActivity = null;
                this.activity.seasonSelection = [];
                this.activity.creationReasonSelection = null;
                this.activity.creationReasonDescription = null;
                this.activity.nameActivityBefore = null;
                this.activity.kind = null;
                this.activity.foundersComposition = null;

                //Submit explizit forcieren
                this.lastSubmittedActivityDataToken = undefined;
                this.lastSubmittedLocalUnitAddressDataToken = undefined;
            }
        }
        //Neu auf Nein?
        if (newVal === false && oldVal !== false) {
            if (this.activity) {
                //Werte gemäss Vorgabe setzen (HINT: Eine analoge Vorgabe gibt es auch für den
                //Papierfragebogen-Import, siehe DAPA-090)
                this.activity.isMerchant = false;
                this.activity.isSeasonActivity = false;
                this.activity.creationReasonSelection = 7; //"Andere (bitte beschreiben)"
                this.activity.creationReasonDescription = 'no (auto text)';
                this.activity.nameActivityBefore = 'no (auto text)';
                this.activity.kind = 1; //"Einzelbetrieb"

                //Submit explizit forcieren
                this.lastSubmittedActivityDataToken = undefined;
                this.lastSubmittedLocalUnitAddressDataToken = undefined;
            }
        }
    }

    /** Liefert die zur Auswahl stehenden Gründe für die Aufgabe der Tätigkeit
     * @devdoc Weil diese Items hier nur einmal verwendet werden, ist kein Clone notwendig
     */
    dropdownInactivityReasonItems: Array<ISelectListItem> = [
        {
            /*Default, keine Auswahl*/
            value: null,
            text: '',
            selected: false,
            disabled: false
        },
        {
            /*Tätigkeitsaufnahme geplant für ... (wenn gewählt, einblenden von Feld Datum)*/
            value: 1,
            text: 'lu_activity_option_planned_by',
            selected: false,
            disabled: false
        },
        {
            /*Aufgabe der Tätigkeit per ... (wenn gewählt, einblenden von Feld Datum)*/
            value: 2,
            text: 'lu_activity_option_cessation_by',
            selected: false,
            disabled: false
        },
        {
            /*Tätigkeitsaufnahme nicht vorgesehen*/
            value: 3,
            text: 'lu_activity_option_not_planned',
            selected: false,
            disabled: false
        }
    ];

    /** Liefert die zur Auswahl stehenden Handelstätigkeiten
     * @devdoc Weil diese Items hier nur einmal verwendet werden, ist kein Clone notwendig
     */
    dropdownMerchantKindItems: Array<ISelectListItem> = [
        {
            /*Default, keine Auswahl*/
            value: null,
            text: '',
            selected: false,
            disabled: false
        },
        {
            /* Im Detailhandel */
            value: 1,
            text: 'lu_merchant_option_retail',
            selected: false,
            disabled: false
        },
        {
            /*Im Grosshandel*/
            value: 2,
            text: 'lu_merchant_option_wholesale',
            selected: false,
            disabled: false
        }
    ];

    /** Liefert die zur Auswahl stehenden Tätigkeitszeiträume
     * @devdoc Weil diese Items hier nur einmal verwendet werden, ist kein Clone notwendig
     * @devdoc Die Auswahl wird in einen Bitwise-Int umgewandelt mit 12 bits, jedes für einen Monat (Januar = 1, Etc...)
     */
    checkboxSeasonalActivityPeriodItems: Array<ISelectListItem> = [
        {
            value: 'JAN',
            text: 'allg_january',
            selected: false,
            disabled: false
        },
        {
            value: 'FEB',
            text: 'allg_february',
            selected: false,
            disabled: false
        },
        {
            value: 'MRC',
            text: 'allg_march',
            selected: false,
            disabled: false
        },
        {
            value: 'APR',
            text: 'allg_april',
            selected: false,
            disabled: false
        },
        {
            value: 'MAY',
            text: 'allg_may',
            selected: false,
            disabled: false
        },
        {
            value: 'JUN',
            text: 'allg_june',
            selected: false,
            disabled: false
        },
        {
            value: 'JUL',
            text: 'allg_july',
            selected: false,
            disabled: false
        },
        {
            value: 'AUG',
            text: 'allg_august',
            selected: false,
            disabled: false
        },
        {
            value: 'SEP',
            text: 'allg_september',
            selected: false,
            disabled: false
        },
        {
            value: 'OCT',
            text: 'allg_october',
            selected: false,
            disabled: false
        },
        {
            value: 'NOV',
            text: 'allg_november',
            selected: false,
            disabled: false
        },
        {
            value: 'DEC',
            text: 'allg_december',
            selected: false,
            disabled: false
        }
    ];

    /** Liefert die zur Auswahl stehenden Gründe für die Erstellung
     * @devdoc Weil diese Items hier nur einmal verwendet werden, ist kein Clone notwendig
     */
    dropdownCreationReasonItems: Array<ISelectListItem> = [
        {
            /*Default, keine Auswahl*/
            value: null,
            text: '',
            selected: false,
            disabled: false
        },
        {
            /* Neugründung */
            value: 1,
            text: 'lu_creation_option_startup',
            selected: false,
            disabled: false
        },
        {
            /* Rechtsformänderung */
            value: 2,
            text: 'lu_creation_option_legalchange',
            selected: false,
            disabled: false
        },
        {
            /* Übernahme / Pächterwechsel */
            value: 3,
            text: 'lu_creation_option_takeover',
            selected: false,
            disabled: false
        },
        {
            /* Reaktivierung */
            value: 4,
            text: 'lu_creation_option_reactivation',
            selected: false,
            disabled: false
        },
        {
            /* Zusammenlegung / Fusion */
            value: 5,
            text: 'lu_creation_option_merger',
            selected: false,
            disabled: false
        },
        {
            /* Aufspaltung / Splitting */
            value: 6,
            text: 'lu_creation_option_split',
            selected: false,
            disabled: false
        },
        {
            /* Andere (bitte beschreiben) (wenn gewählt, einblenden von Feld Beschreibung)
             */
            value: 7,
            text: 'lu_creation_option_other',
            selected: false,
            disabled: false
        }
    ];

    /** Liefert die zur Auswahl stehenden Arten der Local Unit
     * @devdoc Weil diese Items hier nur einmal verwendet werden, ist kein Clone notwendig
     */
    dropdownKindItems: Array<ISelectListItem> = [
        {
            /*Default, keine Auswahl*/
            value: null,
            text: '',
            selected: false,
            disabled: false
        },
        {
            /* Einzelbetrieb */
            value: 1,
            text: 'lu_kind_option_single',
            selected: false,
            disabled: false
        },
        {
            /* Betrieb mit mehreren Arbeitsstätten */
            value: 2,
            text: 'lu_kind_option_multi',
            selected: false,
            disabled: false
        }
    ];

    /** Liefert die zur Auswahl stehenden Zusammensetzungen der Gründer der Local Unit
     * @devdoc Weil diese Items hier nur einmal verwendet werden, ist kein Clone notwendig
     */
    dropdownFoundersCompositionItems: Array<ISelectListItem> = [
        {
            /*Default, keine Auswahl*/
            value: null,
            text: '',
            selected: false,
            disabled: false
        },
        {
            /* Ausschliesslich von einem / mehrerer Männern */
            value: 1,
            text: 'lu_founders_option_onlymen',
            selected: false,
            disabled: false
        },
        {
            /* Ausschliesslich von einer / mehreren Frauen */
            value: 2,
            text: 'lu_founders_option_onlywomen',
            selected: false,
            disabled: false
        },
        {
            /* Einem / mehreren Männern und einer / mehreren Frauen */
            value: 3,
            text: 'lu_founders_option_mixed',
            selected: false,
            disabled: false
        }
    ];

    /** Speichert die aktuell eingegebenen Werte auf das Backend
     * @remarks Die Daten werden nur versendet, wenn diese seit dem letzten Versand oder erstmalig geändert haben
     * Für diese Prüfung werden alle Eingabewerte dieser Komponente verwendet
     * @devdoc Diese Funktion soll beim Verlassen des Bearbeitungsformulars aufgerufen werden
     * Hinweis: Es kann aktuell nicht die Funktionalität der ValidationObserver-Komponente verwendet werden,
     * weil diese keinen zwischenzeitlichen Reset der "Dirty-Flags" erlaubt.
     * Hinweis2: Es wird nicht ein "dirty-tracking" via "changed"-Event implementiert, um nur zum Zeitpunkt des
     * Versandes effektiv veränderte Daten zu versenden.
     */
    saveStepData() {
        console.debug('LocalUnitEditActivity::saveStepData');

        const submittableActivityDataToken = this.submittableActivityDataToken;
        console.debug('LocalUnitEditActivity::submitLocalUnitActivityData', submittableActivityDataToken);
        if (submittableActivityDataToken !== this.lastSubmittedActivityDataToken) {
            this.submitLocalUnitActivityData({
                caseId: this.caseId,
                data: this.activity
            });
            this.lastSubmittedActivityDataToken = submittableActivityDataToken;
        }

        const submittableLocalUnitAddressDataToken = this.submittableLocalUnitAddressDataToken;
        console.debug('LocalUnitEditActivity::submitLocalUnitAddressData', submittableLocalUnitAddressDataToken);
        if (submittableLocalUnitAddressDataToken !== this.lastSubmittedLocalUnitAddressDataToken) {
            this.submitLocalUnitAddressData({
                caseId: this.caseId,
                data: this.localUnit
            });
            this.lastSubmittedLocalUnitAddressDataToken = submittableLocalUnitAddressDataToken;
        }
    }

    /** Zwischenspeicher für die Prüfung, ob ein erneuter Versand der Daten an den Activity-Endpunkt notwendig ist */
    lastSubmittedActivityDataToken: string = null;

    /** Zwischenspeicher für die Prüfung, ob ein erneuter Versand der Daten an den LocalUnitAddress-Endpunkt notwendig ist */
    lastSubmittedLocalUnitAddressDataToken: string = null;

    /** Liefert ein Token für die versendbaren Daten zur späteren Prüfung, ob ein erneuter Versand an den Activity-Endpunkt notwendig ist.
     * @devdoc Für den effektiven Versand an die API wird das Objekt aus dem Zustand verwendet,
     *  nicht dieses interne DTO.
     */
    get submittableActivityDataToken() {
        console.debug('submittableActivityDataToken::Activity', JSON.stringify([this.activity]));
        return JSON.stringify([this.activity]);
    }

    /** Liefert die Validierungsregel für die Angabe des Grundes
     * @devdoc Die explizite Ausprogrammierung dieser "required"-Regel zusammen mit "v-show" statt einem einfachen "v-if" wird vorgenommen,
     * um einen seltsamen Validierungsfehler mit initialer Vertauschung der Fehlermeldungen zwischen der Monats- und der Jahresangabe zu umgehen
     */
    get validationIsInactivityReasonRequired() {
        if (this.isInactive) {
            return '|required';
        } else {
            return '';
        }
    }

    /** Liefert die Validierungsregel für den Monat
     * @remarks Abhängig von anderen Angaben muss das Datum in einem bestimmten Zeitbereich liegen
     * @remarks Implementiert die Anforderung C aus CR1010
     * @devdoc Prüft nur auf ganze Monate, gemäss Anforderung, und nur wenn das aktuelle Jahr eingegeben wurde.
     * Das Jahr selbst wird separat validiert.
     */
    get monthValidation() {
        const now = new Date();
        const thisYear = now.getFullYear();
        const thisMonth = now.getMonth() + 1; //convert to 1-based value
        //console.debug(`monthValidation:thisYear:='${thisYear}'thisMonth:='${thisMonth}'`);

        //Frage «Ist der Betrieb zur Zeit aktiv» = Ja, seit...
        if (this.isActive) {
            //Datum darf nicht in der Zukunft liegen
            if (this.activity.activityDateYear === thisYear) {
                return 'max_value_labeled:' + thisMonth + ',allg_validate_month_year_not_in_future';
            }
            return '';
        }

        //Frage «Ist der Betrieb zur Zeit aktiv» = Nein, und Tätigkeitsaufnahme geplant für...
        if (
            this.isInactive &&
            // eslint-disable-next-line eqeqeq
            this.activity.inactivityReason == 1 /*Tätigkeitsaufnahme geplant für (triple eq does not work)*/
        ) {
            //Datum muss in der Zukunft liegen
            if (this.activity.activityDateYear === thisYear) {
                //Es muss mindestens nächste Monat gewählt sein
                //(Bei Jahresende muss der Benutzer das nächste Jahr wählen)
                return 'min_value_labeled:' + (thisMonth + 1) + ',allg_validate_month_year_not_in_past';
            }
            return '';
        }

        //Frage «Ist der Betrieb zur Zeit aktiv» = Nein, und Aufgabe der Tätigkeit per...
        if (
            this.isInactive &&
            // eslint-disable-next-line eqeqeq
            this.activity.inactivityReason == 2 /*Aufgabe der Tätigkeit per (triple eq does not work)*/
        ) {
            //Datum darf nicht in der Zukunft liegen
            if (this.activity.activityDateYear === thisYear) {
                return 'max_value_labeled:' + thisMonth + ',allg_validate_month_year_not_in_future';
            }
            return '';
        }

        //Keine Einschränkung
        return '';
    }

    /** Liefert die Validierungsregel für das Jahr
     * @remarks Abhängig von anderen Angaben muss das Datum in einem bestimmten Zeitbereich liegen
     * @remarks Implementiert die Anforderung C aus CR1010
     * @devdoc Prüft nur auf ganze Jahre. Der Monat wird separat validiert.
     */
    get yearValidation() {
        const now = new Date();
        const thisYear = now.getFullYear();
        const thisMonth = now.getMonth() + 1; //convert to 1-based value
        //console.debug(`monthValidation:thisYear:='${thisYear}'thisMonth:='${thisMonth}'`);

        //Frage «Ist der Betrieb zur Zeit aktiv» = Ja, seit...
        if (this.isActive) {
            //Datum darf nicht in der Zukunft liegen
            return 'max_value_labeled:' + thisYear + ',allg_validate_month_year_not_in_future';
        }

        //Frage «Ist der Betrieb zur Zeit aktiv» = Nein, und Tätigkeitsaufnahme geplant für...
        if (
            this.isInactive &&
            // eslint-disable-next-line eqeqeq
            this.activity.inactivityReason == 1 /*Tätigkeitsaufnahme geplant für (triple eq does not work)*/
        ) {
            //Datum muss in der Zukunft liegen
            if (thisMonth === 12 /*letzter Monat*/) {
                return 'min_value_labeled:' + (thisYear + 1) + ',allg_validate_month_year_not_in_past';
            }
            return 'min_value_labeled:' + thisYear + ',allg_validate_month_year_not_in_past';
        }

        //Frage «Ist der Betrieb zur Zeit aktiv» = Nein, und Aufgabe der Tätigkeit per...
        if (
            this.isInactive &&
            // eslint-disable-next-line eqeqeq
            this.activity.inactivityReason == 2 /*Aufgabe der Tätigkeit per (triple eq does not work)*/
        ) {
            //Datum darf nicht in der Zukunft liegen
            return 'max_value_labeled:' + thisYear + ',allg_validate_month_year_not_in_future';
        }

        //Keine Einschränkung
        return '';
    }

    /** Gibt an, ob der Betrieb als nicht aktiv gemeldet ist.
     * @remarks Implementiert die Anforderung  DatenErfassungLocalTaetigkeitScreen 010
     * @devdoc Wird für die Steuerung von Einblendungen sowie für das Setzen von Default-Werten verwendet.
     */
    get isInactive() {
        const isInactive = this.activity.isActivityBegin === false;
        if (!isInactive) {
            this.activity.inactivityReason = null;
        }
        return isInactive;
    }

    /** Gibt an, ob der Betrieb als aktiv gemeldet ist.
     * @remarks Implementiert die Anforderung  DatenErfassungLocalTaetigkeitScreen 010
     * @devdoc Wird für die Steuerung von Einblendungen sowie für das Setzen von Default-Werten verwendet.
     */
    get isActive() {
        const isActive = this.activity.isActivityBegin === true;
        if (!isActive) {
            this.localUnit.activityDescription = null;
        }
        return isActive;
    }

    /** Gibt an, ob die Angabe eines Datums verlangt ist.
     * @remarks Implementiert die Anforderung  DatenErfassungLocalTaetigkeitScreen 010 und 020
     * @devdoc Wird für die Steuerung von Einblendungen sowie für das Setzen von Default-Werten verwendet.
     */
    get isDateRequired() {
        const isDateRequired =
            this.activity.isActivityBegin != null /*Status wurde schon ausgewählt*/ &&
            (this.activity.isActivityBegin === true ||
            this.activity.inactivityReason == 1 /*Tätigkeitsaufnahme geplant für (triple eq does not work)*/ ||
                this.activity.inactivityReason == 2); /*Aufgabe der Tätigkeit per (triple eq does not work)*/
        if (!isDateRequired) {
            this.activity.activityDateMonth = null;
            this.activity.activityDateYear = null;
        }
        return isDateRequired;
    }

    /** Gibt an, ob es sich um einen Händler handelt.
     * @remarks Implementiert die Anforderung  DatenErfassungLocalTaetigkeitScreen 050
     * @devdoc Wird für die Steuerung von Einblendungen sowie für das Setzen von Default-Werten verwendet.
     */
    get isMerchant() {
        const isMerchant = this.activity.isMerchant === true;

        if (!isMerchant) {
            this.activity.merchantKind = null;
        }
        return isMerchant;
    }

    /** Gibt an, ob es sich um eine saisonale Aktivität handelt.
     * @remarks Implementiert die Anforderung  DatenErfassungLocalTaetigkeitScreen 070
     * @devdoc Wird für die Steuerung von Einblendungen sowie für das Setzen von Default-Werten verwendet.
     */
    get isSeasonActivity() {
        const isSeasonActivity = this.activity.isSeasonActivity === true;

        if (!isSeasonActivity) {
            this.activity.seasonSelection = null;
        }
        return isSeasonActivity;
    }

    /** Gibt an, ob die Angabe eines Vorgängers verlangt ist.
     * @remarks Implementiert die Anforderung  DatenErfassungLocalTaetigkeitScreen 110
     * @devdoc Wird für die Steuerung von Einblendungen sowie für das Setzen von Default-Werten verwendet.
     */
    get isPredecessorRequired() {
        const isPredecessorRequired = this.activity.creationReasonSelection != 1;
        if (!isPredecessorRequired) {
            this.activity.nameActivityBefore = null;
        }
        return isPredecessorRequired;
    }

    /** Gibt an, ob die Angabe einer Creation Reason verlangt ist.
     * @remarks Implementiert die Anforderung  DatenErfassungLocalTaetigkeitScreen 120
     * @devdoc Wird für die Steuerung von Einblendungen sowie für das Setzen von Default-Werten verwendet.
     */
    get isCreationReasonDescriptionRequired() {
        const isCreationReasonDescriptionRequired = this.activity.creationReasonSelection == 7;
        if (!isCreationReasonDescriptionRequired) {
            this.activity.creationReasonDescription = null;
        }
        return isCreationReasonDescriptionRequired;
    }

    /** Liefert ein Token für die versendbaren Daten zur späteren Prüfung, ob ein erneuter Versand an den LocalUnitAddress-Endpunkt notwendig ist.
     * @remarks Aus historischen Gründen werden die NOGA-Relevanten Angaben der eigentlichen LocalUnit, nicht den Aktivitätsdaten zugeordnet.
     * @devdoc Für den effektiven Versand an die API wird das Objekt aus dem Zustand verwendet,
     *  nicht dieses interne DTO.
     */
    get submittableLocalUnitAddressDataToken() {
        const tokenData = {
            activityDescription: this.localUnit.activityDescription,
            mainActivityNogaCode: this.localUnit.mainActivityNogaCode
        };
        console.debug('submittableLocalUnitAddressDataToken::tokenData', tokenData);
        return JSON.stringify(tokenData);
    }
}
