// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/loading.gif");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\r\n/*****************************************************************\r\n    Loadingoverlay\r\n*****************************************************************/\n.waitOverlay {\r\n    display: none;\r\n    position: fixed;\r\n    z-index: 1000;\r\n    top: 0;\r\n    left: 0;\r\n    height: 100%;\r\n    width: 100%;\r\n    background: rgba(255, 255, 255, 0.8) url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 50% 50% no-repeat;\n}\n.loading.waitOverlay {\r\n    display: block;\n}\n.loading.waitOverlay * {\r\n    top: 50%;\r\n    left: 50%;\n}\n.loading.waitOverlay .loader-text {\r\n    position: absolute;\r\n    width: 400px;\r\n    text-align: center;\r\n    top: calc(50% + 30px);\r\n    left: calc(50% - 200px);\n}\r\n", ""]);
// Exports
module.exports = exports;
