import { MutationTree } from 'vuex';
import { IResourceState, I18N_LANGUAGE } from './state';

import axios from 'axios';
import i18n from '@/plugins/i18n';
import { localize } from 'vee-validate';

export const LANGUAGE = 'language';
export const NEW_LOADED_LANGUAGE = 'newLoadedLanguage';

export const mutations: MutationTree<IResourceState> = {
    [LANGUAGE]: (state, value: string) => {
        localStorage.setItem(I18N_LANGUAGE, value);
        state.language = value;

        i18n.locale = value;
        axios.defaults.headers.common['Accept-Language'] = value;
        document?.querySelector('html')?.setAttribute('lang', value);
        // Vee-Validate
        localize(value);

        return value;
    },
    [NEW_LOADED_LANGUAGE]: (state, { language, value }) => {
        state.loadedLanguages.push(language);
        i18n.setLocaleMessage(language, value);
    }
};
