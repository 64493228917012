import { IRootState } from '@/store';
import { GetterTree } from 'vuex';
import { IFooterBarState } from './state';

/** Gibt an, ob die Footerbar sichtbar ist */
export const IS_BAR_VISIBLE = 'isBarVisible';
export const GET_TEXT = 'getText';
export const GET_CLOSEDELAY = 'getCloseDelay';
export const GET_CLOSEAFTERDELAY = 'getCloseAfterDelay';
export const GET_DISMISSIBLE = 'getDismissible';
export const GET_BUTTONLEFT = 'getButtonLeft';
export const GET_BUTTONCENTER = 'getButtonCenter';
export const GET_BUTTONRIGHT = 'getButtonRight';

export const getters: GetterTree<IFooterBarState, IRootState> = {
    [IS_BAR_VISIBLE]: state => state.visible,
    [GET_TEXT]: state => state.text,
    [GET_CLOSEAFTERDELAY]: state => state.closeAfterDelay,
    [GET_CLOSEDELAY]: state => state.closeDelay,
    [GET_DISMISSIBLE]: state => state.isDismissible,
    [GET_BUTTONLEFT]: state => state.buttonLeft,
    [GET_BUTTONCENTER]: state => state.buttonCenter,
    [GET_BUTTONRIGHT]: state => state.buttonRight
};
