var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h1", [_vm._v(_vm._s(_vm.$t("page_lgn_title")))]),
      _c("div", { domProps: { innerHTML: _vm._s(_vm.$t("page_lgn_info")) } }),
      _c("ValidationObserver", {
        ref: "observer",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var invalid = ref.invalid
              var handleSubmit = ref.handleSubmit
              return [
                _c(
                  "form",
                  {
                    staticClass: "form-horizontal",
                    attrs: { role: "form" },
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return handleSubmit(_vm.loginWrapper)
                      }
                    }
                  },
                  [
                    _c(
                      "fieldset",
                      [
                        _c("NowhowTextbox", {
                          directives: [{ name: "focus", rawName: "v-focus" }],
                          attrs: {
                            id: "username-textbox",
                            label: "allg_user_login_id",
                            validate: "required",
                            "validation-delay": 200
                          },
                          model: {
                            value: _vm.credentials.username,
                            callback: function($$v) {
                              _vm.$set(_vm.credentials, "username", $$v)
                            },
                            expression: "credentials.username"
                          }
                        }),
                        _c("NowhowTextbox", {
                          attrs: {
                            id: "password-textbox",
                            label: "allg_password",
                            "input-type": "Password",
                            validate: "required",
                            "validation-delay": 200
                          },
                          model: {
                            value: _vm.credentials.password,
                            callback: function($$v) {
                              _vm.$set(_vm.credentials, "password", $$v)
                            },
                            expression: "credentials.password"
                          }
                        }),
                        _c("div", { staticClass: "col-sm-offset-3 col-sm-9" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              attrs: {
                                type: "submit",
                                name: "LoginButton",
                                id: "LoginButton",
                                role: "button",
                                disabled: invalid
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("btn_login")) + " ")]
                          )
                        ])
                      ],
                      1
                    )
                  ]
                )
              ]
            }
          }
        ])
      }),
      _c("div", { staticClass: "col-sm-offset-3 col-sm-9" }, [
        _c("ul", { staticClass: "list-unstyled" }, [
          _c(
            "li",
            [
              _c("router-link", {
                attrs: { to: { name: _vm.ACCOUNT_RECOVERY } },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var href = ref.href
                      var navigate = ref.navigate
                      return [
                        _c(
                          "a",
                          { attrs: { href: href }, on: { click: navigate } },
                          [_vm._v(_vm._s(_vm.$t("page_lgn_password_recovery")))]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "li",
            [
              _c("router-link", {
                attrs: { to: { name: _vm.ACCOUNT_REGISTER } },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var href = ref.href
                      var navigate = ref.navigate
                      return [
                        _c(
                          "a",
                          { attrs: { href: href }, on: { click: navigate } },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("page_lgn_password_registry_hint"))
                            )
                          ]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }