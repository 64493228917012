import { IAccountService } from './IAccountService';
import BaseService from '@/services/baseService';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { IAccountViewModel, IContactInfoViewModel, RegistrationDataViewModel, CredentialsDto } from '@/api-viewmodel';

/**
 * Implementiert den Service für die Account-Aktionen.
 * @class
 * @implements {IAccountService}
 */
export class AccountService extends BaseService implements IAccountService {
    private ACCOUNT_URL: string;
    constructor() {
        super();
        this.ACCOUNT_URL = `${this.BASE_URL}/account`;
    }

    /**
     * Authentisiert den Benutzer mit den angegebenen Credentials
     * @param {string} credentials.username Der zu verwendenden Benutzername.
     * @param {string} credentials.password Das zu verwendende Password.
     * @returns {AxiosResponse<IAccountViewModel>} Die Antwort der API mit den Angaben zum Account
     */
    async authenticate(credentials: CredentialsDto): Promise<AxiosResponse<IAccountViewModel>> {
        return super
            .POST<CredentialsDto, AxiosResponse<IAccountViewModel>>(`${this.ACCOUNT_URL}/login`, credentials)
            .then((response: AxiosResponse<IAccountViewModel>) => {
                return response;
            });
    }

    /**
     * Entfernt die bisherige Authentisierung für einen Benutzer
     * @returns {AxiosResponse<IAccountViewModel>} Die Antwort der API mit den Angaben zum Account (typischerweise leerer Benutzername und leere Rollen)
     * @devdoc Wenn NPM als Server fungiert (via Port 8080), funktioniert der Login/Logout Prozess nicht richtig.  Das liegt vermutlich an CORS, da die API auf einem anderen Port (Port 55000) aufgerufen wird.
     * Lösung für die Entwicklung: Die Anwendung auch via Port 55000 (via Visual Studio Server) aufrufen.
     * @devdoc Logout wird via POST gelöst, weil der Endpunkt nicht unbeabsichtigt via Browser aufgerufen sollte. Siehe https://stackoverflow.com/questions/3521290/logout-get-or-post
     */
    async logout(): Promise<AxiosResponse<IAccountViewModel>> {
        return super
            .POST<null, AxiosResponse<IAccountViewModel>>(`${this.ACCOUNT_URL}/logout`, null)
            .then((response: AxiosResponse<IAccountViewModel>) => {
                return response;
            });
    }

    /**
     * Liefert die Kontakt-Informationen
     */
    async getContactInfo(): Promise<AxiosResponse<IContactInfoViewModel>> {
        console.debug('AccountService::getContactInfo', this.ACCOUNT_URL);
        return super
            .GET<AxiosResponse<IContactInfoViewModel>>(`${this.ACCOUNT_URL}/contact`, null)
            .then((response: AxiosResponse<IContactInfoViewModel>) => {
                return response;
            });
    }

    /**
     * Prüft ob ein Benutzer angemeldet ist und liefert Angaben zum Account
     * @returns {AxiosResponse<IAccountViewModel>} Die Antwort der API mit den Angaben zum Account (typischerweise leerer Benutzername und leere Rollen)
     */
    async getAccountInfo(): Promise<AxiosResponse<IAccountViewModel>> {
        return super
            .GET<AxiosResponse<IAccountViewModel>>(`${this.ACCOUNT_URL}/accountinfo`, null)
            .then((response: AxiosResponse<IAccountViewModel>) => {
                return response;
            });
    }

    /**
     * Prüft ob ein Benutzer angemeldet ist und liefert Angaben zum Account
     * @returns {AxiosResponse<boolean>} Die Antwort der API mit true wenn angemeldet, ansonsten false
     */
    isLoggedIn(): Promise<AxiosResponse<boolean>> {
        return super
            .GET<AxiosResponse<boolean>>(`${this.ACCOUNT_URL}/isLoggedIn`, null)
            .then((response: AxiosResponse<boolean>) => {
                return response;
            });
    }

    /**
     * Fordert eine (erneute) Registrierung an
     * @remarks Es wird keine Antwort erwartet, da der Client aus Sicherheitsgründen nicht erfahren soll, ob die Anfrage erfolgreich war
     * @param {string} username Der zu verwendenden Benutzername.
     */
    async requestRegistration(username: string): Promise<any> {
        return super.POST(`${this.ACCOUNT_URL}/requestRegistration`, { username: username }).then((response: any) => {
            return response;
        });
    }

    /**
     * Registriert einen externen Benutzer für eine Befragung.
     * @devdoc Der Aufruf an das Backend wird hier nicht über die Basisklasse gelöst, um allfällige Fehler (HTTP-Status !== 200) selber behandeln zu können
     * @param {string} registrationData Der zu verwendenden Daten für die Registration.
     * @returns {string} Die Informationen zur Registrierung
     */
    async register(registrationData: RegistrationDataViewModel) {
        return super
            .POST<RegistrationDataViewModel, AxiosResponse<string>>(
                `${this.ACCOUNT_URL}/register`,
                registrationData,
                this.getNonValidatedPostConfig()
            )
            .then((response: AxiosResponse<string>) => {
                return response;
            });
    }

    private getNonValidatedPostConfig(): AxiosRequestConfig {
        const config = super.getPostConfig();

        console.debug(config);

        /** Keine Validierung für diesen Request, kann später selbst gehandelt werden. */
        config.validateStatus = null;
        return config;
    }

    /**
     * Liefert den Namen der zum AccessCode gehörenden Survey
     * @param {string} accessCode Der zu verwendenden AccessCode.
     * @returns {string} Die Informationen zur Registrierung
     *    */
    getAssignedSurveyName(accessCode: string): Promise<AxiosResponse<string>> {
        return super
            .GETbyCode<AxiosResponse<string>>(`${this.ACCOUNT_URL}/getAssignedSurveyName`, accessCode)
            .then((response: AxiosResponse<string>) => {
                return response;
            });
    }
}

export const accountService = new AccountService();
