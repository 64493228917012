























































//Imports für dieses Modul
import Vue from 'vue';
import { namespace } from 'vuex-class';
import { NAME } from '../store/index';
import { NAME as NAME_VALIDATION } from '@/store/modules/validation';
import { Component, Prop, Watch } from 'vue-property-decorator';
import AccessCodeTextbox from '../components/AccessCodeTextbox.vue';
import { GET_VALIDATION_KEY } from '@/store/modules/validation/getters';
import { ACCOUNT_CONTACT } from '@/router';

const modul = namespace(NAME);

const validationModul = namespace(NAME_VALIDATION);

/** Das für den Zugriffscode verwendete Alphabet, für die Prüf-Funktion */
const alphabet = 'ABCDEFGHJKLMNPQRSTUVWXYZ23456789';

//Getters und Actions
import { GET_ASSIGNED_SURVEY_NAME } from '../store/actions';

@Component({
    components: {
        AccessCodeTextbox
    }
})

/** Implementiert den Schritt 1 der Registrierung: Eingabe des Zugriffscodes
 * @devdoc Enthält diversen Javascript Code im Zusammenhang mit der Eingabe des Access-Codes
 */
export default class Step1AccessCode extends Vue {
    private ACCOUNT_CONTACT = ACCOUNT_CONTACT;

    /** Der anzuzeigende AccessCode (12 Zeichen ohne Trennzeichen) */
    @Prop({ required: false, default: '' }) private accessCode!: string;

    @modul.Action(GET_ASSIGNED_SURVEY_NAME) getAssignedSurveyName!: Function;

    @validationModul.Getter(GET_VALIDATION_KEY) getValidation!: (key: string) => string;

    private onAccessCodeInput(value) {
        const tidyAccessCode = this.toTidyAccessCode(value);
        this.$emit('onAccessCodeInput', tidyAccessCode);
    }

    /** Stellt für die Eingabe sicher, dass nur Zeichen des Alphabets eingegeben werden
     * @devdoc Implementiert ein Computed Property im Class Component Stil
     */
    toTidyAccessCode(accessCode: string): string {
        let tidyAccessCode = accessCode;
        //Nur Grossbuchstaben
        tidyAccessCode = tidyAccessCode.toUpperCase();
        //Zeichen ausserhalb des Alphabets entfernen
        tidyAccessCode = tidyAccessCode.replace(/[^ABCDEFGHJKLMNPQRSTUVWXYZ23456789]/gi, '');
        return tidyAccessCode;
    }

    created() {
        const accessCode = this.getAccessCodeFromQuery();
        this.onAccessCodeInput(accessCode);
    }

    /** Liefert den AccessCode aus der URL Query (normal und in der Vue-Route) oder einen leeren String als Fallback
     * @remarks Die geprüften Parameter sind "c", "AccessCode" und verschiedene Casings davon als Fallback.
     * @example Beispiel: {@link http://localhost:8080?accesscode=TESTACCESSCD} oder {@link http://localhost:8080/#/register?c=a234-a235-a236}
     */
    private getAccessCodeFromQuery(): string {
        let queryAccessCode = null;

        //Versuch mit der URL Query aus dem Vue-Routing
        if (queryAccessCode === null || queryAccessCode === undefined) {
            queryAccessCode = this.$route.query.c;
        }
        if (queryAccessCode === null || queryAccessCode === undefined) {
            queryAccessCode = this.$route.query.C;
        }
        if (queryAccessCode === null || queryAccessCode === undefined) {
            queryAccessCode = this.$route.query.AccessCode;
        }
        if (queryAccessCode === null || queryAccessCode === undefined) {
            queryAccessCode = this.$route.query.accesscode;
        }
        if (queryAccessCode === null || queryAccessCode === undefined) {
            queryAccessCode = this.$route.query.accessCode;
        }

        //Versuch mit der normalen URL Query
        const urlParams = new URLSearchParams(window.location.search);

        if (queryAccessCode === null || queryAccessCode === undefined) {
            queryAccessCode = urlParams.get('c');
        }
        if (queryAccessCode === null || queryAccessCode === undefined) {
            queryAccessCode = urlParams.get('C');
        }
        if (queryAccessCode === null || queryAccessCode === undefined) {
            queryAccessCode = urlParams.get('AccessCode');
        }
        if (queryAccessCode === null || queryAccessCode === undefined) {
            queryAccessCode = urlParams.get('accesscode');
        }
        if (queryAccessCode === null || queryAccessCode === undefined) {
            queryAccessCode = urlParams.get('accessCode');
        }

        if (queryAccessCode === null || queryAccessCode === undefined) {
            queryAccessCode = ''; //Leerer AccessCode, da kein Parameter erfolgreich war
        }

        return queryAccessCode;
    }

    /** Gibt an, ob mindestens ein Zeichen des Zugriffscodes eingegeben wurde */
    get isTyping() {
        if (this.accessCode.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    /** Gibt an, ob der Zugriffscode vollständig ist (12 Digits) */
    get isComplete() {
        if (this.accessCode.length === 12) {
            return true;
        } else {
            return false;
        }
    }

    /** Gibt an, ob der Zugriffscode korrekt ist (Vollständig und Prüfstelle korrekt) */
    get isCorrect() {
        if (this.ValidateAccessCode(this.accessCode, alphabet)) {
            return true;
        } else {
            return false;
        }
    }

    /** Validiert den Zugangscode.
     *  @description Es wird geprüft, ob der Zugangscode 12 Zeichen lang ist, und die letzten 2 Zeichen ein gültiges Prüfmuster bilden.
     *  Liefert true, wenn der Zugangscode für das angegebene Alphabet gültig ist.
     * @remarks  Diese Methode muss die gleichen Prüfmuster liefern wie die C# Variante in AccessCodeUtil.cs
     * @remarks Implementiert die Anforderungen RegistrationScreen-02, RegistrationScreen-03, RegistrationScreen-04 in {@link https://collaboration.nowhow.ch/erst/foswiki/bin/view/Erst/RegistrierungScreen}
     */
    ValidateAccessCode(accessCode, alphabet): boolean {
        //check properties
        if (accessCode.length === 12) {
            const payload = accessCode.substr(0, 10);
            const check = accessCode.slice(-2);

            if (this.HasOnlyAllowedChars(payload, alphabet)) {
                //calulate the expected check
                const expectedCheck = this.GetCheck(payload, alphabet);

                if (check === expectedCheck) {
                    //code is valid
                    return true;
                }
            }
        }

        return false;
    }

    /**  Liefert ein 2 Zeichen langes Prüfmuster für die angegebene Payload, unter Verwendung des angegebenen Alphabets.
     *  @description Das Prüfmuster basiert auf den Positionen und den Symbolen der Payload und enthält nur Symbole des angegebenen Alphabets.
     * @remarks  Diese Methode muss die gleichen Prüfmuster liefern wie die C# Variante in AccessCodeUtil.cs
     * @remarks Implementiert die Anforderungen RegistrationScreen-02, RegistrationScreen-03, RegistrationScreen-04 in {@link https://collaboration.nowhow.ch/erst/foswiki/bin/view/Erst/RegistrierungScreen}
     */
    GetCheck(payload, alphabet): string {
        //Die Anzahl verfügbarer Symbole feststellen
        const symbolCount = alphabet.length;
        //Der nutzbare Zahlenraum, welcher dem Prüfmuster entspricht, ergibt sich aus der Länge des Prüfmusters und der Anzahl verfügbarer Symbole
        const checkSpace = symbolCount * symbolCount;
        //Nutzwert, dh. die Zahl im Zahlenraum, die das Prüfmuster darstellt
        let checkNumber = 0;
        //Rollendes aufrechnen der einzelnen Symbole (bzw. deren Index im Alphabet) in der Payload, abhängig von der Position
        for (let index = 0; index < payload.length; index++) {
            const character = payload[index];
            //Index im Alphabet als Nutzwert verwenden.
            const characterIndex = alphabet.indexOf(character);
            //Die Position ist 1-basiert, damit auch das erste Zeichen einen Einfluss auf das Prüfmuster hat
            const position = index + 1;
            //Die Position wird quadratisch gewichtet, um den nutzbaren Zahlenraum besser auszunutzen (bessere Verteilung der Symbole des Prüfmusters).
            const positionWeight = position * position;
            //Positionsbasiertes Aufrechnen des Nutzwertes für das aktuelle Symbol
            //Durch die Modulo-Funktion wird der nutzbare Zahlenraum nicht verlassen.
            checkNumber = (checkNumber + characterIndex * positionWeight) % checkSpace;
        }

        //Index und somit Symbole des Prüfmusters berechnen, anhand der oberen und unteren Bits des Nutzwertes.
        const checkCharacterIndex1 = ~~(checkNumber / symbolCount);
        const checkCharacterIndex2 = checkNumber % symbolCount;

        const checkChar1 = alphabet[checkCharacterIndex1];
        const checkChar2 = alphabet[checkCharacterIndex2];

        return checkChar1 + checkChar2;
    }

    /**  Ermöglicht die Validierung des (partiellen) Zugangcodes anhand der verwendeten Zeichen. Liefert true, wenn alle Zeichen im Alphabet enthalten sind, sonst false.
     * @remarks Implementiert die Anforderungen RegistrationScreen-02, RegistrationScreen-03, RegistrationScreen-04 in {@link https://collaboration.nowhow.ch/erst/foswiki/bin/view/Erst/RegistrierungScreen}
     */
    HasOnlyAllowedChars(payload, alphabet): boolean {
        for (let index = 0; index < payload.length; index++) {
            const character = payload[index];
            const characterIndex = alphabet.indexOf(character);

            if (characterIndex === -1) {
                return false;
            }
        }
        return true;
    }
}
