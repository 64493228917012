






























//Imports für dieses Modul
import Vue from 'vue';
import { namespace } from 'vuex-class';
import { NAME as NAME_SWISSTOPO } from '@/areas/swisstopo/store';
import { NAME as NAME_HEADERNOTIFICATION } from '@/store/modules/headerNotification';
import { Component, Watch, Prop } from 'vue-property-decorator';
import { NEW_SUCESS, NEW_ALERT } from '@/store/modules/headerNotification/actions';
import { vSelect } from '@desislavsd/vue-select';

const swisstopoArea = namespace(NAME_SWISSTOPO);
const headerModul = namespace(NAME_HEADERNOTIFICATION);

//Getters und Actions
import { FEATURE_SEARCH_RESULT, SELECTED_FEATURE } from '../store/getters';
import { GET_FEATURE_DETAILS, SEARCH_FEATURES } from '../store/actions';
import { IFeature, IFeatureSearchResult } from '../api-viewmodel';

@Component({
    components: {
        vSelect
    }
})

/** Eine Komponente für die Suche und Auswahl von Features via die Swisstopo-API.
 *  Enthält eine Textbox mit Dropdown für die Auswahl.
 *  Als Resultat wird mit dem Event @featureSelected ein ausgewähltes Feature gemeldet.
 * @remarks Siehe die {@link https://collaboration.nowhow.ch/erst/foswiki/bin/view/Erst/SwisstopoSchnittstelle}
 * @remarks Implementiert Anforderungen gemäss {@link https://collaboration.nowhow.ch/erst/foswiki/bin/view/Erst/DatenErfassungUnternehmenScreen} und weiteren
 * @devdoc  Diese Komponente verwendet aktuell als einzige v-select (https://desislavsd.gitbook.io/vue-select/)
 * und verwendet deshalb die lokale Installation innerhalb nur dieser Komponente. Falls später sinnvoll, kann auch die globale Installation implementiert werden,
 * dann kann das untenstehende Original-CSS aus dieser Komponente entfernt werden. */
export default class SwisstopoFeatureSearch extends Vue {
    @Prop({ required: true, type: String }) private id!: string;

    @Prop({ required: false, type: Boolean, default: false }) private disabled!: boolean;

    /** Die Id des ausgewählten Features */
    selectedFeatureId: string | null = '';

    @headerModul.Action(NEW_SUCESS) successMessage!: Function;
    @headerModul.Action(NEW_ALERT) alertMessage!: Function;

    /** @devdoc Die Action SEARCH_FEATURES wird für die Verwendung mit der v-select Komponente mit einer Promise verwendet */
    @swisstopoArea.Action(SEARCH_FEATURES) searchFeatures!: Function;
    @swisstopoArea.Action(GET_FEATURE_DETAILS) getFeature!: Function;

    @swisstopoArea.Getter(FEATURE_SEARCH_RESULT) featureSearchResult!: IFeatureSearchResult;
    @swisstopoArea.Getter(SELECTED_FEATURE) selectedFeature!: IFeature;

    /** Sucht die Features mithilfe der Vuex Action, unter Zuhilfenahme einer Promise */
    async searchFeaturesWithPromise(searchText) {
        console.debug('searchText', searchText);
        return this.searchFeatures(searchText).then(
            response => {
                console.debug('Swisstopo: Features zur Anzeige erhalten');
                return this.featureSearchResult;
            },
            error => {
                //Den Fehler weitergeben (In der Anwendung soll im Fehlerfall die Manuelle Eingabe aktiviert werden)
                this.$emit('error', error);
            }
        );
    }

    /** Behandelt die Änderung der ausgewählten Feature-Id, indem die Details des Features geholt werden
     * @remarks Implementiert die Anforderungen gemäss DatenErfassungLocalAdresseScreen
     */
    @Watch('selectedFeatureId', { deep: false, immediate: true })
    private onSelectedFeatureIdChanged(newVal: string | null, oldVal: string | null) {
        if (newVal && newVal !== oldVal) {
            this.getFeature(newVal);
        }
    }

    /** Behandelt die Änderung des ausgewählten Features, indem dieses gemeldet wird
     * @remarks Implementiert die Anforderungen gemäss DatenErfassungLocalAdresseScreen und DatenErfassungUnternehmenScreen
     * @devdoc Watch non-immediate, um nur Änderungen aufgrund von Benutzereingaben (während der mounted-phase)
     * zu erfassen (nicht bei Änderungen vor der Initialisierung)   */
    @Watch('selectedFeature', { deep: false, immediate: false })
    private onSelectedFeatureChanged(newVal: IFeature | null) {
        console.debug('SwisstopoFeatureSearch::onSelectedFeatureChanged', newVal);
        this.$emit('featureSelected', newVal);
    }
}
