var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("nav", { staticClass: "nav-process process-items-3" }, [
        _c("ul", [
          _c("li", { staticClass: "disabled" }, [
            _c("a", { attrs: { href: "#" } }, [
              _vm._v(_vm._s(_vm.$t("allg_accesscode")))
            ])
          ]),
          _c("li", { staticClass: "active" }, [
            _c("a", { attrs: { href: "#" } }, [
              _vm._v(_vm._s(_vm.$t("allg_password")))
            ])
          ]),
          _c("li", { staticClass: "disabled" }, [
            _c("a", { attrs: { href: "#" } }, [
              _vm._v(_vm._s(_vm.$t("allg_delivery")))
            ])
          ])
        ])
      ]),
      _c("h1", [_vm._v(_vm._s(_vm.$t("page_reg_title")))]),
      _c("ValidationObserver", {
        ref: "observer",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var invalid = ref.invalid
              var handleSubmit = ref.handleSubmit
              return [
                _c(
                  "form",
                  {
                    staticClass: "form-horizontal",
                    attrs: { role: "form" },
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return handleSubmit(_vm.registerWrapper)
                      }
                    }
                  },
                  [
                    _c(
                      "fieldset",
                      [
                        _c("NowhowTextline", {
                          attrs: {
                            id: "accesscode-textline",
                            label: _vm.$t("allg_accesscode"),
                            text: _vm.accessCode
                          }
                        }),
                        _c("NowhowTextline", {
                          attrs: {
                            id: "emailinfo-textline",
                            label: _vm.$t("survey_name"),
                            text: _vm.surveyName
                          }
                        }),
                        _c("NowhowTextline", {
                          attrs: {
                            id: "emailinfo-textline",
                            text: _vm.$t("page_rpw_emailinfo")
                          }
                        }),
                        _c("NowhowTextbox", {
                          directives: [{ name: "focus", rawName: "v-focus" }],
                          attrs: {
                            id: "registrationEmail",
                            label: "allg_email",
                            validate: _vm.getValidation(
                              "registrationDataViewModel.eMailAddress"
                            )
                          },
                          model: {
                            value: _vm.registrationData.eMailAddress,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.registrationData,
                                "eMailAddress",
                                $$v
                              )
                            },
                            expression: "registrationData.eMailAddress"
                          }
                        }),
                        _c("NowhowTextline", {
                          attrs: {
                            id: "emailinfo-textline",
                            text: _vm.$t("page_rpw_passwordinfo"),
                            "is-html-text": true
                          }
                        }),
                        _c("NowhowTextbox", {
                          attrs: {
                            id: "registrationPassword",
                            label: "allg_password",
                            "input-type": "Password",
                            validate: _vm.getValidation(
                              "registrationDataViewModel.password"
                            )
                          },
                          model: {
                            value: _vm.registrationData.password,
                            callback: function($$v) {
                              _vm.$set(_vm.registrationData, "password", $$v)
                            },
                            expression: "registrationData.password"
                          }
                        }),
                        _c("NowhowTextbox", {
                          attrs: {
                            id: "registrationPasswordConfirmation",
                            label: "allg_password_repetition",
                            "input-type": "Password",
                            validate:
                              _vm.getValidation(
                                "registrationDataViewModel.passwordConfirmation"
                              ) + "|confirmed:registrationPassword"
                          },
                          model: {
                            value: _vm.registrationData.passwordConfirmation,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.registrationData,
                                "passwordConfirmation",
                                $$v
                              )
                            },
                            expression: "registrationData.passwordConfirmation"
                          }
                        }),
                        _c("div", { staticClass: "col-sm-offset-3 col-sm-9" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              attrs: {
                                type: "submit",
                                role: "button",
                                disabled: invalid
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("allg_confirmandregister")) +
                                  " "
                              )
                            ]
                          )
                        ])
                      ],
                      1
                    )
                  ]
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }