var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-select", {
        key: _vm.id,
        staticClass: "form-control",
        attrs: {
          id: _vm.id,
          disabled: _vm.disabled,
          name: _vm.id,
          as: "attrs.label:attrs.featureId:attrs.featureId",
          from: _vm.searchFeaturesWithPromise,
          parse: "results",
          required: "",
          debounce: 500,
          multiple: false,
          placeholder: _vm.$t("btn_search")
        },
        scopedSlots: _vm._u([
          {
            key: "selected",
            fn: function(ref) {
              var option = ref.option
              return [
                _c("img", {
                  staticStyle: { visibility: "hidden" },
                  attrs: { alt: "spacer", width: "15", src: "" }
                }),
                _vm._v(" " + _vm._s(option.label) + " ")
              ]
            }
          }
        ]),
        model: {
          value: _vm.selectedFeatureId,
          callback: function($$v) {
            _vm.selectedFeatureId = $$v
          },
          expression: "selectedFeatureId"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }