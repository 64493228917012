






































































































































































































import Vue from 'vue';
import { defaultTo } from 'lodash';
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { GET_VALIDATION_KEY } from '@/store/modules/validation/getters';

import NowhowTextbox from '@/components/NowhowTextbox.vue';
import NowhowDismissableTextbox from '@/components/NowhowDismissableTextbox.vue';
import NowhowTextline from '@/components/NowhowTextline.vue';
import NowhowRequiredInfoline from '@/components/NowhowRequiredInfoline.vue';
import NowhowTextarea from '@/components/NowhowTextarea.vue';
import NowhowRadioList from '@/components/NowhowRadioList.vue';
import NowhowRadioYesNo from '@/components/NowhowRadioYesNo.vue';
import NowhowUInt32Box from '@/components/NowhowUInt32Box.vue';
import SwisstopoFeatureSearch from '@/areas/swisstopo/components/SwisstopoFeatureSearch.vue';

import { NAME } from '../store/index';
const modul = namespace(NAME);

import { NAME as NAME_VALIDATION } from '@/store/modules/validation';
const validationModul = namespace(NAME_VALIDATION);

import { SUBMIT_LOCAL_UNIT_ADDRESS_DATA } from '../store/actions';
import { CASE_ID } from '../store/getters';

import { ILocalUnitDataObject, IEnterpriseUnitDataObject } from '@/api-viewmodel';
import { IFeature } from '@/areas/swisstopo/api-viewmodel';

@Component({
    components: {
        NowhowTextline,
        NowhowRequiredInfoline,
        NowhowTextbox,
        NowhowDismissableTextbox,
        NowhowTextarea,
        NowhowRadioList,
        NowhowRadioYesNo,
        NowhowUInt32Box,
        SwisstopoFeatureSearch
    }
})

/** Bereich für die Bearbeitung der Adress-Details einer Arbeitsstätte*/
export default class LocalUnitEditAddress extends Vue {
    //Getters
    @modul.Getter(CASE_ID) caseId!: bigint;

    @validationModul.Getter(GET_VALIDATION_KEY) getValidation!: (key: string) => string;

    //Actions
    @modul.Action(SUBMIT_LOCAL_UNIT_ADDRESS_DATA) submitLocalUnitAddressData!: Function;

    @Prop({ required: true, type: Object })
    private localUnit!: ILocalUnitDataObject;

    @Prop({ required: true, type: Object })
    private enterpriseUnit!: IEnterpriseUnitDataObject;

    mounted(): void {
        //Aktuelles Token der unveränderte Daten für die spätere, erstmalige Prüfung merken
        this.lastSubmittedDataToken = this.submittableDataToken;
    }

    /** Behandelt die Auswahl eines Features aus der Swisstopo-Komponente, indem die Adressdaten in die
     * entsprechenden Eingabefelder befüllt werden
     */
    private onLocalSwisstopoFeatureSelected(feature: IFeature): void {
        console.debug('LocalUnitEditAddress::onLocalSwisstopoFeatureSelected', feature);
        this.localUnit.street = feature.attributes.strname_deinr.replace(feature.attributes.deinr, '').trim();
        this.localUnit.houseNumber = feature.attributes.deinr;
        this.localUnit.zipCode = feature.attributes.dplz4;
        this.localUnit.town = feature.attributes.dplzname;
        this.localUnit.municipality = feature.attributes.ggdename;
        this.localUnit.canton = feature.attributes.gdekt;
        this.localUnit.egId = defaultTo(Number.parseInt(feature.attributes.egid), null);
        this.localUnit.edId = defaultTo(Number.parseInt(feature.attributes.edid), null);
        this.localUnit.esId = feature.attributes.esid;
        this.localUnit.gdeId = feature.attributes.ggdenr;
        this.localUnit.country = 'CH'; //Default-Wert für eine Swisstopo-Adresse
    }

    /** Behandelt den Fall, dass die Swisstopo-Schnittstelle nicht verfügbar ist
     */
    private onLocalSwisstopoUnavailable() {
        this.isLocalSwisstopoUnavailable = true;
        //Manuelle Eingabe einschalten
        this.localUnit.isSwisstopoDismissed = true;
    }

    /** Behandelt das Auswählen des "Dismissed"-Zustandes für die Swisstopo-Adresssuche
     * @remarks Steuert die manuelle Eingabemöglichkeit für die Adressfelder
     * @devdoc Die Controls müssen mit :readonly (nicht :disabled) gesteuert werden, damit die Eingabewerte
     * trotzdem weiterverarbeitet werden
     */
    private onLocalSwisstopoDismissedChange() {
        this.localUnit.isSwisstopoDismissed = !this.localUnit.isSwisstopoDismissed;
    }

    /** Behandelt das Auswählen des "Dismissed"-Zustandes für die Email-Adresse
     */
    private onEmailAddressDismissed(dismissed: boolean) {
        this.localUnit.isEmailAddressDismissed = dismissed;
    }

    /** Behandelt das Auswählen des "Dismissed"-Zustandes für die Email-Adresse
     */
    private onWebsiteAddressDismissed(dismissed: boolean) {
        this.localUnit.isWebsiteAddressDismissed = dismissed;
    }

    /** Behandelt das Auswählen des "Dismissed"-Zustandes für die Email-Adresse
     */
    private onPhoneNumberDismissed(dismissed: boolean) {
        this.localUnit.isPhoneNumberDismissed = dismissed;
    }

    beforeDestroy() {
        this.saveStepData();
    }

    /** Speichert die aktuell eingegebenen Werte auf das Backend
     * @remarks Die Daten werden nur versendet, wenn diese seit dem letzten Versand oder erstmalig geändert haben
     * Für diese Prüfung werden alle Eingabewerte dieser Komponente verwendet
     * @devdoc Diese Funktion soll beim Verlassen des Bearbeitungsformulars aufgerufen werden
     * Hinweis: Es kann aktuell nicht die Funktionalität der ValidationObserver-Komponente verwendet werden,
     * weil diese keinen zwischenzeitlichen Reset der "Dirty-Flags" erlaubt.
     * Hinweis2: Es wird nicht ein "dirty-tracking" via "changed"-Event implementiert, um nur zum Zeitpunkt des
     * Versandes effektiv veränderte Daten zu versenden.
     */
    saveStepData() {
        console.debug('::saveStepData');

        const submittableDataToken = this.submittableDataToken;
        if (submittableDataToken !== this.lastSubmittedDataToken) {
            this.submitLocalUnitAddressData({
                caseId: this.caseId,
                data: this.localUnit
            });
            this.lastSubmittedDataToken = submittableDataToken;
        }
    }

    /** Liefert die Validierungsregel für die gemeinesame Eingabe der Postoffice-Box-Angaben
     * @remarks Es müssen entweder alle oder keine PO-Box-Angaben gemacht werden
     * @remarks Implementiert die Anforderung D aus CR1010
     */
    get getPoBoxTogetherRule() {
        if (
            this.localUnit.postOfficeBoxNumber ||
            this.localUnit.postOfficeBoxZipCode ||
            this.localUnit.postOfficeBoxTown
        ) {
            return 'required_labeled:allg_validate_pobox_not_all_set_together';
        }
        //Keine Einschränkung
        return '';
    }

    /** Zwischenspeicher für die Prüfung, ob ein erneuter Versand der Daten notwendig ist */
    lastSubmittedDataToken: string = null;

    /** Lokales Flag, welches einen allfälligen Fehlerzustand der Swisstopo-Schnittstelle anzeigt
     * @remarks Implementiert die Anforderung zum "Fallback" für die Adresseingabe
     */
    isLocalSwisstopoUnavailable = false;

    /** Behandelt die manuelle Änderung der Adressdaten
     * @remarks Werden die Adressdaten manuell geändert, sind die Swisstopo-spezifischen Id's nicht mehr gültig.
     * Implementiert die Anforderung gemäss DatenErfassungLocalAdresseScreen-075
     * Siehe auch MapServerSwisstopoFunktion
     */
    private onManualLocalAddressInput() {
        this.localUnit.municipality = null;
        this.localUnit.canton = null;
        this.localUnit.egId = null;
        this.localUnit.edId = null;
        this.localUnit.esId = null;
        this.localUnit.gdeId = null;
        this.localUnit.country = 'CH'; //Default-Wert für eine manuell eingegebene Adresse, es werden nur Schweizer Adressen unterstützt;
        this.localUnit.estrId = null;
    }

    /** Liefert ein Token für die versendbaren Daten zur späteren Prüfung, ob ein erneuter Versand notwendig ist.
     * @devdoc Für den effektiven Versand an die API wird das Objekt aus dem Zustand verwendet,
     *  nicht dieses interne DTO.
     */
    get submittableDataToken() {
        return JSON.stringify([
            this.localUnit.name,
            this.localUnit.nameBusiness,
            this.localUnit.careOf,
            this.localUnit.street,
            this.localUnit.houseNumber,
            this.localUnit.additionalTxt,
            this.localUnit.zipCode,
            this.localUnit.town,
            this.localUnit.isSwisstopoDismissed,
            this.localUnit.postOfficeBoxNumber,
            this.localUnit.postOfficeBoxZipCode,
            this.localUnit.postOfficeBoxTown,
            this.localUnit.commentText,

            this.localUnit.municipality,
            this.localUnit.canton,
            this.localUnit.egId,
            this.localUnit.edId,
            this.localUnit.esId,
            this.localUnit.estrId,
            this.localUnit.gdeId,
            this.localUnit.country,

            this.localUnit.websiteAddress,
            this.localUnit.phoneNumber,
            this.localUnit.emailAddress,
            this.localUnit.isWebsiteAddressDismissed,
            this.localUnit.isPhoneNumberDismissed,
            this.localUnit.isEmailAddressDismissed
        ]);
    }
}
