import { IRootState } from '@/store';
import { ActionTree } from 'vuex';
import { IAccountState } from './state';
import i18n from '@/plugins/i18n';
import { accountService } from '../services/AccountService';
import { MAPUSERPROFILE, MAPCONTACTINFO, MAPSURVEYNAME, MAPREGISTRATIONRESULT, MAPISLOGGEDIN } from './mutations';
import { SHOW, HIDE } from '@/store/modules/loadingSpinner/actions';
import { NEW_SUCESS, NEW_ALERT } from '@/store/modules/headerNotification/actions';
import { CredentialsDto, RegistrationDataViewModel } from '@/api-viewmodel';
import { WIPECASEDATA } from '@/areas/entry/store/mutations';
import { NAME as StoreNameEntry } from '@/areas/entry/store';

/** Pfad zu der Loading-Spinner-Komponente */
const LOADING_SPINNER = 'loadingSpinner/';

/** Pfad zu der Header-Notification-Komponente */
const HEADER_NOTIFICATION = 'headerNotification/';

/** Pfad zu der Save-Indicator-Komponente */
const SAVE_INDICATOR = 'saveIndicator/';

/** Führt ein Login an der Anwendung mit den angegebenen Credentials aus. */
export const LOGIN = 'LOGIN';

/** Führt ein Check aus, ob der aktuelle User angemeldet ist und ermittelt gegebenenfalls die AccountInfo */
export const LOGEDIN_CHECK = 'LOGEDIN_CHECK';

/** Führt ein Logout an der Anwendung aus und entfernt anschliessend allfällig noch vorhandene Falldaten aus dem clientseitigen Store. */
export const LOGOUT = 'LOGOUT';

/** Führt eine Registrierung an der Anwendung aus. */
export const REGISTER = 'REGISTER';

/** Fordert eine erneute Registrierung an der Anwendung an (Passwort vergessen / Recovery). */
export const REQUEST_REGISTRATION = 'REQUEST_REGISTRATION';

/** Holt die Kontakt-Informationen */
export const GET_CONTACT_INFO = 'GET_CONTACT_INFO';

/** Holt Informationen zum User, wird zusätzlich verwendet um zu prüfen ob der User angemeldet ist */
export const GET_ACCOUNT_INFO = 'GET_ACCOUNT_INFO';

/** Liefert den Namen der zum AccessCode gehörenden Survey */
export const GET_ASSIGNED_SURVEY_NAME = 'GET_ASSIGNED_SURVEY_NAME';

export const actions: ActionTree<IAccountState, IRootState> = {
    /**
     * Authentisiert den Benutzer mit den angegebenen Credentials und übergibt die erhaltene Authorisierungsinformation in den Anwendungszustand.
     * @param {string} credentials.username - Der zu verwendenden Benutzername.
     * @param {string} credentials.password - Das zu verwendende Password.
     */
    [LOGIN]({ dispatch, commit }: any, credentials: CredentialsDto) {
        dispatch(LOADING_SPINNER + SHOW, {}, { root: true });

        //Aktion durchführen und Resultat zuweisen
        accountService
            .authenticate(credentials)
            .then(response => {
                commit(MAPUSERPROFILE, response.data);
            })
            .then(() => {
                dispatch(LOADING_SPINNER + HIDE, {}, { root: true });
            })
            .finally(() => {
                //Passwort aus Sicherheitsgründen wieder löschen
                credentials.password = '';
            });
    },
    [LOGOUT]({ dispatch, commit }: any) {
        dispatch(LOADING_SPINNER + SHOW, {}, { root: true });

        //Aktion durchführen und Resultat zuweisen
        //Return von Promise damit anschliessend ein Routing vorgenommen werden kann (vgl. Header)
        return accountService
            .logout()
            .then(response => {
                commit(MAPUSERPROFILE, response.data);

                console.debug('Allfällige bestehende Falldaten werden entfernt.');
                commit(StoreNameEntry + '/' + WIPECASEDATA, null, { root: true });
            })
            .then(() => {
                dispatch(LOADING_SPINNER + HIDE, {}, { root: true });
            });
    },
    [GET_CONTACT_INFO]({ dispatch, commit }: any) {
        dispatch(LOADING_SPINNER + SHOW, {}, { root: true });

        //Aktion durchführen und Resultat zuweisen
        accountService
            .getContactInfo()
            .then(response => {
                commit(MAPCONTACTINFO, response.data);
            })
            .then(() => {
                dispatch(LOADING_SPINNER + HIDE, {}, { root: true });
            });
    },
    [GET_ACCOUNT_INFO]({ dispatch, commit }: any) {
        dispatch(LOADING_SPINNER + SHOW, {}, { root: true });

        //Aktion durchführen und Resultat zuweisen
        accountService
            .getAccountInfo()
            .then(response => {
                commit(MAPUSERPROFILE, response.data);
            })
            .then(() => {
                dispatch(LOADING_SPINNER + HIDE, {}, { root: true });
            });
    },
    [REQUEST_REGISTRATION]({ dispatch, commit }: any, username: string) {
        dispatch(LOADING_SPINNER + SHOW, {}, { root: true });

        //Aktion durchführen
        accountService.requestRegistration(username).then(() => {
            dispatch(LOADING_SPINNER + HIDE, {}, { root: true });
            //Erfolgsmeldung unabhängig vom Ausgang der versuchten Registrierung anzeigen (verunmöglicht Dictionary-Angriff auf Benutzernamen)

            dispatch(HEADER_NOTIFICATION + NEW_SUCESS, { text: i18n.t('page_prc_infodone') }, { root: true });
        });
    },
    [GET_ASSIGNED_SURVEY_NAME]({ dispatch, commit }: any, accessCode: string) {
        dispatch(LOADING_SPINNER + SHOW, {}, { root: true });

        //Aktion durchführen
        accountService
            .getAssignedSurveyName(accessCode)
            .then(response => {
                commit(MAPSURVEYNAME, response.data);
            })
            .then(() => {
                dispatch(LOADING_SPINNER + HIDE, {}, { root: true });
            });
    },
    [REGISTER]({ dispatch, commit }: any, registrationData: RegistrationDataViewModel) {
        dispatch(LOADING_SPINNER + SHOW, {}, { root: true });

        //Aktion durchführen
        accountService
            .register(registrationData)
            .then(response => {
                commit(MAPSURVEYNAME, response.data);
                if (response.status >= 200 && response.status < 300) {
                    commit(MAPREGISTRATIONRESULT, true);
                } else {
                    commit(MAPREGISTRATIONRESULT, false);
                }
            })
            .then(() => {
                dispatch(LOADING_SPINNER + HIDE, {}, { root: true });
            });
    },

    [LOGEDIN_CHECK]({ dispatch, commit }: any) {
        accountService
            .isLoggedIn()
            .then(response => {
                if (response.data) {
                    dispatch(GET_ACCOUNT_INFO);
                } else {
                    commit(MAPISLOGGEDIN, false);
                }
            })
            .catch(() => {
                console.warn('Logincheck failed');
                commit(MAPISLOGGEDIN, false);
            });
    }
};
