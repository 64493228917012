





































































//Imports für dieses Modul
import Vue from 'vue';
import { namespace } from 'vuex-class';
import { Component } from 'vue-property-decorator';

import { NAME as NAME_ACCOUNT } from '@/areas/account/store';
const accountModul = namespace(NAME_ACCOUNT);
import {
    AUTHENTICATED_USERNAME,
    AUTHORIZED_ROLES,
    IS_EXTERNAL_USER,
    IS_LOGGED_IN
} from '@/areas/account/store/getters';

import { NAME as NAME_ENTRY } from '@/areas/entry/store';
const entryModul = namespace(NAME_ENTRY);
import { CASE_ID, CASE_UID, CASE_NAME } from '@/areas/entry/store/getters';
import SaveIndicator from './SaveIndicator.vue';

import { ACCOUNT_CONTACT } from '@/router';

@Component({
    components: { SaveIndicator }
})
export default class Footer extends Vue {
    protected WEBFORMS_BASE_URL: string | undefined = process.env.VUE_APP_WEBFORMS_BACKEND_URL;

    constructor() {
        super();
        //Hint den Fallback zentral lösen
        if (!this.WEBFORMS_BASE_URL) {
            //Fallback für den Fall dass keine Backend-URL verfügbar ist
            const url = window.location.href;
            const searchParam = 'redesign/';
            const length = url.toLowerCase().indexOf(searchParam) - 1; //ohne 'redesign/' Pfad
            this.WEBFORMS_BASE_URL = url.substr(0, length);
            console.debug('Footer::constructor (with fallback URL)', this.WEBFORMS_BASE_URL);
        } else {
            console.debug('Footer::constructor', this.WEBFORMS_BASE_URL);
        }
    }

    @accountModul.Getter(AUTHENTICATED_USERNAME) authenticatedUsername!: string;
    @accountModul.Getter(IS_LOGGED_IN) isLoggedIn!: string;
    @accountModul.Getter(AUTHORIZED_ROLES) authorizedRoles!: string[];
    @accountModul.Getter(IS_EXTERNAL_USER) isExternalUser!: boolean;
    @entryModul.Getter(CASE_ID) caseId!: bigint;
    @entryModul.Getter(CASE_UID) caseUid!: string;
    @entryModul.Getter(CASE_NAME) caseName!: string;

    /** @devdoc Zwingend nötige Definition auf der Instanz, damit dieser Wert für das Rendering verfügbar ist */
    private ACCOUNT_CONTACT = ACCOUNT_CONTACT;

    private get AppVersion() {
        return process.env.VUE_APP_VERSION ? process.env.VUE_APP_VERSION : 'TestAppVersion';
    }

    /** Angaben zum Anwendungs-Status, im Footer
     * @remarks Wird aktuell für die Angabe der UID des aktuell dargestellten Falles im Entry-Wizard verwendet
     */
    private get AppState() {
        return this.caseUid;
    }

    /** URL für die Bearbeitung eines Falles im BFS-Backend
     * @remarks Wird nur im Entry-Wizard verwendet
     */
    private get BackendCaseDetailUrl() {
        /** Ein Fall wird im Wizard von einem BFS-Mitarbeiter bearbeitet? */
        if (this.caseId && !this.isExternalUser && this.isLoggedIn) {
            return this.WEBFORMS_BASE_URL + '/survey/ViewCase.aspx?CaseId=' + this.caseId;
        }
        return null;
    }

    /** Detail-Angaben zum Anwendungs-Status, im Footer als Tooltip zum Anwendungsstatus
     * @remarks Wird aktuell für Detail-Angaben zum aktuell dargestellten Fall im Entry-Wizard verwendet
     */
    private get AppStateDetails() {
        if (this.caseId) {
            return this.caseUid + ' (' + this.caseName + '), ' + this.$t('case_id') + ':' + this.caseId;
        }
        return '';
    }

    /** Angaben zum aktuell eingeloggten Benutzer, im Footer als Tooltip zum Anwendungsnamen
s     */
    private get UserInfo() {
        return (
            this.$t('allg_user_login_id') + ': ' + this.authenticatedUsername + ' (Role: ' + this.authorizedRoles + ')'
        );
    }
}
