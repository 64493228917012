var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("fieldset", [
    _c("div", { staticClass: "form-group" }, [
      _c("label", { staticClass: "col-sm-3 control-label" }, [
        _vm._v(" * " + _vm._s(_vm.$t("allg_descr_pflichtfeld")) + " ")
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }