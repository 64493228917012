var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("NowhowRadioList", {
    attrs: {
      id: _vm.id + "-yes-no",
      name: _vm.name ? _vm.name : _vm.id,
      value: _vm.valueAsString(),
      items: _vm.yesNoItems,
      label: _vm.label,
      validate: _vm.validate,
      inline: _vm.inline,
      disabled: _vm.disabled,
      readonly: _vm.readonly
    },
    on: {
      change: _vm.onChange,
      input: _vm.onInput,
      focusout: _vm.onFocusout,
      "group-focusout": _vm.onGroupFocusout
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }