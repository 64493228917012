































//Imports für dieses Modul
import Vue from 'vue';
import { namespace } from 'vuex-class';
import { NAME } from '../store/index';
import { NAME as NAME_HEADERNOTIFICATION } from '@/store/modules/headerNotification';
import { Component } from 'vue-property-decorator';
import { NEW_SUCESS, NEW_ALERT } from '@/store/modules/headerNotification/actions';
import { ACCOUNT_RECOVERY } from '@/router';
import NowhowTextbox from '@/components/NowhowTextbox.vue';

const headerModul = namespace(NAME_HEADERNOTIFICATION);
const modul = namespace(NAME);

//Getters und Actions
import { REQUEST_REGISTRATION } from '../store/actions';

@Component({
    components: {
        NowhowTextbox
    }
})

/** Passwort zurücksetzen, erneute Registration wird ausgelöst. Steht nur für externe Benutzer zur Verfügung.
 * @remarks Nur externe Benutzer können eine "Password-Recovery" benutzen, da nur diese den Registrierungsprozess unterstützen. Für BFS-interne Benutzer muss ein Admin
   das Passwort für den betreffenden Benutzer resetten.
 * @remarks Implementiert {@link https://collaboration.nowhow.ch/erst/foswiki/bin/view/Erst/PasswortVergessenScreen}
*/
export default class AccountRecovery extends Vue {
    private ACCOUNT_RECOVERY = ACCOUNT_RECOVERY;

    username = '';

    @headerModul.Action(NEW_SUCESS) successMessage!: Function;
    @headerModul.Action(NEW_ALERT) alertMessage!: Function;

    @modul.Action(REQUEST_REGISTRATION) requestRegistration!: Function;

    /** Wrapper für eine Form-Submit-Aktion, um das Standard-Submit-Verhalten im Zusammenspiel mit vee-validate zu unterbinden.
     * @devdoc Wird hier benötigt, da sich die zu versendenden Daten nicht im Store befinden.
     */
    private requestRegistrationWrapper() {
        this.requestRegistration(this.username);
    }
}
