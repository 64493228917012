export interface ILoadingSpinnerState {
    delay: number;
    text: string;
    visible: boolean;
}

export const state: ILoadingSpinnerState = {
    delay: 0,
    text: '',
    visible: false
};
