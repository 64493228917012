import { Module } from 'vuex';
import { IRootState } from '@/store';

import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { IResourceState, state } from './state';


export const NAME = 'resource';

export const store: Module<IResourceState, IRootState> = {
    actions,
    getters,
    modules: {},
    mutations,
    namespaced: true,
    state
};

export default store;
