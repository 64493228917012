

















import Vue from 'vue';
import { Component } from 'vue-property-decorator';

//Getters und Actions

@Component({})

/** Finish-Step nach erfolgreichem Abschluss des Entry Wizard
 * @remarks Implementiert den {@link:https://collaboration.nowhow.ch/erst/foswiki/bin/view/Erst/DatenErfassungAbschlussBestaetigungScreen}
 */
export default class StepFinish extends Vue {}
