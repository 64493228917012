import { IRootState } from '@/store';
import { ActionTree } from 'vuex';
import { IHeaderNotificationState } from './state';

import { SET_TEXT, SET_TYPE, SET_VISIBLE, SET_ERRORS, SET_CLOSEAFTERDELAY } from './mutations';

export const NEW = 'new';
export const NEW_SUCESS = 'newSuccess';
export const NEW_INFO = 'newInfo';
export const NEW_ALERT = 'newAlert';
export const NEW_ERRORMESSAGES = 'newErrorMessages';
export const CLOSE = 'close';

export const actions: ActionTree<IHeaderNotificationState, IRootState> = {
    async [NEW]({ commit }: any, { type, text }: any) {
        commit(SET_VISIBLE, true);
        commit(SET_TYPE, type);
        commit(SET_TEXT, text);
        commit(SET_CLOSEAFTERDELAY, type === 'success');
    },
    async [NEW_SUCESS]({ commit }: any, { text }: any) {
        commit(SET_VISIBLE, true);
        commit(SET_TYPE, 'success');
        commit(SET_TEXT, text);
        commit(SET_CLOSEAFTERDELAY, true);
    },
    async [NEW_INFO]({ commit }: any, { text }: any) {
        commit(SET_VISIBLE, true);
        commit(SET_TYPE, 'info');
        commit(SET_TEXT, text);
        commit(SET_CLOSEAFTERDELAY, false);
    },
    async [NEW_ALERT]({ commit }: any, { text }: any) {
        commit(SET_VISIBLE, true);
        commit(SET_TYPE, 'warning');
        commit(SET_TEXT, text);
        commit(SET_CLOSEAFTERDELAY, true);
    },
    async [NEW_ERRORMESSAGES]({ commit }: any, { errorMessages }: any) {
        commit(SET_VISIBLE, true);
        commit(SET_TYPE, 'danger');
        commit(SET_ERRORS, errorMessages);
        commit(SET_CLOSEAFTERDELAY, false);
    },
    async [CLOSE]({ commit }) {
        commit(SET_VISIBLE, false);
        commit(SET_TYPE, 'danger');
        commit(SET_TEXT, 'placeholder');
        commit(SET_CLOSEAFTERDELAY, false);
    }
};
