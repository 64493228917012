var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ValidationObserver", {
        ref: "observer",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var errors = ref.errors
              return [
                _c("NowhowCheckboxList", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: false,
                      expression: "false"
                    }
                  ],
                  key: _vm.id,
                  ref: "seasonPicker",
                  attrs: {
                    id: _vm.id,
                    name: _vm.$t(_vm.label),
                    readonly: _vm.readonly,
                    disabled: _vm.disabled,
                    value: _vm.value,
                    items: _vm.items,
                    validate: _vm.validate,
                    label: _vm.label,
                    "data-cy": _vm.cy,
                    inline: true
                  },
                  on: {
                    change: _vm.onChange,
                    input: _vm.onInput,
                    focusout: _vm.onFocusout
                  }
                }),
                _c(
                  "div",
                  {
                    class: {
                      "form-group": true,
                      "has-warning": Object.values(errors).flat().length > 0
                    }
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "col-sm-3 control-label",
                        attrs: { id: _vm.id + "-group" }
                      },
                      [
                        _vm._v(_vm._s(_vm.$t(_vm.label)) + " "),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.required,
                                expression: "required"
                              }
                            ]
                          },
                          [_vm._v("*")]
                        )
                      ]
                    ),
                    _c("div", { staticClass: "col-sm-9" }, [
                      _c(
                        "table",
                        {
                          staticClass:
                            "table table-naked table-slim table-season-picker"
                        },
                        [
                          _c("thead", [
                            _c("tr", [
                              _c("th", { staticClass: "text-center" }, [
                                _vm._v(_vm._s(_vm.$t("allg_quarter1_short")))
                              ]),
                              _c("th", { staticClass: "text-center" }, [
                                _vm._v(_vm._s(_vm.$t("allg_quarter2_short")))
                              ]),
                              _c("th", { staticClass: "text-center" }, [
                                _vm._v(_vm._s(_vm.$t("allg_quarter3_short")))
                              ]),
                              _c("th", { staticClass: "text-center" }, [
                                _vm._v(_vm._s(_vm.$t("allg_quarter4_short")))
                              ])
                            ])
                          ]),
                          _c(
                            "tbody",
                            _vm._l(3, function(rowNumber) {
                              return _c(
                                "tr",
                                { key: rowNumber },
                                _vm._l(
                                  _vm.getRowOfItems(rowNumber - 1),
                                  function(item) {
                                    return _c("td", { key: item.value }, [
                                      _c(
                                        "button",
                                        {
                                          class: {
                                            btn: true,
                                            "btn-primary": item.selected
                                          },
                                          attrs: { type: "button" },
                                          on: {
                                            click: function($event) {
                                              item.selected = !item.selected
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "buttontext" },
                                            [_vm._v(_vm._s(_vm.$t(item.text)))]
                                          )
                                        ]
                                      )
                                    ])
                                  }
                                ),
                                0
                              )
                            }),
                            0
                          )
                        ]
                      ),
                      errors
                        ? _c(
                            "span",
                            {
                              staticClass: "help-block",
                              attrs: { id: _vm.id + "-error", role: "alert" }
                            },
                            _vm._l(Object.values(errors).flat(), function(
                              error
                            ) {
                              return _c("span", { key: error }, [
                                _vm._v(_vm._s(error))
                              ])
                            }),
                            0
                          )
                        : _vm._e()
                    ])
                  ]
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }