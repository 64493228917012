var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isBarVisible
    ? _c(
        "div",
        { staticClass: "footer sticky-footer", attrs: { id: "sticky-footer" } },
        [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-xs-3" }, [
                _c(
                  "div",
                  { staticClass: "text-left" },
                  [
                    _vm._l(_vm.buttonLeft, function(button) {
                      return [
                        button.label &&
                        button.label.length > 0 &&
                        button.visible !== false
                          ? _c(
                              "button",
                              {
                                key: button.label,
                                class: _vm.footerClass(button.type),
                                attrs: {
                                  type: "button",
                                  disabled: _vm.isDisabled(button),
                                  title: _vm.isDisabled(button)
                                    ? _vm.$t("btn_continue_disabled_title")
                                    : ""
                                },
                                on: {
                                  click: function($event) {
                                    return button.onClick(button)
                                  }
                                }
                              },
                              [_vm._v(" " + _vm._s(_vm.$t(button.label)) + " ")]
                            )
                          : _vm._e()
                      ]
                    })
                  ],
                  2
                )
              ]),
              _c("div", { staticClass: "col-xs-6" }, [
                _c(
                  "div",
                  { staticClass: "text-center" },
                  [
                    _vm._l(_vm.buttonCenter, function(button) {
                      return [
                        button.label &&
                        button.label.length > 0 &&
                        button.visible !== false
                          ? _c(
                              "button",
                              {
                                key: button.label,
                                class: _vm.footerClass(button.type),
                                attrs: {
                                  type: "button",
                                  disabled: _vm.isDisabled(button),
                                  title: _vm.isDisabled(button)
                                    ? _vm.$t("btn_continue_disabled_title")
                                    : ""
                                },
                                on: {
                                  click: function($event) {
                                    return button.onClick(button)
                                  }
                                }
                              },
                              [_vm._v(" " + _vm._s(_vm.$t(button.label)) + " ")]
                            )
                          : _vm._e()
                      ]
                    })
                  ],
                  2
                )
              ]),
              _c("div", { staticClass: "col-xs-3" }, [
                _c(
                  "div",
                  { staticClass: "text-right" },
                  [
                    _vm._l(_vm.buttonRight, function(button) {
                      return [
                        button.label &&
                        button.label.length > 0 &&
                        button.visible !== false
                          ? _c(
                              "button",
                              {
                                key: button.label,
                                class: _vm.footerClass(button.type),
                                attrs: {
                                  type: "button",
                                  disabled: _vm.isDisabled(button),
                                  title: _vm.isDisabled(button)
                                    ? _vm.$t("btn_continue_disabled_title")
                                    : ""
                                },
                                on: {
                                  click: function($event) {
                                    return button.onClick(button)
                                  }
                                }
                              },
                              [_vm._v(" " + _vm._s(_vm.$t(button.label)) + " ")]
                            )
                          : _vm._e()
                      ]
                    })
                  ],
                  2
                )
              ])
            ])
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }