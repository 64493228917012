import { IRootState } from '@/store';
import { ActionTree } from 'vuex';
import { ISaveIndicationState } from './state';

import { SET_ACTIVE, SET_INACTIVE } from './mutations';

export const ACTIVATE = 'activate';
export const DEACTIVATE = 'deactivate';

export const actions: ActionTree<ISaveIndicationState, IRootState> = {
    async [ACTIVATE]({ commit }: any) {
        commit(SET_ACTIVE);
    },
    async [DEACTIVATE]({ commit }: any) {
        commit(SET_INACTIVE);
    }
};
