import { IRootState } from '@/store';
import { ActionTree } from 'vuex';
import { ILoadingSpinnerState } from './state';
import { TEXT, VISIBLE, DELAY } from './mutations';

export const SHOWDELAYED = 'showDelayed';
export const SHOW = 'show';
export const HIDE = 'hide';

export const actions: ActionTree<ILoadingSpinnerState, IRootState> = {
    [SHOWDELAYED]({ commit }, { text, delay }) {
        commit(TEXT, text);
        commit(VISIBLE, true);
        commit(DELAY, delay);
    },
    [SHOW]({ commit }, { text }) {
        commit(TEXT, text);
        commit(VISIBLE, true);
        commit(DELAY, 0);
    },
    [HIDE]({ commit }) {
        commit(TEXT, '');
        commit(VISIBLE, false);
        commit(DELAY, 0);
    }
};
