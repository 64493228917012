var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    { staticClass: "form-horizontal", attrs: { role: "form" } },
    [
      _c("fieldset", [
        _c("legend", {
          directives: [
            {
              name: "t",
              rawName: "v-t",
              value: "edit_eu_tit_eu",
              expression: "'edit_eu_tit_eu'"
            }
          ]
        }),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("NowhowTextline", {
              attrs: {
                id: "enterpriseDetailUid",
                label: "eu_uid",
                text: this.enterpriseUnit.uidId
              }
            }),
            _c("NowhowTextline", {
              attrs: {
                id: "enterpriseDetailBurId",
                label: "eu_entid",
                text: this.enterpriseUnit.burEnterpriseUnitId
              }
            }),
            _c("NowhowTextbox", {
              directives: [{ name: "focus", rawName: "v-focus" }],
              ref: "enterpriseDetailName",
              attrs: {
                id: "enterpriseDetailName",
                label: "eu_name",
                "input-type": "text",
                validate: _vm.getValidation(
                  "enterpriseUnitDataObject.legalUnitName"
                )
              },
              model: {
                value: _vm.enterpriseUnit.legalUnitName,
                callback: function($$v) {
                  _vm.$set(_vm.enterpriseUnit, "legalUnitName", $$v)
                },
                expression: "enterpriseUnit.legalUnitName"
              }
            }),
            _c("NowhowTextbox", {
              attrs: {
                id: "enterpriseDetailBusinessName",
                label: "eu_busname",
                "input-type": "text",
                validate: _vm.getValidation(
                  "enterpriseUnitDataObject.nameBusiness"
                )
              },
              model: {
                value: _vm.enterpriseUnit.nameBusiness,
                callback: function($$v) {
                  _vm.$set(_vm.enterpriseUnit, "nameBusiness", $$v)
                },
                expression: "enterpriseUnit.nameBusiness"
              }
            })
          ],
          1
        )
      ]),
      _c(
        "fieldset",
        [
          _c("legend", [_vm._v(_vm._s(_vm.$t("edit_eu_tit_adr")))]),
          _c("NowhowTextbox", {
            attrs: {
              id: "enterpriseDetailCareOf",
              label: "adr_adrline1",
              "input-type": "text",
              validate: _vm.getValidation("enterpriseUnitDataObject.careOf")
            },
            model: {
              value: _vm.enterpriseUnit.careOf,
              callback: function($$v) {
                _vm.$set(_vm.enterpriseUnit, "careOf", $$v)
              },
              expression: "enterpriseUnit.careOf"
            }
          }),
          _c("br"),
          !_vm.isEnterpriseSwisstopoUnavailable
            ? _c("div", { staticClass: "form-group" }, [
                _c(
                  "label",
                  {
                    staticClass: "col-sm-3 control-label",
                    attrs: { for: "enterpriseDetailSwisstopoSearchbox" }
                  },
                  [_vm._v(_vm._s(_vm.$t("address_searchbox_with_swisstopo")))]
                ),
                _c(
                  "div",
                  { staticClass: "col-sm-9" },
                  [
                    _c("SwisstopoFeatureSearch", {
                      attrs: { id: "enterpriseDetailSwisstopoSearchbox" },
                      on: {
                        featureSelected:
                          _vm.onEnterpriseSwisstopoFeatureSelected,
                        error: _vm.onEnterpriseSwisstopoUnavailable
                      }
                    }),
                    _c("div", { staticClass: "checkbox checkbox--left" }, [
                      _c(
                        "label",
                        {
                          attrs: {
                            for: "enterpriseDetailSwisstopoSearchbox-dismissed"
                          }
                        },
                        [
                          _c("input", {
                            key: "enterpriseDetailSwisstopoSearchbox-dismissed",
                            ref: "swisstopoDismissalCheckbox",
                            staticClass: "form-control",
                            attrs: {
                              type: "checkbox",
                              id:
                                "enterpriseDetailSwisstopoSearchbox-dismissed",
                              name: _vm.$t("address_manual_entry")
                            },
                            domProps: {
                              checked: _vm.enterpriseUnit.isSwisstopoDismissed
                            },
                            on: {
                              change: _vm.onEnterpriseSwisstopoDismissedChange
                            }
                          }),
                          _vm._v(
                            " " + _vm._s(_vm.$t("address_manual_entry")) + " "
                          )
                        ]
                      )
                    ])
                  ],
                  1
                )
              ])
            : _vm._e(),
          _c("NowhowTextbox", {
            attrs: {
              id: "enterpriseDetailStreet",
              label: "allg_street",
              "input-type": "text",
              readonly: !_vm.enterpriseUnit.isSwisstopoDismissed,
              validate: _vm.getValidation("enterpriseUnitDataObject.street")
            },
            on: { input: _vm.onManualEnterpriseAddressInput },
            model: {
              value: _vm.enterpriseUnit.street,
              callback: function($$v) {
                _vm.$set(_vm.enterpriseUnit, "street", $$v)
              },
              expression: "enterpriseUnit.street"
            }
          }),
          _c("NowhowTextbox", {
            attrs: {
              id: "enterpriseDetailHouseNumber",
              label: "allg_street_nr",
              "input-type": "text",
              readonly: !_vm.enterpriseUnit.isSwisstopoDismissed,
              validate: _vm.getValidation(
                "enterpriseUnitDataObject.houseNumber"
              )
            },
            on: { input: _vm.onManualEnterpriseAddressInput },
            model: {
              value: _vm.enterpriseUnit.houseNumber,
              callback: function($$v) {
                _vm.$set(_vm.enterpriseUnit, "houseNumber", $$v)
              },
              expression: "enterpriseUnit.houseNumber"
            }
          }),
          _c("NowhowTextbox", {
            attrs: {
              id: "enterpriseDetailAddressAdditionalText",
              label: "adr_additional_text",
              "input-type": "text",
              validate: _vm.getValidation(
                "enterpriseUnitDataObject.additionalTxt"
              )
            },
            model: {
              value: _vm.enterpriseUnit.additionalTxt,
              callback: function($$v) {
                _vm.$set(_vm.enterpriseUnit, "additionalTxt", $$v)
              },
              expression: "enterpriseUnit.additionalTxt"
            }
          }),
          _c("NowhowUInt32Box", {
            attrs: {
              id: "enterpriseDetailZipCode",
              label: "allg_zip",
              readonly: !_vm.enterpriseUnit.isSwisstopoDismissed,
              validate: _vm.getValidation("enterpriseUnitDataObject.zipCode")
            },
            on: { input: _vm.onManualEnterpriseAddressInput },
            model: {
              value: _vm.enterpriseUnit.zipCode,
              callback: function($$v) {
                _vm.$set(_vm.enterpriseUnit, "zipCode", $$v)
              },
              expression: "enterpriseUnit.zipCode"
            }
          }),
          _c("NowhowTextbox", {
            attrs: {
              id: "enterpriseDetailTown",
              label: "allg_ort",
              "input-type": "text",
              readonly: !_vm.enterpriseUnit.isSwisstopoDismissed,
              validate: _vm.getValidation("enterpriseUnitDataObject.town")
            },
            on: { input: _vm.onManualEnterpriseAddressInput },
            model: {
              value: _vm.enterpriseUnit.town,
              callback: function($$v) {
                _vm.$set(_vm.enterpriseUnit, "town", $$v)
              },
              expression: "enterpriseUnit.town"
            }
          }),
          _c("br"),
          _c("NowhowTextbox", {
            attrs: {
              id: "enterpriseDetailPoBoxNumber",
              label: "allg_postbox_nr",
              "input-type": "number",
              validate:
                _vm.getValidation(
                  "enterpriseUnitDataObject.postOfficeBoxNumber"
                ) +
                "|" +
                _vm.getPoBoxTogetherRule
            },
            model: {
              value: _vm.enterpriseUnit.postOfficeBoxNumber,
              callback: function($$v) {
                _vm.$set(_vm.enterpriseUnit, "postOfficeBoxNumber", $$v)
              },
              expression: "enterpriseUnit.postOfficeBoxNumber"
            }
          }),
          _c("NowhowUInt32Box", {
            attrs: {
              id: "enterpriseDetailPoBoxZipCode",
              label: "allg_postbox_zip",
              validate:
                _vm.getValidation(
                  "enterpriseUnitDataObject.postOfficeBoxZipCode"
                ) +
                "|" +
                _vm.getPoBoxTogetherRule
            },
            model: {
              value: _vm.enterpriseUnit.postOfficeBoxZipCode,
              callback: function($$v) {
                _vm.$set(_vm.enterpriseUnit, "postOfficeBoxZipCode", $$v)
              },
              expression: "enterpriseUnit.postOfficeBoxZipCode"
            }
          }),
          _c("NowhowTextbox", {
            attrs: {
              id: "enterpriseDetailPoBoxTown",
              label: "allg_postbox_town",
              "input-type": "text",
              validate:
                _vm.getValidation(
                  "enterpriseUnitDataObject.postOfficeBoxTown"
                ) +
                "|" +
                _vm.getPoBoxTogetherRule
            },
            model: {
              value: _vm.enterpriseUnit.postOfficeBoxTown,
              callback: function($$v) {
                _vm.$set(_vm.enterpriseUnit, "postOfficeBoxTown", $$v)
              },
              expression: "enterpriseUnit.postOfficeBoxTown"
            }
          }),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: false,
                  expression: "false"
                }
              ]
            },
            [
              _vm._v(" GDEID:" + _vm._s(_vm.enterpriseUnit.gdeId)),
              _c("br"),
              _vm._v(" ESTRID:" + _vm._s(_vm.enterpriseUnit.estrId)),
              _c("br"),
              _vm._v(" EGID:" + _vm._s(_vm.enterpriseUnit.egId)),
              _c("br"),
              _vm._v(" EDID:" + _vm._s(_vm.enterpriseUnit.edId)),
              _c("br"),
              _vm._v(" ESID:" + _vm._s(_vm.enterpriseUnit.esId)),
              _c("br")
            ]
          ),
          _c("br"),
          _c("NowhowTextarea", {
            attrs: {
              id: "enterpriseDetailComment",
              label: "eu_comment",
              "input-type": "text",
              validate: _vm.getValidation(
                "enterpriseUnitDataObject.commentText"
              )
            },
            model: {
              value: _vm.enterpriseUnit.commentText,
              callback: function($$v) {
                _vm.$set(_vm.enterpriseUnit, "commentText", $$v)
              },
              expression: "enterpriseUnit.commentText"
            }
          })
        ],
        1
      ),
      _c("NowhowRequiredInfoline")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }