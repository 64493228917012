










































import Vue from 'vue';

import { Component, Prop, Watch } from 'vue-property-decorator';

@Component
export default class NowhowTextarea extends Vue {
    @Prop({ required: true, type: String }) private id!: string;

    @Prop({ required: false, type: String, default: '' }) private label!: string;

    @Prop({ required: false, type: String, default: '' }) private placeholder!: string;

    @Prop({ required: false, type: String, default: '' }) private autocomplete!: string;

    @Prop({ required: false, type: Boolean, default: false }) private disabled!: boolean;

    @Prop({ required: false, type: Boolean, default: false }) private readonly!: boolean;

    @Prop({ default: '', type: [String, Object] }) validate!: string | object;

    @Prop({ required: true, default: '' }) private value!: any;

    @Prop({ required: false, type: Number, default: 4 }) private rows!: number;

    @Prop({ required: false, type: Number, default: 5 }) private cols!: number;

    @Prop({ required: false, type: String, default: '' })
    private cy!: string;

    /* eslint-disable-next-line no-empty, @typescript-eslint/no-empty-function */
    @Prop({ required: false, type: Function, default: function() {} })
    private onEnter!: Function;

    private provider: any;
    private touched = false;

    mounted() {
        const test: any = document.getElementById(String(this.id));
        test.value = this.value;

        this.provider = this.$refs['provider'];
    }

    public focus() {
        (this.$refs.inputfield as HTMLInputElement).focus();
    }

    private onChange(value) {
        this.$emit('change', value);
        this.touched = true;
    }

    private onFocusout(value) {
        this.$emit('focusout', this.value);
    }

    private onInput(value) {
        this.$emit('input', value);
        this.touched = true;
    }

    @Watch('value', { deep: true, immediate: true })
    private onValueChanged() {
        this.provider = this.$refs['provider'];
        this.provider?.validate(this.value, { silent: true });
    }
}
