







































import Vue from 'vue';

import { Component, Prop, Watch } from 'vue-property-decorator';

import NowhowCheckbox from '@/components/NowhowCheckbox.vue';
import { ISelectListItem } from '@/types/clienttypes';
import { ProviderInstance } from 'vee-validate/dist/types/types';

@Component({
    components: { NowhowCheckbox }
})
export default class NowhowCheckboxList extends Vue {
    @Prop({ required: true, type: String }) private id!: string;

    @Prop({ required: false, type: String, default: '' }) private label!: string;

    @Prop({ required: false, type: Boolean, default: false }) private disabled!: boolean;

    @Prop({ required: false, type: Boolean, default: false }) private readonly!: boolean;

    @Prop({ required: false, type: Boolean, default: false }) private inline!: boolean;

    @Prop({ default: '', type: [String, Object] }) validate!: string | object;

    @Prop({ required: true, type: Array as () => Array<ISelectListItem>, default: () => [] }) items!: ISelectListItem[];

    @Prop({
        required: false,
        type: [String, Array as () => Array<string>, Number, Array as () => Array<number>],
        default: ''
    })
    value!: string | string[] | number | number[];

    @Prop({ required: false, type: String, default: '' })
    private cy!: string;

    private isMounted = false;
    private provider: ProviderInstance;
    private touched = false;

    mounted() {
        this.items.forEach(item => {
            //Selected je nach Typ unterschiedlich festlegen
            //Hinweis: Es wird erwartet dass, der Basis-Typ von checkbox.value und von value exakt der gleiche ist.
            if (Array.isArray(this.value)) {
                //Array: Prüfen, ob der Wert im Array enthalten ist
                //checkbox.selected = Array.from(this.value).includes(checkbox.value);
                if (typeof item.value === 'string') {
                    item.selected = (this.value as Array<string>).includes(item.value);
                } else if (typeof item.value === 'number') {
                    item.selected = (this.value as Array<number>).includes(item.value);
                } else {
                    //Andere Typen werden nicht unterstützt
                    throw new Error(`Expected string or number, got '${item.value}'.`);
                }
            } else {
                //Einzel-Wert: prüfen, ob der Wert exakt gleich ist
                item.selected = this.value === item.value;
            }
        });
        this.provider = this.$refs['provider'] as ProviderInstance;

        if (((this.value instanceof String || Array.isArray(this.value)) && this.value.length > 0) || this.value > 0) {
            this.onChange();
        }
        this.isMounted = true;
    }

    updateItems(value: string | string[] | number | number[]) {
        console.debug('NowhowCheckboxList::updateItems', value);
        if (Array.isArray(value)) {
            const valueAsStringArray = value as Array<string>;
            //Array: Prüfen, ob der Wert im Array enthalten ist
            this.items.forEach(item => {
                const itemSelected = valueAsStringArray.includes(item.value.toString());
                item.selected = itemSelected;
            });
        } else {
            this.items.forEach(item => {
                const itemSelected = value?.toString() === item.value.toString();
                item.selected = itemSelected;
            });
        }
        this.onChange();
    }

    get hasErrors() {
        if (this.isMounted) {
            return this.provider.errors.length > 0;
        } else {
            return false;
        }
    }
    get required() {
        const isString = typeof this.validate === 'string' || this.validate instanceof String;
        // eslint-disable-next-line no-prototype-builtins
        return isString ? this.validate.toString().indexOf('required') >= 0 : this.validate.hasOwnProperty('required');
    }

    get selectedValues() {
        return this.items.filter(item => item.selected).map(item => item.value);
    }

    private onChange() {
        this.provider.validate(this.selectedValues).then(() => {
            this.$emit('input', this.selectedValues);
            this.$emit('change', this.selectedValues);
            this.touched = true;
        });
    }

    private onFocusout(value) {
        this.provider.validate(value).then(() => {
            this.$emit('focusout', value);
        });
    }

    @Watch('value')
    valueChanged(newVal, oldVal) {
        console.debug('NowhowCheckboxList::valueChanged', newVal, oldVal);
        this.updateItems(newVal);
        this.provider?.validate(newVal, { silent: true });
    }

    @Watch('items', { deep: true })
    itemsChanged(newVal, oldVal) {
        console.debug('NowhowCheckboxList::itemsChanged', newVal, oldVal);
        this.updateItems(newVal?.filter(item => item.selected).map(item => item.value));
    }
}
