import { IEnvironmentService } from './IEnvironmentService';
import BaseService from '@/services/baseService';
import { AxiosResponse } from 'axios';
import { IEnvironmentViewModel } from '@/api-viewmodel';

/**
 * Implementiert den Service zum Environment
 * @class
 * @implements {IEnvironmentService}
 */
export class EnvironmentService extends BaseService implements IEnvironmentService {
    private ENVIRONMENT_URL: string;
    constructor() {
        super();
        this.ENVIRONMENT_URL = `${this.BASE_URL}/environment`;
        console.debug('ResourceService::constructor', this.ENVIRONMENT_URL);
    }

    /**
     * Holt die Informationen zum Environment
     */
    async environmentInfo(): Promise<AxiosResponse<IEnvironmentViewModel>> {
        console.debug('EnvironmentService::environmentInfo', this.ENVIRONMENT_URL);
        return super
            .GET<AxiosResponse<IEnvironmentViewModel>>(`${this.ENVIRONMENT_URL}/Info`, null)
            .then((response: AxiosResponse<IEnvironmentViewModel>) => {
                return response;
            });
    }
}

export const environmentService = new EnvironmentService();
